import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';

const IrregularInterval = ({ data: graphData, xLabel, yLabel, downloadTitle = 'Chart' }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    let root = am5.Root.new(chartRef.current);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        pinchZoomX: true,
        pinchZoomY: true,
        layout: root.verticalLayout
      })
    );

    var colors = chart.get('colors');

    const data = graphData?.map((d, index) => {
      return {
        ...d,
        fillSettings: {
          fillOpacity: 1,
          visible: true,
          fill: colors.getIndex(index % 2)
        }
      };
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        // max: 50,
        // numberFormat: "#,###'km'",
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: am5.percent(50),
      forceHidden: false
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        // numberFormat: "#,###'m'",
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
        text: yLabel,
        yaxisValue: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      0
    );

    yAxis.get('renderer').labels.template.setAll({
      rotation: 0,
      centerX: am5.p100,
      centerY: am5.p50
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        baseAxis: xAxis,
        valueXField: 'xaxisValue',
        valueYField: 'yaxisValue',
        tooltip: am5.Tooltip.new(root, {
          labelText: `[bold]${yLabel}[/]: {valueY}\n[bold]${xLabel}[/]: {valueX}`
        })
      })
    );

    series.strokes.template.setAll({
      visible: false
    });

    series.fills.template.setAll({
      fillOpacity: 1,
      visible: true,
      templateField: 'fillSettings'
    });

    series.data.setAll(data);

    // Create grid/ranges for X axis
    for (var i = 0; i < data.length; i++) {
      var value = data[i].xaxisValue;

      var rangeDataItem = xAxis.makeDataItem({
        value: value
      });

      xAxis.createAxisRange(rangeDataItem);

      rangeDataItem.get('label').setAll({
        forceHidden: true,
        text: value
      });

      rangeDataItem.get('grid').setAll({
        forceHidden: false,
        strokeOpacity: 0.2,
        location: 1
      });
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        snapToSeries: [series]
      })
    );

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      })
    );

    chart.set(
      'scrollbarY',
      am5.Scrollbar.new(root, {
        orientation: 'vertical'
      })
    );

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    // series.appear();
    chart.appear(1000, 10);

    const downloadedData = data?.map((item) => ({ [xLabel]: item?.xaxisValue, [yLabel]: item?.yaxisValue }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: downloadedData,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      },
      filePrefix: downloadTitle
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, []);

  return <div id="chartdiv" style={{ width: '100%', height: '500px' }} ref={chartRef}></div>;
};

export default IrregularInterval;
