import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { deleteLicense, getLicenseForm } from 'api/asset360.service';
import useNotification from 'hooks/useNotifications';
import DeleteConfirmationModal from 'ui-component/Modal/DeleteConfirmationModal';
import LicenseEditModal from 'ui-component/Modal/LicenseEditModal';
import CreateButton from 'ui-component/Sections/CreateButton';
import CommonTable from 'ui-component/table/DataSourceTable/CommonTable';
import { getUrlParams } from 'utils';

const DatasetDetailTable = ({
  apiCallOnPage,
  tableData,
  params,
  title,
  sectionProps,
  selectedChip,
  setSelectedChip,
  tableTotalCount,
  searchBar,
  handleClick,
  toolbarConfig
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [currentLicenseId, setCurrentLicenseId] = useState(null);
  const location = useLocation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingLicenseId, setDeletingLicenseId] = useState(null);
  const [deletingDisplayName, setDeletingDisplayName] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const notification = useNotification();

  const handleEditClick = async (row) => {
    try {
      setIsModalOpen(true);
      setIsFormLoading(true);
      setApiError(null);

      const licenseId = row?.licenseId || row?.id;

      if (!licenseId) {
        const params = getUrlParams(location.search);
        const urlLicenseId = params.licenseId;

        if (!urlLicenseId) {
          setApiError('License ID not found');
          return;
        }
      }

      setCurrentLicenseId(licenseId);

      const response = await getLicenseForm(licenseId);

      if (response.status === 200) {
        setFormData(response.data);
      } else {
        setApiError(response?.data?.detail || 'Failed to fetch license details');
      }
    } catch (error) {
      setApiError(error?.response?.data?.detail || 'Failed to fetch license details');
    } finally {
      setIsFormLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(null);
    setCurrentLicenseId(null);
  };

  const handleRefresh = () => {
    const tableId = params?.id || 'default';
    const sortByKey = `tableSortBy_${tableId}`;
    const sortOrderKey = `tableSortOrder_${tableId}`;

    const sortBy = sessionStorage.getItem(sortByKey);
    const sortOrder = sessionStorage.getItem(sortOrderKey);
    const currentPageSize = tableData?.data?.page_size || 10;

    apiCallOnPage({
      ...params,
      page_number: 0,
      page_size: currentPageSize,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: parseInt(sortOrder) })
    });
  };

  const handleDeleteClick = (row) => {
    const licenseId = row?.licenseId || row?.id;
    const displayName = row?.displayName || row?.name;
    setDeletingLicenseId(licenseId);
    setDeletingDisplayName(displayName);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!deletingLicenseId) return;

    setIsDeleting(true);
    try {
      const response = await deleteLicense(deletingLicenseId);
      if (response.status === 200) {
        notification.success('License deleted successfully', 3000);
        handleRefresh();
      } else {
        notification.error('Failed to delete license', 3000);
      }
    } catch (error) {
      notification.error(error?.response?.data?.detail || 'Failed to delete license', 3000);
    } finally {
      setIsDeleting(false);
      setDeleteModalOpen(false);
      setDeletingLicenseId(null);
      setDeletingDisplayName('');
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setDeletingLicenseId(null);
    setDeletingDisplayName('');
  };

  return (
    <>
      <CommonTable
        apiCallOnPage={apiCallOnPage}
        tableData={tableData}
        req_params={params}
        title={title}
        disableExport={sectionProps?.disableExport}
        entitlements={sectionProps?.entitlements}
        toggleButtons={sectionProps?.toggleButtons}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        tableTotalCount={tableTotalCount}
        searchBar={searchBar}
        handleClick={handleClick}
        toolbarConfig={toolbarConfig}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteClick}
      >
        {sectionProps?.actionButton && <CreateButton name={sectionProps.name} handleClick={handleClick} />}
      </CommonTable>

      <LicenseEditModal
        open={isModalOpen}
        onClose={handleCloseModal}
        formData={formData}
        loading={isFormLoading}
        error={apiError}
        onRefresh={handleRefresh}
        licenseId={currentLicenseId}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        displayName={deletingDisplayName}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default DatasetDetailTable;
