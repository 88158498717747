// import MainLayout from 'layout/MainLayout';
import React from 'react';
import { Button, CssBaseline, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainLayout from 'layout/MainLayout';
import { AppLayout } from 'layout/AppLayout';

import img from 'assets/images/errorBoundary.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    maxWidth: '500px',
    rowGap: '1rem',
    margin: 'auto'
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '1rem'
  }
}));

const Error500 = ({ reset }) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <AppLayout>
        <div className={classes.root}>
          <img src={img} alt="Error" className={classes.image} />
          <Typography variant="h2" maxWidth={600}>
            Oops! We couldn&apos;t load that.
          </Typography>
          <Typography>
            Sorry, this isn&apos;t supposed to happen, but our team is aware and is working on a fix. Hang tight! Need support instead?
            Write to us at <a href="mailto:support@asato.ai">support@asato.ai</a>.
          </Typography>
          <Button sx={{ padding: '0.5rem 1.5rem', fontSize: '1rem' }} variant="outlined" onClick={reset}>
            Retry
          </Button>
        </div>
      </AppLayout>
    </>
  );
};

export default Error500;
