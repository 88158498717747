import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

import { formatCurrency } from 'utils';

// {
//   Title: 'Microsoft Office 365 Optimisation',
//   Y1_axis: '# of Licenses',
//   Y2_axis: 'Annual Cost ($)',
//   X_axis: ['Now', 'Optimised'],
//   Values: {
//     Now: {
//       E5: 2775,
//       'F3+': 225,
//       'Annual Cost': 1605000
//     },
//     Optimised: {
//       E5: 500,
//       'F3+': 2500,
//       'Annual Cost': 760500
//     }
//   },
//   Textual_Insight: 'Optimising your Microsoft Office 365 licenses allocation will save you $0.9M annually'
// }

const BarchartWithLine = ({ data, xLabel = '', yLabel1 = '', yLabel2 = '', yFormat = 'number' }) => {
  const id = uuid();

  const seriesRefs = useRef([]);
  const chartData = data?.chartValues;

  // Filter out 'category' and data.lineSeries from the keys
  const seriesKeys = Object.keys(chartData[0]).filter((key) => key !== 'category' && key !== data.lineSeries);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    let root = am5.Root.new(id);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingTop: 50,
        paddingBottom: 50
      })
    );

    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none'
      })
    );
    cursor.lineY.set('visible', false);

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({ location: 0.4, centerY: am5.p50 });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: xRenderer
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.data.setAll(chartData);

    let yAxis1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: yFormat === 'currency' ? "'$'#a" : '#a',
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    yAxis1.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: data.Y1_axis,
        y: am5.p50,
        centerX: am5.p50
      }),
      0
    );

    yAxis1.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel1,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    var yRenderer = am5xy.AxisRendererY.new(root, {
      visible: true,
      opposite: true,
      rotation: -90
    });

    let yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "'$'#a",
        min: 0,
        renderer: yRenderer
      })
    );

    yAxis2.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: data.Y2_axis,
        y: am5.p50,
        centerX: am5.p50
      }),
      0
    );

    yAxis2.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel2,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    // ********** Common Fns Started **********

    // eslint-disable-next-line
    const MakeColumnSeries = (name, valueYField, tooltipText) => {
      // * A common function for creating column series
      return chart.series.push(
        am5xy.ColumnSeries.new(root, {
          stacked: true,
          name: name,
          xAxis: xAxis,
          yAxis: yAxis1,
          valueYField: valueYField,
          categoryXField: 'category'
          // tooltip: am5.Tooltip.new(root, { labelText: `{name}: {valueY}` })
        })
      );
    };

    // eslint-disable-next-line
    const MakeLineSeries = (name, valueYField, tooltipText) => {
      // * A common function for creating line series
      return chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: valueYField,
          categoryXField: 'category',
          tooltip: am5.Tooltip.new(root, { labelText: `{name}:\n{valueY}` })
        })
      );
    };

    // ********** Common Fns End **********

    // * Create column series for each key except 'category' and lineSeries
    seriesKeys.forEach((key, index) => {
      let series = MakeColumnSeries(key, key, key);
      series.columns.template.setAll({ tooltipText: '{name}:\n{valueY}' });
      series.columns.template.adapters.add('tooltipText', (text, target) => {
        const valueY = target?.dataItem?.get('valueY');
        const categoryX = target?.dataItem?.get('categoryX');
        let newName = key;
        if (newName && newName.length > 12) {
          const half = Math.ceil(newName.length / 2);
          newName = newName.slice(0, half) + ' -\n- ' + newName.slice(half);
        }
        const formattedValueY = yFormat === 'currency' ? formatCurrency(valueY) : valueY;
        return `[fontSize: 10px]${newName},\n ${categoryX} : ${formattedValueY}`;
      });
      series.data.setAll(chartData);
      seriesRefs.current[index] = series;
    });

    // * Create a line series for the key specified by data.lineSeries
    let lineSeries = MakeLineSeries(data.lineSeries, data.lineSeries, data.lineSeries);
    lineSeries.strokes.template.setAll({ strokeWidth: 3 });
    lineSeries.bullets.push(() =>
      am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: root.interfaceColors.get('background'),
          stroke: lineSeries.get('stroke')
        })
      })
    );
    lineSeries.data.setAll(chartData);

    // Updated tooltip logic for line series
    lineSeries.get('tooltip').label.adapters.add('text', (text, target) => {
      const valueY = target?.dataItem?.get('valueY');
      const categoryX = target?.dataItem?.get('categoryX');
      let newName = data.lineSeries;
      if (newName && newName.length > 12) {
        const half = Math.ceil(newName.length / 2);
        newName = newName.slice(0, half) + ' -\n- ' + newName.slice(half);
      }
      const formattedValueY = yFormat === 'currency' ? formatCurrency(valueY) : valueY;
      return `[fontSize: 10px]${newName},\n ${categoryX} : ${formattedValueY}`;
    });

    seriesRefs.current.push(lineSeries);

    // seriesRefs.current.forEach((series) => // series.appear());

    // chart.appear(1000, 100);

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: data?.title,
      dataSource: data?.chartValues,
      pngOptions: {
        quality: 1,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (seriesRefs.current.length) {
      seriesRefs.current.forEach((series) => {
        series.data.setAll(chartData);
      });
    }
  }, [chartData]);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
};

export default BarchartWithLine;
