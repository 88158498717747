import React from 'react';
import { useId, useRef } from 'react';
import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
const PieChart = ({ data }) => {
  const id = useId();
  const seriesRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(id);

    // Set themes
    // Create chart
    const chart = root.container.children.push(am5percent.PieChart.new(root, {}));

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: true
      })
    );

    series.labels.template.setAll({
      text: '{category}',
      textType: 'radial',
      inside: false,
      radius: 10,
      oversizedBehavior: 'ellipsis',
      maxWidth: 90,
      ellipsis: '...'
    });

    series.labels.template.adapters.add('fill', function (fill, target) {
      if (target.dataItem) {
        return target.dataItem.get('slice');
      } else {
        return fill;
      }
    });

    seriesRef.current = series;

    // series.appear(1000, 100);

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (seriesRef.current) {
      seriesRef.current.data.setAll(data);
    }
  }, [data]);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
};

export default PieChart;
