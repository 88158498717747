import React from 'react';
import { Box, MenuItem, Pagination, Select, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

function CustomPagination({ totalCount, requestParams, totalPages }) {
  const apiRef = useGridApiContext();

  const page = requestParams?.page_number ?? 0;
  const pageSize = requestParams?.page_size ?? 10;

  const calculatedPageCount = Math.ceil(totalCount / pageSize);
  const effectivePageCount = totalPages || calculatedPageCount;

  const rowCount = totalCount || 0;
  const startRow = rowCount === 0 ? 0 : page * pageSize + 1;
  const endRow = Math.min((page + 1) * pageSize, rowCount);

  const handlePageChange = (event, value) => {
    apiRef.current.setPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    apiRef.current.setPageSize(newSize);
  };

  // hide pagination if no data
  if (rowCount === 0 && !totalPages) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ padding: '0 16px', width: '100%' }}>
      <Box display="flex" alignItems="center" gap="8px">
        <Typography variant="body2">Rows per page:</Typography>
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          variant="outlined"
          sx={{
            marginLeft: '8px',
            padding: '2px 4px',
            borderRadius: '4px',
            minWidth: '50px',
            height: '32px',
            lineHeight: '32px',
            fontSize: '0.875rem',
            boxShadow: 'none',
            '& .MuiSelect-select': {
              padding: '2px',
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ccc'
            }
          }}
        >
          {[5, 10, 25, 100].map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">
          {startRow} - {endRow} of {rowCount}
        </Typography>
        <Pagination count={effectivePageCount} page={page + 1} onChange={handlePageChange} disabled={rowCount === 0} />
      </Box>
    </Box>
  );
}

export default CustomPagination;
