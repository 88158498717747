import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import { InfoCircle } from 'iconsax-react';

const DataTableToolbar = ({ title = '', description = 'test', toolbarConfig = [] }) => {
  return (
    <GridToolbarContainer sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      {title ? (
        <Box px={2} py={1} display={'flex'} alignItems={'center'} gap={1}>
          <Typography variant="h6" fontWeight={500}>
            {title}
          </Typography>
          {description && (
            <Tooltip title={description} placement={'top'}>
              <InfoCircle size="18" color={'#2A76FA'} />
            </Tooltip>
          )}
        </Box>
      ) : null}
      <Box display={'flex'} width={'100%'}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
        </Box>
        <Box display={'flex'} gap={1}>
          {toolbarConfig?.map((config) => (
            <Button
              key={config.key}
              color={config.color || 'info'}
              size={'small'}
              startIcon={config.icon}
              onClick={config.onClick}
              disabled={config.loading || false}
            >
              {config.loading || config.label}
            </Button>
          ))}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export { DataTableToolbar };
