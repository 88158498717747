import React from 'react';
import { Box } from '@mui/system';

import FadeInDiv from 'ui-component/FadeInDiv';
import SectionWrapper from 'ui-component/SectionWrapper';

import { DataProductTable } from './DataProductTable';

/**
 *
 * @param {string} name - The name of the data product
 * @param {Z.infer<typeof getDataproductNameGetResponse.tables >} tables - the data products tables specifications
 * @param {Object} dateRange - Date range for the tables
 * @param {string} dateRange.start - Start date of the date range
 * @param {string} dateRange.end - End date of the date range
 * @returns
 */
export const DataProductTablesContainer = ({ name, tables, dateRange }) => {
  const hasMultipleTables = tables.length > 1;
  return (
    <FadeInDiv>
      <SectionWrapper
        hasBackButton={false}
        {...(hasMultipleTables ? { variant: 'box', title: 'Tables', bordered: true } : { bordered: false })}
      >
        <Box flexDirection={'column'} display={'flex'} gap={2}>
          {tables.map((table) => (
            <DataProductTable name={name} tableInfo={table} key={table.id} dateRange={dateRange} />
          ))}
        </Box>
      </SectionWrapper>
    </FadeInDiv>
  );
};
