import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

// import { data as rawData, guideValue } from '../../constants/chartData';

const ControlChart = ({ data, xLabel, yLabel, downloadTitle = 'Chart', hasScrollBarX = true }) => {
  const rawData = data[0].visitCount_values;
  const guideValue = data[0].medianVisitCount;
  const id = uuid();

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    let root = am5.Root.new(id);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        pinchZoomX: true
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: false
    });

    // Rotate x-axis labels to prevent overlap
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100
    });

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: xRenderer,
        // Prevents grouping of dates
        groupData: false,
        extraMin: 0.01,
        extraMax: 0.01,
        tooltipLocation: 0,
        markUnitChange: false
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set('forceHidden', true);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    // Add cursor
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
        xAxis: xAxis
      })
    );
    cursor.lineY.set('visible', false);

    // Add scrollbars

    if (hasScrollBarX) {
      chart.set(
        'scrollbarX',
        am5.Scrollbar.new(root, {
          orientation: 'horizontal',
          minHeight: 3
        })
      );
    }

    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'timestamp',
        locationX: 0,
        stroke: am5.color('#2A76F4'),
        seriesTooltipTarget: 'bullet',
        tooltip: am5.Tooltip.new(root, {
          labelText: '[bold]count:{valueY}[/]\n\n{formattedDate}'
          // getFillFromSprite: false
          // autoTextColor: false,
          // background: am5.Rectangle.new(root, {
          //   fill: am5.color(0x000000)
          // }),
          // label: am5.Label.new(root, {
          //   fill: am5.color(0xffffff)
          // })
        })
      })
    );

    series.bullets.push(() => {
      let circleTemplate = am5.Template.new({
        radius: 3,
        templateField: 'bulletSettings',
        fill: am5.color('#9DBAE9'),
        strokeWidth: 1,
        stroke: root.interfaceColors.get('background')
      });

      let circle = am5.Circle.new(root, {}, circleTemplate);

      //fill color if bulletSettings is present in the data
      circle.adapters.add('fill', (fill, target) => {
        let dataItem = target.dataItem;
        if (dataItem && dataItem.dataContext.bulletSettings) {
          return am5.color(dataItem.dataContext.bulletSettings);
        }
        return fill;
      });

      return am5.Bullet.new(root, {
        sprite: circle,
        locationX: 0
      });
    });

    function createGuide(value, text, dashArray) {
      let guideDataItem = yAxis.makeDataItem({ value: value });
      yAxis.createAxisRange(guideDataItem);
      guideDataItem.get('grid').setAll({
        forceHidden: false,
        strokeOpacity: 0.5,
        strokeDasharray: dashArray
      });

      let label = guideDataItem.get('label');
      label.setAll({
        text: text,
        isMeasured: false,
        centerY: am5.p100
      });

      label.adapters.add('x', () => chart.plotContainer.width());

      chart.events.on('boundschanged', () => {
        label.set('x', label.get('x'));
      });
    }

    createGuide(guideValue, `Median: ${guideValue?.toFixed(2)}`, [8, 3]);

    const formattedData = rawData.map((item) => ({
      ...item,
      timestamp: new Date(item.timestamp).getTime(),
      formattedDate: moment(new Date(item.timestamp)).format('ddd, MMM D, YYYY')
    }));

    series.data.setAll(formattedData);

    // // series.appear(1000);
    // chart.appear(1000, 100);

    // Format the x-axis labels using moment
    xAxis.get('renderer').labels.template.adapters.add('text', (text, target) => {
      let date = target?.dataItem?.get('value');
      return moment(date).format('ddd, MMM D');
    });

    const downloadedData = rawData?.map((item) => ({
      Date: moment(item?.date).format('ddd, MMM D'),
      Value: item?.value
    }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: downloadTitle,
      dataSource: downloadedData,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, [rawData]);

  return <div id={id} style={{ width: '100%', height: '300px' }}></div>;
};

export default ControlChart;
