// material-ui
import { Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/system';

// ==============================|| SKELETON - InsightCard||============================== //

const InsightCardSkeleton = () => (
  <Card sx={{ width: '100%' }}>
    <CardContent>
      <Grid container direction="column">
        <Grid item>
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} width={'30%'} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton variant="rectangular" height={24} width={'50%'} />
            <Skeleton variant="rectangular" height={24} width={'20%'} />
          </Box>
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={96} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={56} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={34} width={'40%'} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default InsightCardSkeleton;
