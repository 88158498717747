import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { Box, CircularProgress, DialogContent, DialogTitle, Drawer } from '@mui/material';

import { createAction, taskDetailCardApiCalls } from 'api/taskManagement.service';
import { INITIAL_ACTION_FORM } from 'constants/TaskManagement/forms';
import useNotification from 'hooks/useNotifications';
import { StyledButton, StyledTypography } from 'styled-components/global';
import FadeInDiv from 'ui-component/FadeInDiv';
import GlobalForm from 'ui-component/forms/GlobalForm';
import { cleanPayload } from 'utils';
import { getUserFormSelectInput } from 'utils/createAction';
import { useFunctionContext } from 'views/common/FunctionContext';

export default function CreateAction({ open, handleClose, handleRefreshTable }) {
  // * This component is a mess, will refactor it once we get the time
  // Todo for Mohit
  const [loading, setLoading] = useState(false);
  const [formConfig, setFormConfig] = useState(INITIAL_ACTION_FORM);
  const [formLoading, setFormLoading] = useState({
    value: false,
    text: 'Loading...'
  });
  const { error: errorNotification, success: successNotification } = useNotification();
  const { taskId } = useParams();
  const formRef = useRef(null);
  const { sharedFunction } = useFunctionContext() || {};
  const { handleRefreshHybridCards } = sharedFunction || '';
  const [assetOrUsersCtx, setAssetOrUsersCtx] = useState('assets');

  const [formValues, setFormValues] = useState({
    assetsOrUsers: assetOrUsersCtx || 'assets',
    actionType: '',
    assetType: '',
    selectUser: '',
    selectAsset: ''
  });

  useEffect(() => {
    if (formValues.assetType) {
      setFormValues((prevValues) => ({
        ...prevValues,
        affectedAsset: ''
      }));
    }
  }, [formValues.assetType]);

  const handleFormUpdateAfterTaskInfoFetch = (data) => {
    const { assetsOrUsers, impactType, lastActionType } = data;
    if (assetsOrUsers) setAssetOrUsersCtx(assetsOrUsers);
    // Update the assetOrUsersCtx with the fetched value
    if (assetsOrUsers) {
      setFormValues((prev) => ({
        ...prev,
        assetsOrUsers: assetsOrUsers
      }));
      // disable the switch if the context is set below
      setFormConfig((prev) => {
        // dhyan do
        const updatedConfig = prev.map((field) => {
          if (field.name === 'assetsOrUsers') {
            field.disabled = true;
          }
          return field;
        });
        return updatedConfig;
      });
    } else {
      setFormConfig((prev) => {
        const updatedConfig = prev.map((field) => {
          if (field.name === 'assetsOrUsers') {
            field.disabled = false;
          }
          return field;
        });
        return updatedConfig;
      });
    }
    if (!assetsOrUsers) {
      setFormConfig((prev) => {
        const updatedConfig = prev.map((field) => {
          if (field.name === 'assetsOrUsers') {
            field.disabled = false;
          }
          return field;
        });
        return updatedConfig;
      });
    }

    if (lastActionType) {
      setFormValues((prev) => ({
        ...prev,
        actionType: lastActionType
      }));
    }

    // Update the form label for impact type
    if (impactType) {
      setFormConfig((prev) => {
        // Update the impact value label to include the impact type
        const updatedConfig = prev.map((field) => {
          if (field.name === 'impact') {
            field.labelText = `Impact value (${impactType})`;
          }
          return field;
        });
        return updatedConfig;
      });
    }
  };

  useEffect(() => {
    setFormLoading({
      value: true,
      text: 'Loading Task Info'
    });
    taskDetailCardApiCalls
      .getTaskInfo(taskId)
      .then((res) => {
        if (res?.status !== 200) {
          errorNotification('Failed to fetch Task Type!', 3000);
        } else {
          handleFormUpdateAfterTaskInfoFetch(res?.data);
        }
      })
      .catch(() => {
        errorNotification('Failed to fetch Task Type!', 3000);
      })
      .finally(() => {
        setFormLoading({
          value: false,
          text: ''
        });
      });
  }, [taskId]);

  useEffect(() => {
    let userInput = [];

    let updatedConfig = [...formConfig];
    const fun = async () => {
      if (formValues['assetsOrUsers'] === 'users' && !formConfig.find((input) => input.name === 'affectedUser')) {
        updatedConfig = updatedConfig.filter((field) => field.name !== 'assetType');
        userInput = getUserFormSelectInput({
          formKeyName: 'affectedUser',
          formLabelText: 'Select User',
          gridSize: 12
        });
        updatedConfig.splice(1, 0, userInput);
      } else if (formValues['assetsOrUsers'] === 'assets' && !formConfig.find((input) => input.name === 'assetType')) {
        updatedConfig = updatedConfig.filter((field) => field.name !== 'affectedUser');

        // If it is assets, we add a hardwarware/software dropdown, i.e. assetType
        // and add it to the form
        updatedConfig.splice(1, 0, {
          name: 'assetType',
          labelText: 'Select Asset Type',
          type: 'tenant-asset-types',
          required: true,
          grid: { xs: 12, sm: 12, md: 12 }
        });
      }
      setFormConfig([...updatedConfig]);
    };
    fun();
  }, [sharedFunction, formValues]);

  // Below useEffect will add a 'select asset' field in the form when user selects 'assets' in assetsOrUsers field
  // i.e. the assets or user switch
  useEffect(() => {
    if (formValues['assetsOrUsers'] === 'assets' && formValues['assetType']) {
      const updatedConfig = formConfig.filter((field) => field.name !== 'affectedAsset');
      updatedConfig.splice(2, 0, {
        name: 'affectedAsset',
        labelText: 'Select Asset',
        type: 'autocomplete-with-api',
        apiPath: `api/v1/list/assets?asset_type=${formValues['assetType']}`,
        searchKey: 'assetName',
        grid: { xs: 12, sm: 12, md: 12 }
      });
      setFormConfig(updatedConfig);
    }
  }, [formValues['assetType']]);

  // Below useEffect calls API when user changes the value of Asset Type
  // i.e. Hardware or Software dropdown

  const handleChange = async (event) => {
    const { name, value } = event.target;
    let userInput = {};

    setFormValues({
      ...formValues,
      [name]: value
    });

    //  When assetsOrUser switch is used, we check if value is 'users' or 'assets'
    if (name === 'assetsOrUsers') {
      let updatedConfig = [...INITIAL_ACTION_FORM];

      // If it is users, we fetch the user list and add it to the form
      if (value === 'users') {
        setFormLoading({
          value: true,
          text: 'Loading Users List'
        });
        try {
          userInput = await getUserFormSelectInput({
            formKeyName: 'affectedUser',
            formLabelText: 'Select User',
            gridSize: 12
          });
        } catch (err) {
          errorNotification('Failed to fetch users list', 3000);
        } finally {
          setFormLoading({
            value: false,
            text: ''
          });
          updatedConfig.splice(1, 0, userInput);
        }
      } else if (value === 'assets') {
        // If it is assets, we add a hardwarware/software dropdown, i.e. assetType
        // and add it to the form
        updatedConfig.splice(1, 0, {
          name: 'assetType',
          labelText: 'Select Asset Type',
          type: 'tenant-asset-types',
          required: true,
          grid: { xs: 12, sm: 12, md: 12 }
        });
      }

      // Only set the updatedConfig after the API calls have completed
      setFormConfig([...updatedConfig]);
    }
  };

  const handleSubmit = (values) => {
    const request = {
      assetsOrUsers: formValues?.assetsOrUsers,
      ...(formValues?.assetsOrUsers === 'assets' && {
        affectedAsset: cleanPayload({
          ...values,
          ...values?.affectedAsset,
          affectedAsset: undefined
        })
      }),
      ...(formValues?.assetsOrUsers === 'users' && {
        affectedUser: cleanPayload({
          ...values,
          name: values?.affectedUser?.userName,
          ...values?.affectedUser,
          userName: undefined,
          affectedUser: undefined
        })
      })
    };

    setLoading(true);
    createAction(taskId, request)
      .then((res) => {
        if (res?.status === 200) {
          successNotification('Action created successfully', 3000);
          handleClose();
          if (handleRefreshTable) handleRefreshTable();
          if (handleRefreshHybridCards) {
            handleRefreshHybridCards('getTaskChartData');
            handleRefreshHybridCards('getTaskLogs');
            handleRefreshHybridCards('getTaskInfo');
          }
        } else {
          errorNotification('Failed to create action', 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        errorNotification('Failed to create action', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseForm = () => {
    handleClose();
    formRef.current.resetForm({
      values: {
        assetsOrUsers: assetOrUsersCtx || 'assets',
        actionType: '',
        assetType: '',
        affectedUser: '',
        affectedAsset: '',
        current: '',
        target: '',
        actionDescription: '',
        note: '',
        impact: ''
      }
    });
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ width: 500 }} height="100%">
        <DialogTitle variant="h3">Create new Action</DialogTitle>
        {formLoading.value ? (
          <FadeInDiv>
            <Box display="flex" rowGap={2} flexDirection={'column'} justifyContent="center" alignItems="center" height="90%">
              <CircularProgress />
              <StyledTypography size="md">{formLoading.text}</StyledTypography>
            </Box>
          </FadeInDiv>
        ) : (
          <>
            <DialogContent>
              <GlobalForm
                emptyValues={{
                  assetsOrUsers: assetOrUsersCtx || 'assets',
                  actionType: '',
                  assetType: '',
                  affectedUser: '',
                  affectedAsset: '',
                  current: '',
                  target: '',
                  actionDescription: '',
                  note: '',
                  impact: ''
                }}
                ref={formRef}
                resetOnSubmit
                formData={formConfig}
                initialValues={formValues}
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                <Box display={'flex'} columnGap={2} justifyContent={'flex-end'}>
                  <StyledButton onClick={handleCloseForm} variant="outlined" disabled={loading}>
                    <span>Cancel</span>
                  </StyledButton>

                  <StyledButton variant="contained" type="submit" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : <span>Submit</span>}
                  </StyledButton>
                </Box>
              </GlobalForm>
            </DialogContent>
          </>
        )}
      </Box>
    </Drawer>
  );
}
