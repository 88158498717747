import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5radar from '@amcharts/amcharts5/radar';
// import * as am5flow from '@amcharts/amcharts5/flow';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

const RadialHistogram = ({ data, downloadTitle = 'Chart' }) => {
  const id = uuid();
  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(id);

    // Create custom theme
    // https://www.amcharts.com/docs/v5/concepts/themes/#Quick_custom_theme
    const myTheme = am5.Theme.new(root);
    myTheme.rule('Label').set('fontSize', 10);
    myTheme.rule('Grid').set('strokeOpacity', 0.06);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    // tell that valueX should be formatted as a date (show week number)

    document.getElementById('chartdiv');

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        startAngle: -84,
        endAngle: 264,
        innerRadius: am5.percent(40)
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      'cursor',
      am5radar.RadarCursor.new(root, {
        behavior: 'zoomX'
      })
    );
    cursor.lineY.set('forceHidden', true);

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
        exportable: false
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 30
    });

    xRenderer.grid.template.set('forceHidden', true);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: xRenderer
      })
    );

    let yRenderer = am5radar.AxisRendererRadial.new(root, {});
    yRenderer.labels.template.set('centerX', am5.p50);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        min: 0,
        renderer: yRenderer
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        name: 'Series 1',
        sequencedInterpolation: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        categoryXField: 'category'
      })
    );

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadius: 5,
      tooltipText: '{categoryX}: {valueY}'
    });

    // Make each column to be of a different color
    series.columns.template.adapters.add('fill', function (fill, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add('stroke', function (stroke, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target));
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    // series.appear(1000);
    chart.appear(1000, 100);

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      },
      filePrefix: downloadTitle
    });

    return () => {
      root.dispose();
    };

    // eslint-disable-next-line
  }, [data]);

  return <div id={id} style={{ width: '100%', height: '320px' }} />;
};

export default RadialHistogram;
