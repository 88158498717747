import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

import { benchMarkVarianceToSeries } from 'utils/graphUtils';

const VarianceChart = ({ data, xLabel = '', yLabel = '', xLabelRotation = -45, yFormat = 'number' }) => {
  const amchartData = benchMarkVarianceToSeries(data);
  const id = useRef(uuid());
  const xAxisRef = useRef(null);
  const series1Ref = useRef(null);
  const series2Ref = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    let root = am5.Root.new(id.current);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        paddingLeft: 0,
        paddingRight: 1
      })
    );

    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.1,
      strokeWidth: 1,
      minGridDistance: 30,
      minorGridEnabled: false
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.push(
      am5.Label.new(root, {
        text: amchartData.X_axis,
        x: 0
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      // centerX: 0, // Label starts at its left side
      dx: -10,
      rotation: xLabelRotation,
      oversizedBehavior: 'truncate',
      maxHeight: 120,
      maxWidth: 100,
      ellipsis: '...'
    });

    xAxis.data.setAll(amchartData.graphData);

    var yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: yFormat === 'currency' ? "'$'#a" : '#a',
        maxPrecision: 0,
        maxDeviation: 0.3,
        renderer: yRenderer,
        min: 0
      })
    );

    yAxis.children.push(
      am5.Label.new(root, {
        text: amchartData.Y_axis,
        rotation: -90,
        y: 200
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    var series1 = chart.series.push(
      // * This is the blue bar (currently actual data -> later benchmark data)
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 's1_value',
        sequencedInterpolation: true,
        categoryXField: 'category'
      })
    );

    series1.columns.template.setAll({
      strokeOpacity: 0,
      width: am5.percent(50),
      fill: am5.color('#2A76F4'),
      tooltipText: 'Expected Assets (Benchmark): {valueY}'
    });

    series1.columns.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color('#2A76F4'),
            offset: 0.1
          },
          {
            color: am5.color('#7FADF8')
          }
        ],
        rotation: 90
      })
    );

    var series2 = chart.series.push(
      // * This is the green bar (currently benchmark data -> later actual data)
      am5xy.ColumnSeries.new(root, {
        name: 'Series 2',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 's2_value',
        openValueYField: 's2_openValue',
        actualValueField: 's1_value',
        valueXField: 'category',
        sequencedInterpolation: true,
        categoryXField: 'category',
        clustered: false,
        // todo : not auto updating in real time - storybook
        fill: amchartData.isPositive ? '#5DB806' : '#FA2B2B'
      })
    );

    series2.columns.template.setAll({
      strokeOpacity: 0,
      width: am5.percent(30),
      tooltipText: amchartData?.isPositive
        ? 'Variance : {s2_variance}, Actual Assets: {s2_actualValue}'
        : 'Variance : {s2_variance}, Actual Assets: {s2_actualValue}'
    });

    xAxisRef.current = xAxis;
    series1Ref.current = series1;
    series2Ref.current = series2;

    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: data?.title,
      dataSource: [
        { ...data?.chartValues[0]?.values, category: 'values' },
        { ...data?.chartValues[0]?.variance, category: 'variance' }
      ],
      pngOptions: {
        quality: 1,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (xAxisRef.current && series1Ref.current && series2Ref.current) {
      xAxisRef.current.data.setAll(amchartData.graphData);
      series1Ref.current.data.setAll(amchartData.graphData);
      series2Ref.current.data.setAll(amchartData.graphData);
    }
  }, [amchartData]);

  return <div id={id.current} style={{ width: '100%', height: '500px' }} />;
};

export default VarianceChart;
