import { useState } from 'react';

export const useChartModal = (dataSource, columns, searchColumnKey) => {
  const [open, setOpen] = useState(false);
  const [selectedColumnData, setSelectedColumnData] = useState(null);

  const handleOpen = (column) => {
    setOpen(true);

    const searchColumn = columns?.find((col) => col.key === searchColumnKey);
    const categoryColumnTitle = searchColumn?.headerName;

    const chartValues = dataSource?.map((row) => ({
      category: String(row[searchColumn?.field || columns?.[0]?.field] || ''),
      value: typeof row[column.field] === 'number' ? row[column.field] : 1
    }));

    setSelectedColumnData({
      columnField: column.field,
      columnTitle: column.headerName,
      categoryColumnTitle: categoryColumnTitle,
      chartTypes: column.chartType || [{ value: 'barchart', label: 'Bar Graph' }],
      chartValues
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    selectedColumnData,
    handleOpen,
    handleClose
  };
};
