import React, { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

const SmoothLineAreaChart = ({ data, chartHeight = '300px', xLabel, yLabel, hideAxisLabels }) => {
  const pageType = useSelector((state) => state?.page?.page);
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(chartRef.current);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingBottom: 0
        // paddingLeft: 0
      })
    );
    var yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 50, strokeOpacity: 0.2, minorGridEnabled: false });
    yRenderer.grid.template.set('forceHidden', true);

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 50,
      strokeOpacity: 0.2,
      minorGridEnabled: false
    });
    xRenderer.grid.template.set('forceHidden', true);

    // Date Axis for X-axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: xRenderer,
        strictMinMax: false,
        maxPrecision: 0,
        dateFormats: {
          day: 'MMM d, yyyy'
        },
        periodChangeDateFormats: {
          day: 'MMM d, yyyy'
        },
        tooltipDateFormat: 'MMM d, yyyy',
        ...(data?.dataRange && {
          min: new Date(data.dataRange.start).getTime(),
          max: Math.max(...data.data.map((item) => new Date(item.xaxisValue).getTime()), new Date(data.dataRange.end).getTime())
        })
      })
    );

    xAxis.get('renderer').labels.template.setAll({
      labelText: '{valueX.formatDate("MMM d, yyyy")}',
      rotation: -55, // Vertical rotation
      centerY: am5.p100,
      centerX: am5.p50
    });

    // Add X-axis label
    if (xLabel && !hideAxisLabels) {
      xAxis.children.push(
        am5.Label.new(root, {
          text: xLabel,
          centerX: am5.p50,
          centerY: am5.p50,
          paddingRight: 10,
          fontSize: 16,
          fontWeight: 600,
          x: am5.p50,
          fill: am5.color('#7685A3')
        })
      );
    }

    // Create Y-axis (value axis)
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        min: 0,
        strictMinMax: false,
        maxPrecision: 0,
        ...(data?.totalCount && { max: data.totalCount + 2 })
      })
    );

    // Add Y-axis label
    if (yLabel && !hideAxisLabels) {
      yAxis.children.unshift(
        am5.Label.new(root, {
          text: yLabel,
          rotation: -90,
          y: am5.p50,
          centerY: am5.p50,
          paddingRight: 10,
          fontSize: 16,
          fontWeight: 600,
          centerX: am5.p50,
          fill: am5.color('#7685A3')
        })
      );
    }

    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'yaxisValue',
        valueXField: 'xaxisValue',
        fill: am5.color(0x67b7dc),
        stroke: am5.color(0x67b7dc),
        tooltip: am5.Tooltip.new(root, {
          labelText: `Completed : {valueY}
Date : {valueX.formatDate('MMM d, yyyy')}`
        })
      })
    );

    series.fills.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            opacity: 0.4
          },
          {
            opacity: 0
          }
        ]
      })
    );

    // Add bullets at data points
    series.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 4,
          fill: am5.color(0x67b7dc)
        })
      });
    });

    // Smoothing tension
    series.setAll({
      tensionX: 0.8 // Adjust for smoother line (0.1-1 range)
    });
    series.fills.template.setAll({ fillOpacity: 0.1, visible: true });

    function createGuide(value, text) {
      let guideDataItem = yAxis.makeDataItem({ value: value });
      yAxis.createAxisRange(guideDataItem);
      guideDataItem.get('grid').setAll({
        forceHidden: false,
        strokeOpacity: 0.5
        // strokeDasharray:
      });

      let label = guideDataItem.get('label');
      label.setAll({
        text: text,
        isMeasured: false,
        centerY: am5.p100
      });

      label.adapters.add('x', () => chart.plotContainer.width());

      chart.events.on('boundschanged', () => {
        label.set('x', label.get('x'));
      });
    }

    if (data?.totalCount) {
      createGuide(data?.totalCount, `Total ${pageType === 'tasks' ? 'Tasks' : 'Actions'} : ${data?.totalCount}`, [8, 3]);
    }

    // Sort and format data to epoch

    const chartData =
      data?.data && data?.dataRange && data?.data?.length > 0
        ? data.data
        : [
            { xaxisValue: data?.dataRange?.start, yaxisValue: -2 },
            { xaxisValue: data?.dataRange?.end, yaxisValue: -2 }
          ];
    const formattedData = chartData
      ?.sort((a, b) => new Date(a.xaxisValue) - new Date(b.xaxisValue))
      ?.map((item) => ({
        ...item,
        xaxisValue: new Date(item.xaxisValue).getTime()
      }));

    // Add data to series
    series.data.setAll(formattedData);

    // Cursor for interactivity
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
        lineX: { visible: false },
        lineY: { visible: false }
      })
    );

    return () => {
      root.dispose();
    };
  }, [data, xLabel, yLabel]);

  return <div ref={chartRef} style={{ width: '100%', height: chartHeight }}></div>;
};

export default SmoothLineAreaChart;
