import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { GridColumnMenu } from '@mui/x-data-grid';
import { Graph } from 'iconsax-react';

function CustomUserItem(props) {
  const { onClick, column } = props;

  const handleClick = () => {
    onClick(column);
  };

  if (!column?.graph) {
    return null;
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <Graph size="1.25rem" variant="Bold" />
      </ListItemIcon>
      <ListItemText>Graph</ListItemText>
    </MenuItem>
  );
}

function CustomColumnMenu(props) {
  const { handleOpen } = props;

  if (!props.colDef?.graph) {
    return <GridColumnMenu {...props} />;
  }

  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuUserItem: (props) => <CustomUserItem {...props} onClick={handleOpen} column={props.colDef} />
      }}
      slotProps={{
        columnMenuUserItem: {
          displayOrder: 1
        }
      }}
    />
  );
}

export { CustomColumnMenu };
