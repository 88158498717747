import { createHeadersWithToken } from 'api/api';
import axios from 'api/interceptors';
import { configuration } from 'services/baseApiService';

import { getNameGetResponse, listGetResponse } from './dataProducts.zod';

/**
 * Fetch data products
 * @returns {Promise<Z.infer<typeof listGetResponse>>} The data products
 */
export const fetchDataProducts = async () => {
  try {
    const response = await axios.get(configuration.dataProductsBaseUrl, createHeadersWithToken());
    const responseData = listGetResponse.parse(response.data);
    return responseData;
  } catch (error) {
    throw error.response ? error.response.data : console.error('Failed to fetch data products');
  }
};

/**
 * Fetch data product details
 * @param {string} name - The name of the data product
 * @returns {Promise<Z.infer<typeof getDataproductNameGetResponse>>} The data product details
 */
export const fetchDataProductDetails = async (name) => {
  try {
    const response = await axios.get(`${configuration.dataProductsBaseUrl}${name}`, createHeadersWithToken());
    const responseData = getNameGetResponse.parse(response.data);
    return responseData;
  } catch (error) {
    throw error.response ? error.response.data : console.error('Failed to fetch data product details');
  }
};

/**
 * Fetch data product table data
 * @param {string} dataProductName - The name of the data product
 * @param {string} tableId - The id of the table
 * @param {Z.infer<typeof getTableDpNameTablesTableIdPostBody>} data - The query to fetch the data
 * @returns {Promise<Z.infer<typeof getTableDpNameTablesTableIdPostResponse>>} The data product table data
 */
export const fetchDataProductTableData = async (dataProductName, tableId, data) => {
  const headers = createHeadersWithToken();
  try {
    const { data: responseData } = await axios.post(
      `${configuration.dataProductsBaseUrl}${dataProductName}/tables/${tableId}`,
      data,
      headers
    );
    const { values, summary } = responseData;
    const { rows, totalCount } = values[0];
    return { rows, totalCount: totalCount[0]?.count || 0, summary };
  } catch (error) {
    throw error.response ? error.response.data : console.error('Failed to fetch data product table data');
  }
};

/**
 * Download data product table data
 * @param {string} dataProductName - The name of the data product
 * @param {string} tableId - The id of the table
 * @param {Z.infer<typeof getTableDpNameTablesTableIdPostBody>} data - The query to fetch the data
 * @returns {Promise<Z.infer<typeof getTableDpNameTablesTableIdPostResponse>>} Downloads the data product table data
 */
export const downloadDataProductTableData = async (dataProductName, tableId, data) => {
  const headers = createHeadersWithToken();
  try {
    const { data: responseData } = await axios.post(
      `${configuration.dataProductsBaseUrl}${dataProductName}/tables/${tableId}`,
      data,
      headers
    );
    const { values } = responseData;
    return values;
  } catch (error) {
    throw error.response ? error.response.data : console.error('Failed to download data product table data');
  }
};

/**
 * Fetch data product chart data
 * @param {string} dataProductName - The name of the data product
 * @param {string} chartId - The id of the chart
 * @param {Z.infer<typeof getChartDpNameChartsChartIdPostBody>} data - The query to fetch the data
 * @returns {Promise<Z.infer<typeof getChartDpNameChartsChartIdPostResponse>>} The data product chart data
 */
export const fetchDataProductChartData = async (dataProductName, chartId, data) => {
  const headers = createHeadersWithToken();
  try {
    const { data: responseData } = await axios.post(
      `${configuration.dataProductsBaseUrl}${dataProductName}/charts/${chartId}`,
      data,
      headers
    );
    const { values } = responseData;
    return values;
  } catch (error) {
    throw error.response ? error.response.data : console.error('Failed to fetch data product chart data');
  }
};

/**
 * Fetch data product Insight data
 * @param {string} dataProductName - The name of the data product
 * @param {string} insightId - The id of the insight
 * @param {Z.infer<typeof getInsightDpNameInsightsInsightIdPostBody>} data - The query to fetch the data
 * @returns {Promise<Z.infer<typeof getInsightDpNameInsightsInsightIdPostResponse>>} The data product insight data
 */
export const fetchDataProductInsightData = async (dataProductName, insightId, data) => {
  const headers = createHeadersWithToken();
  try {
    const { data: responseData } = await axios.post(
      `${configuration.dataProductsBaseUrl}${dataProductName}/insights/${insightId}`,
      data,
      headers
    );
    return responseData;
  } catch (error) {
    throw error.response ? error.response.data : console.error('Failed to fetch data product insight data');
  }
};
