import { Paper } from '@mui/material';

const PaperCard = ({ children, padding = '24px', borderRadius = '16px', height = '100%', onClick }) => {
  return (
    <Paper
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        p: padding,
        borderRadius,
        bgcolor: '#F4F8FE',
        flex: 1,
        height,
        boxSizing: 'border-box'
      }}
    >
      {children}
    </Paper>
  );
};

export default PaperCard;
