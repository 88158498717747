import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

const Pareto = ({ data, downloadTitle = 'Chart' }) => {
  const id = uuid();

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    am5.ready(function () {
      let root = am5.Root.new(id);
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingLeft: 0,
          paddingRight: 0,
          layout: root.verticalLayout
        })
      );

      // Function to calculate Pareto values
      function prepareParetoData() {
        let total = data.reduce((acc, cur) => acc + cur.user_minutes, 0);
        let sum = 0;
        data.forEach((item) => {
          sum += item.user_minutes;
          item.pareto = (sum / total) * 100;
        });
      }

      prepareParetoData();

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 85,
        minorGridEnabled: true
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'app',
          renderer: xRenderer
        })
      );

      xRenderer.grid.template.setAll({
        location: 1
      });

      xRenderer.labels.template.setAll({
        paddingTop: 20
      });

      xAxis.data.setAll(data);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
          })
        })
      );

      var paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
      var paretoAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: paretoAxisRenderer,
          min: 0,
          max: 100,
          strictMinMax: true
        })
      );

      paretoAxisRenderer.grid.template.set('forceHidden', true);
      paretoAxis.set('numberFormat', "#'%");
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'user_minutes',
          categoryXField: 'app'
        })
      );

      series.columns.template.setAll({
        tooltipText: '{categoryX}: {valueY}',
        tooltipY: 0,
        strokeOpacity: 0,
        cornerRadiusTL: 6,
        cornerRadiusTR: 6
        // fill: root.interfaceColors.get('primaryButtonHover')
      });

      series.columns.template.set(
        'fillGradient',
        am5.LinearGradient.new(root, {
          stops: [
            { color: am5.color('#2A76F4'), offset: 0 },
            { color: am5.color('#7FADF8'), offset: 1 }
          ],
          rotation: 90
        })
      );

      var paretoSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: paretoAxis,
          valueYField: 'pareto',
          categoryXField: 'app',
          stroke: root.interfaceColors.get('alternativeBackground'),
          maskBullets: false
        })
      );

      paretoSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 6,
            fill: am5.color('#D4E4FD'),
            stroke: root.interfaceColors.get('alternativeBackground')
          })
        });
      });

      xAxis.data.setAll(data);
      series.data.setAll(data);
      paretoSeries.data.setAll(data);

      // series.appear();
      paretoSeries.appear();
      chart.appear(1000, 100);

      am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: data,
        filePrefix: downloadTitle,
        pngOptions: {
          quality: 0.8,
          maintainPixelRatio: true
        },
        csvOptions: {
          addBOM: true,
          addColumnNames: true
        },
        htmlOptions: {
          disabled: true
        },
        printOptions: {
          disabled: true
        },
        jpgOptions: {
          disabled: true
        },
        jsonOptions: {
          disabled: true
        },
        xlsxOptions: {
          disabled: true
        }
      });

      return () => root.dispose();
    });
    // eslint-disable-next-line
  }, [data]);

  return <div id={id} style={{ width: '100%', height: '500px' }}></div>;
};

export default Pareto;
