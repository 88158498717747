import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

const InvertedBarChart = ({ data, context, xLabel, yLabel, downloadTitle = 'Chart' }) => {
  const id = uuid();

  const yAxisRef = useRef(null);
  const seriesRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    let root = am5.Root.new(id);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        // wheelX: 'panX',
        // wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1
      })
    );

    var cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineX.set('visible', false);

    // Switched to ValueAxis for X (horizontal)
    var xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.setAll({
      fill: am5.color(context === 'GraphicalInsights' ? '#DBE8FE' : '#292D32')
    });
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer
      })
    );
    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    // Switched to CategoryAxis for Y (vertical)
    var yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30
    });
    yRenderer.labels.template.setAll({
      rotation: 0,
      paddingRight: 15,
      fill: am5.color(context === 'GraphicalInsights' ? '#FFFFFF' : '#292D32')
    });

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'yaxisValue',
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      0
    );
    yAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxWidth: 130,
      ellipsis: '...'
    });

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'xaxisValue',
        sequencedInterpolation: true,
        categoryYField: 'yaxisValue',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueX}'
        })
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      height: am5.percent(50),
      fill: am5.color('#2A76F4'),
      cornerRadiusTR: 50,
      cornerRadiusBR: 50
    });

    series.columns.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(context === 'GraphicalInsights' ? '#DBE8FE' : '#2A76F4'),
            offset: 0.1
          },
          {
            color: am5.color('#7FADF8')
          }
        ],
        rotation: 0
      })
    );

    yAxisRef.current = yAxis;
    seriesRef.current = series;

    // yAxis.data.setAll(data);
    // series.data.setAll(data);

    // series.appear(1000);
    chart.appear(1000, 100);

    const downloadedData = data?.map((item) => ({ [yLabel]: item?.yaxisValue, [xLabel]: item?.xaxisValue }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: downloadedData,
      filePrefix: downloadTitle,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    yAxisRef.current.data.setAll(data);
    seriesRef.current.data.setAll(data);
  }, [data]);

  return (
    <>
      <div id={id} style={{ width: '100%', height: '500px' }} />
    </>
  );
};

export default InvertedBarChart;
