import { lazy } from 'react';
// import CardDetail from 'views/cardDetail';
//import InsightDetailV2 from 'ui-component/insights/InsightDetailV2';
//import CommonView from 'views/common';
//import ManualInsightDetail from 'ui-component/insights/ManualInsight';
import { Cardano, Chart2, CpuSetting, Home, Layer, Link2, MessageQuestion, Monitor, ReceiptText, Task } from 'iconsax-react';
import { AppLayout } from 'layout/AppLayout';
// import MainLayout from 'layout/MainLayout';
import { sidebarIcons } from 'layout/MainLayout/Sidebar/PageList/sidebarData';

import DataProductDetailPage from '@features/DataProducts/DataProductDetail/DataProductDetailPage';
import { DATA_PRODUCTS_ROUTES } from '@features/DataProducts/dataProducts.routes';

// import ErrorPage from 'pages/data-not-found';
import { AccessIcon, AskAsatoIcon } from 'ui-component/icons/common';
import Loadable from 'ui-component/Loadable';
import CatalogList from 'views/Catalog/CatalogList';
import AskAsatoService from 'views/micro-frontend/AskAsato';

import { AskAsatoStubDataTenantId } from './askAsatoConfig';
import RequireAuth from './Protected';
const PageNotFound = Loadable(lazy(() => import('pages/no-page-found')));
const Analysis = Loadable(lazy(() => import('views/analysis')));
// const Report = Loadable(lazy(() => import('views/report')));
// const Help = Loadable(lazy(() => import('pages/help')));
const APITable = Loadable(lazy(() => import('views/admin/apps/linkage/linkageBlock/APITableContainer')));
const ViewAll = Loadable(lazy(() => import('views/admin/apps/linkage/viewAll/ViewAll')));
// const ReportTracker = Loadable(lazy(() => import('views/report-tracker/indexTracker')));
// const DataDisplay = Loadable(lazy(() => import('ui-component/decisionTracker/decision/DecisonData')));
const DataProductList = Loadable(lazy(() => import('views/DataProductList')));
const DataProductLazy = Loadable(lazy(() => import('views/data-product')));
// import AskAsato from 'air/AskAsato';
// const IndexDecisionTracker = Loadable(lazy(() => import('ui-component/decisionTracker/history/indexDecisionHistory')));
// const UpdateReport = Loadable(lazy(() => import('ui-component/report/selectedReport/selectedReport')));
const CreateLink = Loadable(lazy(() => import('ui-component/Sections/Layout')));
// const AssetView = Loadable(lazy(() => import('views/assets/AssetView')));
const CommonView = Loadable(lazy(() => import('views/common')));
const CardDetail = Loadable(lazy(() => import('views/cardDetail')));
const Graph = Loadable(lazy(() => import('ui-component/Sections/Graph')));

const StubDataAskAsato = Loadable(lazy(() => import('views/askAsato')));

const {
  Home: HomeIcon,
  // Link: LinkIcon,
  Assets: AssetsIcon,
  Users: UsersIcon,
  Cost: CostIcon
  // Tracker: TrackerIcon,
  // Report: ReportIcon,
  // Ask: AskIcon
} = sidebarIcons;

const role = localStorage.getItem('persona');
const tenantId = localStorage.getItem('tenantId');

const commonRoutes = [
  {
    path: '/home',
    name: 'Home',
    // todo : <CommonView /> section will be applied for wellstreet only - in future, this hardcoding will be removed
    element: <CommonView />,
    sidebar: true,
    svg: <Home />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ],
    position: 'top',
    rolePermission: ['admin', 'executive', 'user']
  },
  {
    path: '/asset',
    name: 'asset',
    element: <CommonView />,
    sidebar: false,
    svg: <HomeIcon />,
    position: 'top',
    rolePermission: ['executive', 'user'],
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user']
      }
    ]
  },
  {
    path: '/link',
    name: 'Link',
    element: <CommonView />,
    sidebar: true,
    svg: <Link2 />,
    position: 'top',
    rolePermission: ['admin'],
    subRoutes: [
      {
        path: '/detail',
        rolePermission: ['admin'],
        element: <CommonView />,
        subRoutes: []
      },
      {
        path: '/detail/:id',
        rolePermission: ['admin'],
        element: <CommonView />,
        subRoutes: []
      },
      {
        path: '/:type/:step',
        rolePermission: ['admin'],
        element: <CreateLink />,
        subRoutes: []
      }
    ]
  },
  {
    path: '/apps',
    element: <CommonView />,
    rolePermission: ['admin', 'executive', 'user'],
    name: 'Apps',
    sidebar: true,
    position: 'top',
    svg: <AssetsIcon />,
    subRoutes: [
      {
        path: '/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/view-all',
        rolePermission: ['admin', 'executive', 'user'],
        element: <ViewAll />,
        subRoutes: []
      },
      {
        path: '/view-all/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/hardware',
    element: <CommonView />,
    name: 'Hardware',
    sidebar: true,
    position: 'top',
    rolePermission: ['user', 'executive'],
    svg: <Monitor />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/software',
    element: <CommonView />,
    name: 'Software',
    sidebar: true,
    position: 'top',
    rolePermission: ['user', 'executive'],
    svg: <CpuSetting />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user'],
        subRoutes: []
      }
    ]
  },
  ...DATA_PRODUCTS_ROUTES,
  {
    path: '/usage',
    element: <CommonView />,
    name: 'Usage',
    sidebar: true,
    position: 'top',
    rolePermission: ['admin', 'executive', 'user'],
    svg: <UsersIcon />,
    subRoutes: [
      {
        path: '/linkage-table',
        element: <APITable />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all',
        element: <ViewAll />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/access',
    element: <Analysis />,
    name: 'Access',
    position: 'middle',
    rolePermission: ['admin'],
    subRoutes: [],
    sidebar: true,
    svg: <AccessIcon />
  },
  {
    path: '/cost',
    element: <CommonView />,
    name: 'Costs',
    rolePermission: ['admin', 'executive', 'user'],
    sidebar: true,
    position: 'top',
    svg: <CostIcon />,
    subRoutes: [
      {
        path: '/linkage-table',
        element: <APITable />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all',
        element: <ViewAll />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/data-product',
    element: <DataProductList />,
    rolePermission: ['user', 'executive'],
    name: 'Data Products',
    sidebar: true,
    position: 'middle',
    svg: <Chart2 />,
    subRoutes: [
      {
        path: '/:dataProductId',
        rolePermission: ['user', 'executive'],
        element: <DataProductLazy />,
        name: 'Data Product'
      }
    ]
  },
  {
    path: import.meta.env.VITE_API_HELP,
    sidebar: true,
    name: 'Help',
    external: true,
    position: 'bottom',
    svg: <MessageQuestion />,
    rolePermission: ['admin', 'executive', 'user'],
    subRoutes: []
  },
  {
    path: '/ask-asato',
    beta: true,
    rolePermission: ['executive', 'user'],
    element: <AskAsatoService />,
    subRoutes: [
      {
        path: '/response',
        rolePermission: ['executive', 'user'],
        element: <AskAsatoService />,
        subRoutes: [],
        name: 'Ask Asato'
      }
    ],
    name: 'Ask Asato',
    sidebar: true,
    position: 'bottom',
    svg: <AskAsatoIcon />
  },
  {
    path: '/datasets',
    rolePermission: ['executive', 'user'],
    element: <CommonView />,
    subRoutes: [
      {
        path: '/list',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Asato Generated'
      },
      {
        path: '/catalogs',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Catalogs'
      },
      {
        path: '/list/:id',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Dataset Details'
      },
      {
        path: '/catalogs/:id',
        rolePermission: ['executive', 'user'],
        element: <CatalogList />,
        name: 'Dataset Details'
      }
    ],
    drawerChildren: [
      {
        path: '/datasets/list',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Asato Generated'
      },
      {
        path: '/datasets/catalogs',
        rolePermission: ['executive', 'user'],
        element: <CatalogList />,
        name: 'Catalogs'
      }
    ],
    name: 'Datasets',
    sidebar: true,
    position: 'middle',
    svg: <Layer />
  },
  {
    path: `/tasks`,
    beta: true,
    onClickPath: `/tasks?view=${role === 'executive' ? 'dashboard' : 'workflows'}`,
    rolePermission: ['executive', 'user'],
    element: <CommonView />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user']
      },
      {
        path: '/:taskId',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Task Details'
      }
    ],
    name: 'Tasks',
    sidebar: true,
    position: 'middle',
    svg: <Task />
  },
  {
    path: `/graph`,
    rolePermission: ['executive'],
    element: <Graph />,
    subRoutes: [],
    name: 'Graph',
    sidebar: true,
    position: 'middle',
    svg: <Cardano />
  },
  {
    path: '/asset/:assetType',
    name: 'asset_360',
    element: <CommonView />,
    rolePermission: ['executive', 'user'],
    sidebar: false,
    subRoutes: [
      {
        path: '/overview',
        rolePermission: ['executive', 'user'],
        element: <CardDetail />
      }
    ]
  },
  {
    path: `/invoice-list`,
    beta: true,
    onClickPath: `/invoice-list?invoice_type=${role === 'executive' ? 'new-invoice' : 'invoice'}`,
    rolePermission: ['executive', 'user'],
    name: 'Invoice',
    element: <CommonView />,
    sidebar: true,
    position: 'middle',
    svg: <ReceiptText />,
    subRoutes: [
      {
        path: '/:invoiceId',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Invoice Details'
      }
    ]
  },
  {
    path: '/data-products-new/:name',
    element: <DataProductDetailPage />,
    rolePermission: ['user', 'executive'],
    name: 'New Data Products'
  }
];
let configRoute;

try {
  configRoute = JSON.parse(localStorage.getItem('configRoutes'));
} catch (error) {
  configRoute = null;
}

function checkIfChildDrawerRoute(drawerChildren) {
  if (!drawerChildren && !drawerChildren?.length) {
    return [];
  }

  const route = [];
  drawerChildren?.forEach((child) => {
    if (child?.path && configRoute?.findIndex((item) => item.path === child.path) !== -1) {
      route.push(child);
    }
  });

  if (route?.length === 1) {
    return [];
  }

  return route;
}

const finialisedRoute = (routes = commonRoutes, baseUrl = '') => {
  let finalRoute = [];

  if (!configRoute) {
    localStorage.clear();
    return [];
  }

  routes &&
    routes?.forEach((route) => {
      let path = route.name === 'Help' ? import.meta.env.VITE_API_HELP : baseUrl + route.path;
      const persona = localStorage.getItem('persona');

      const configRouteIndex = configRoute ? configRoute?.findIndex((item) => item.path === path) : -1;

      if (persona && configRouteIndex !== -1 && configRoute[configRouteIndex].rolePermission.includes(persona)) {
        if (route?.path === '/ask-asato' && AskAsatoStubDataTenantId.includes(tenantId)) {
          finalRoute.push({
            onClickPath: route.onClickPath,
            path: path,
            element: <StubDataAskAsato />,
            svg: route.svg,
            name: route.name,
            position: route.position,
            icon: route.icon,
            sidebar: route.sidebar,
            beta: route.beta,
            external: route.external,
            children: checkIfChildDrawerRoute(route?.drawerChildren)
          });
        } else {
          finalRoute.push({
            onClickPath: route.onClickPath,
            path: path,
            element: route.element,
            svg: route.svg,
            name: route.name,
            position: route.position,
            icon: route.icon,
            sidebar: route.sidebar,
            beta: route.beta,
            external: route.external,
            children: checkIfChildDrawerRoute(route?.drawerChildren)
          });
        }
      }

      if (route?.subRoutes && route?.subRoutes?.length) {
        const subRoute = finialisedRoute(route.subRoutes, route.path);
        finalRoute = [...finalRoute, ...subRoute];
      }
    });

  return finalRoute;
};

const finalRoutes = finialisedRoute(commonRoutes);

finalRoutes.push({
  path: '*',
  rolePermission: ['admin', 'executive', 'user'],
  element: <PageNotFound />,
  subRoutes: []
});

export const allRoutes = finalRoutes;

const MainRoutes = {
  path: '/',
  element: (
    <RequireAuth>
      {/* <MainLayout /> */}
      <AppLayout />
    </RequireAuth>
  ),
  children: finalRoutes
};

export default MainRoutes;
