import React, { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { debounce } from 'lodash';

import { DataTable } from 'ui-component/table/DataTable/DataTable';

import { useDownloadDataProductTableData, useFetchDataProductTableData } from '../dataProducts.hooks';
import { downloadCSV, ExportIcon, processColumns } from '../dataProducts.utils';

/**
 *
 * @param {string} name - The name of the data product
 * @param {Z.infer<typeof getDataproductNameGetResponse.table[number] >} tableInfo - The table info data
 * @param {Z.infer<type of Z.object<{field: string, sort: string}>.optional>} defaultSort - The defaykt sort settings
 * @param {Object} dateRange - Date range for the table
 * @param {string} dateRange.start - Start date of the date range
 * @param {string} dateRange.end - End date of the date range
 * @returns
 */
export const DataProductTable = ({ name, tableInfo, defaultSort, dateRange }) => {
  const { id, info, columns, description } = tableInfo;

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10
  });
  // total rows needs to be state because during the refetching of data from the server tableData can be undefined
  // this will save the total rows count from last data fetch
  const [totalRows, setTotalRows] = useState(0);
  // {field: 'name', sort: 'asc'}
  const [sortSettings, setSortSettings] = useState(defaultSort);
  // Array of {items: [{field: 'name', value: 'value', operator: 'contains', id: 123}], logicOperator: 'and'}
  const [filters, setFilters] = useState({ items: [], logicOperator: 'and' });

  const {
    data: tableData,
    isLoading,
    refetch
  } = useFetchDataProductTableData(name, id, {
    skip: pagination.page * pagination.pageSize,
    limit: pagination.pageSize,
    sortSettings,
    filters,
    dateRange
  });

  const onDownloadSuccess = (data) => {
    downloadCSV(data, name);
  };

  const { mutate: downloadTableData, isPending: isDownloading } = useDownloadDataProductTableData(onDownloadSuccess);

  const handleDownload = () => {
    downloadTableData({
      name,
      tableId: id,
      query: { sortSettings, filters, dateRange }
    });
  };

  // Refetch the table data when the pagination, sort settings, or filters change
  useEffect(() => {
    refetch();
  }, [pagination, sortSettings, filters, refetch]);

  // Update the total rows when the table data changes
  useEffect(() => {
    if (tableData?.totalCount) {
      setTotalRows(tableData.totalCount);
    }
  }, [tableData?.totalCount]);

  // Update the filters when the filter model changes
  const onFilterChange = debounce(({ items, logicOperator }) => {
    setFilters({ items, logicOperator });
  }, 1000);

  // Process the columns and memoize the result
  const processedColumns = useMemo(() => processColumns(columns), [columns]);

  return (
    <DataTable
      title={info}
      description={description}
      data={tableData?.rows}
      columns={processedColumns}
      loading={isLoading}
      paginationModel={pagination}
      sortingMode="server"
      handleSorting={setSortSettings}
      onFilterChange={onFilterChange}
      tableTotalCount={totalRows}
      handlePageChange={setPagination}
      toolbarConfig={[
        {
          action: 'download',
          color: 'primary',
          label: 'Download',
          icon: <ExportIcon />,
          onClick: handleDownload,
          loading: isDownloading ? 'Downloading' : undefined
        }
      ]}
    />
  );
};
