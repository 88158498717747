import { useRef } from 'react';
import { useLayoutEffect, useMemo } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

import { formatCurrency } from 'utils';
import { generateDownloadData } from 'utils/graphUtils';

export const WaterFallChart = ({ data, xLabelRotation = -45, xLabel = '', yLabel = '', yFormat = 'number' }) => {
  const chartId = useMemo(() => uuid(), []);
  const seriesRef = useRef(null);
  const stepSeriesRef = useRef(null);
  const xAxisRef = useRef(null);

  const graphData = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        displayValue: yFormat === 'currency' ? formatCurrency(item.displayValue) : item.displayValue
      })),
    [data, yFormat]
  );

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(chartId);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0
      })
    );

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: xRenderer
      })
    );

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxHeight: 120,
      maxWidth: 90,
      ellipsis: '...'
    });

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xRenderer.labels.template.setAll({
      location: 0.1,
      rotation: xLabelRotation
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        numberFormat: yFormat === 'currency' ? "'$'#a" : '#a',
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 })
      })
    );

    yAxis.children.push(
      am5.Label.new(root, {
        text: data?.Y_axis,
        rotation: 270,
        y: 300
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        openValueYField: 'open',
        categoryXField: 'category',
        displayValueField: 'displayValue',
        fill: am5.color('#2A76F4'),
        tooltip: am5.Tooltip.new(root, {
          labelText: `[fontSize: 10px]{categoryX}: \n{displayValue}`
        })
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      tooltipX: am5.percent(50),
      tooltipY: am5.percent(0)
    });

    series.columns.template.adapters.add('tooltipText', (text, target) => {
      const dataItem = target?.dataItem;
      const dataContext = dataItem?.dataContext;
      const displayValue = dataContext?.displayValue;
      let categoryX = target?.dataItem?.get('categoryX');
      if (categoryX && categoryX.length > 15) {
        const half = Math.ceil(categoryX.length / 2);
        categoryX = categoryX.slice(0, half) + ' -\n- ' + categoryX.slice(half);
      }
      const toolTip = dataContext?.toolTip;

      let formattedDisplayValue = yFormat === 'currency' ? formatCurrency(displayValue) : displayValue;
      return `[fontSize: 10px]${categoryX}:\n${formattedDisplayValue}${toolTip ? '\n' + toolTip : ''}`;
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          fill: am5.color(0xffffff)
        })
      });
    });

    const stepSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'stepValue',
        categoryXField: 'category',
        noRisers: true,
        locationX: 0.65,
        stroke: root.interfaceColors.get('alternativeBackground')
      })
    );

    stepSeries.strokes.template.setAll({
      strokeDasharray: [3, 3]
    });

    xAxisRef.current = xAxis;
    seriesRef.current = series;
    stepSeriesRef.current = stepSeries;

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    // series.appear(1000);
    chart.appear(1000, 100);

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: data?.title,
      dataSource: generateDownloadData({ data: graphData, xLabel, yLabel, chartType: 'waterfall' }),
      pngOptions: {
        quality: 1,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };

    // eslint-disable-next-line
  }, [chartId, graphData, xLabel, yLabel, yFormat, xLabelRotation]);

  useLayoutEffect(() => {
    xAxisRef.current.data.setAll(graphData);
    seriesRef.current.data.setAll(graphData);
    stepSeriesRef.current.data.setAll(graphData);
    // eslint-disable-next-line
  }, [graphData]);

  return <div style={{ width: '100%', height: '500px' }} id={chartId}></div>;
};
