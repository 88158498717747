import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import usaLow from '@amcharts/amcharts5-geodata/usaLow';
import { Drawer } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowLeft } from 'iconsax-react';
import { v4 as uuid } from 'uuid';


import styles from './styling/mapBubbleChart.module.scss';
import Table from 'ui-component/Sections/Table';

const MapBubbleChart = ({ data, showTable = false, tableId = 'asset_drift_map_bubble', toolbarConfig }) => {
  const chartId = uuid(); // id for the initial chart
  const drawerChartId = uuid(); // separate id for the chart inside the drawer
  const [popUp, setPopUp] = useState(null);
  const [drawerChartRendered, setDrawerChartRendered] = useState(false); // New state to track if the chart in the drawer should render
  const createChart = (id) => {
    const element = document.getElementById(id);
    if (!element) return; // Prevent chart creation if the element is not yet mounted

    const root = am5.Root.new(id);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoAlbersUsa()
      })
    );

    // Create polygon series
    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: usaLow
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get('alternativeBackground'),
      fillOpacity: 0.15,
      strokeWidth: 0.5,
      stroke: root.interfaceColors.get('background')
    });

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}'
    });

    polygonSeries.mapPolygons.template.states.create('hover', {
      fill: am5.color(0x297373)
    });

    // Set map color to a blueish tone
    polygonSeries.mapPolygons.template.setAll({
      fill: am5.color(0x4a90e2), // Blueish color
      fillOpacity: 0.8, // Adjust the opacity as needed
      strokeWidth: 0.5,
      stroke: root.interfaceColors.get('background')
    });

    // Create series for circles (bubbles)
    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: 'value',
        latitudeField: 'latitude',
        longitudeField: 'longitude'
      })
    );

    bubbleSeries.bullets.push(() => {
      const circle = am5.Circle.new(root, {
        radius: 5,
        templateField: 'circleTemplate',
        tooltipText:
          '[bold]{city}[/]\nLocation: {location}\nOrganisation Owning: {owningOrganization}\nAssets Counts: {value}\nDrifted: {drifted}\nDrifted Percentage: {driftedPercentage}%'
      });

      // Add a click event to the circle
      circle.events.on('click', (ev) => {
        const dataItem = ev.target.dataItem.dataContext; // Access the data from the clicked point
        const assetId = dataItem.assetId; // Get the location field from the data
        const location = dataItem.location;
        if (assetId) setPopUp({ assetId, location }); // Set the location to the state to display in the popup
      });

      return am5.Bullet.new(root, {
        sprite: circle
      });
    });

    const chartValues = data.map((item) => {
      return {
        value: item.value,
        latitude: am5.type.toNumber(item.latitude),
        longitude: am5.type.toNumber(item.longitude),
        circleTemplate: { fill: item.drifted > 0 ? 'red' : 'green' },
        location: item.location,
        city: item.city,
        drifted: item.drifted,
        owningOrganization: item.owningOrganization,
        driftedPercentage: `${item.driftedPercentage}%`,
        assetId: item?.assetId || item?.asset_id
      };
    });

    bubbleSeries.data.setAll(chartValues);

    // Dynamically create legend from series data
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        y: am5.p100,
        layout: root.verticalLayout
      })
    );

    legend.data.setAll([
      {
        name: 'Drifted',
        fill: 'red'
      },
      {
        name: 'Undrifted',
        fill: 'green'
      }
    ]);

    return root;
  };

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE_MAP);
    const root = createChart(chartId);
    return () => {
      root && root.dispose();
    };
  }, []);

  useEffect(() => {
    if (popUp) {
      setDrawerChartRendered(true); // Trigger chart rendering in drawer when popUp is set
    } else {
      setDrawerChartRendered(false); // Clean up when drawer closes
    }
  }, [popUp]);

  useEffect(() => {
    if (drawerChartRendered) {
      const root = createChart(drawerChartId);
      return () => {
        root && root.dispose();
      };
    }
  }, [drawerChartRendered, drawerChartId]);

  return (
    <>
      <div id={chartId} className={styles.chart} />
      {showTable && (
        <Drawer anchor="right" open={!!popUp} onClose={() => setPopUp(null)}>
          {!!popUp && (
            <div className={styles.drawerContainer}>
              <div className={styles.chartContainer}>
                <Box>
                  <ArrowLeft size="2rem" className={styles.backIcon} onClick={() => setPopUp(null)} />
                </Box>
                <div id={drawerChartId} className={styles.chart} />
              </div>
              <div className={styles.tableContainer}>
                <h2 className={styles.popUpHeading}>{popUp?.location}</h2>
                <Table tableId={tableId} assetId={popUp?.assetId} />
              </div>
            </div>
          )}
        </Drawer>
      )}
    </>
  );
};

export default MapBubbleChart;
