import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { getColumnFilterOperators } from './DataTableFilters';
import { DataTablePagination } from './DataTablePagination';
import { DataTableToolbar } from './DataTableToolbar';
import styles from './Styles/DataGridTableStyles';

const DEFAULT_FILTER_MODEL = {
  items: [],
  logicOperator: 'and',
  quickFilterValues: []
};

/**
 * DataTable component for displaying data in a grid format with advanced features
 * @param {string} title - The title of the table [optional]
 * @param {string} description - The description of the table [optional]
 * @param {Array} data - Array of data objects to display in the table
 * @param {Array} columns - Array of column configuration objects
 * @param {boolean} loading - Loading state of the table
 * @param {Function} handlePageChange - Callback function for page change events
 * @param {Object} paginationModel - Configuration object for pagination
 * @param {boolean} hideFooter - Whether to hide the table footer
 * @param {Function} handleSorting - Callback function for sorting events
 * @param {boolean} disableColumnSorting - Whether to disable column sorting
 * @param {boolean} disableColumnFilter - Whether to disable column filtering
 * @param {Function} onFilterChange - Callback function for filter change events
 * @param {number} tableTotalCount - Total count of rows in the table
 * @param {Object} columnGroupingModel - Configuration for column grouping
 * @param {Object} toolbarConfig - toolbar config for custom action buttons
 * @returns {JSX.Element} DataTable component
 */

function DataTable({
  title,
  description,
  data,
  columns,
  loading,
  handlePageChange,
  paginationModel,
  hideFooter,
  handleSorting,
  disableColumnSorting,
  disableColumnFilter,
  onFilterChange,
  tableTotalCount,
  columnGroupingModel,
  toolbarConfig = []
}) {
  const defaultColumnVisibilityState = {};
  const updatedColumns = columns?.map((column) => {
    defaultColumnVisibilityState[column?.field] = column?.defaultVisible ?? true;
    return {
      ...column,
      filterOperators: getColumnFilterOperators(column)
    };
  });

  const [filterModel, setFilterModel] = useState(DEFAULT_FILTER_MODEL);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultColumnVisibilityState);

  const rows = Array.isArray(data)
    ? data?.map((data) => ({
        ...data,
        id: data?._id || data?.id || Math.random()
      }))
    : [];

  const handleFilterChange = (model) => {
    setFilterModel(model);
    onFilterChange(model);
  };

  const handleReset = () => {
    handleFilterChange(DEFAULT_FILTER_MODEL);
    setColumnVisibilityModel(defaultColumnVisibilityState);
  };

  return (
    <Box sx={styles.container}>
      <DataGrid
        columnGroupingModel={columnGroupingModel}
        rows={rows}
        columns={updatedColumns}
        loading={loading}
        paginationMode="server"
        pageSizeOptions={[5, 10, 25, 100]}
        onPaginationModelChange={handlePageChange}
        rowCount={tableTotalCount}
        onSortModelChange={handleSorting}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        filterModel={filterModel}
        sortingMode={'server'}
        disableRowSelectionOnClick
        disableDensitySelector
        hideFooter={hideFooter}
        rowHeight={60}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: defaultColumnVisibilityState
          },
          pagination: {
            paginationModel: { ...paginationModel, rowCount: tableTotalCount }
          }
        }}
        slots={{
          pagination: DataTablePagination,
          toolbar: (props) => (
            <DataTableToolbar
              {...props}
              title={title}
              description={description}
              toolbarConfig={toolbarConfig || []}
              onReset={handleReset}
            />
          )
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          },
          pagination: {
            totalCount: tableTotalCount
          }
        }}
        sx={styles.dataGrid}
        disableColumnSorting={disableColumnSorting}
        disableColumnFilter={disableColumnFilter}
        getRowId={(row) => row?._id || row?.id}
      />
    </Box>
  );
}

export { DataTable };
