/* eslint-disable  */
import React, { useRef, useLayoutEffect, useId } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

const BubbleChart = ({
  data,
  xLabel,
  yLabel,
  // xTooltipLabel,
  // yTooltipLabel,
  // valueLabel,
  title,
  downloadTitle = 'Chart',
  yFormat = 'number'
}) => {
  const id = useId();
  // console.log(data.map(({ xaxisValue: x, yaxisValue: y, ...rest }) => ({ x, y, color: '#eea638', ...rest })));
  const seriesRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    let root = am5.Root.new(id);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        // wheelX: 'zoomXY',
        pinchZoomX: true,
        pinchZoomY: true,
        autoResize: false
      })
    );

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {})
      })
    );

    xAxis?.children?.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      xAxis?.children?.length - 1
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: yFormat === 'currency' ? "'$'#a" : '#a',
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: false
        })
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      0
    );

    yAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxHeight: 120,
      maxWidth: 70,
      ellipsis: '...'
    });

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'y',
        valueXField: 'x',
        valueField: 'value',
        seriesTooltipTarget: 'bullet'
        // tooltip: am5.Tooltip.new(root, {
        //   pointerOrientation: 'horizontal',
        //   labelText: `[bold]{title}[/]\n{yaxisValueLabel}: {tooltipYAxisValue}\n{xaxisValueLabel}: {tooltipXAxisValue}\n{valueLabel}: {tooltipValue}`
        // })
      })
    );

    series.strokes.template.set('visible', false);

    var circleTemplate = am5.Template.new({});
    circleTemplate.adapters.add('fill', function (fill, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        return am5.Color.fromString(dataItem.dataContext.color);
      }
      return fill;
    });

    series.bullets.push(function () {
      var bulletCircle = am5.Circle.new(
        root,
        {
          radius: 5,
          fill: series.get('fill'),
          fillOpacity: 0.8
        },
        circleTemplate
      );
      bulletCircle.set(
        'tooltipText',
        `[bold]{title}[/]\n{yaxisValueLabel}: {tooltipYAxisValue}\n{xaxisValueLabel}: {tooltipXAxisValue}\n{valueLabel}: {tooltipValue}`
      );
      return am5.Bullet.new(root, {
        sprite: bulletCircle
      });
    });

    series.set('heatRules', [
      {
        target: circleTemplate,
        min: 3,
        max: 30,
        dataField: 'value',
        key: 'radius'
      }
    ]);

    // Set data
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Setting_data

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        snapToSeries: [series]
      })
    );

    // Add scrollbars
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      })
    );

    chart.set(
      'scrollbarY',
      am5.Scrollbar.new(root, {
        orientation: 'vertical'
      })
    );

    seriesRef.current = series;

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    // // series.appear(1000);
    // chart.appear(1000, 100);

    const downloadedData = data?.map((item) => ({
      [title]: item?.title,
      [item?.xaxisValueLabel]: item?.xaxisValue || 0,
      [item?.yaxisValueLabel]: item?.yaxisValue || 0,
      [item?.valueLabel]: item?.value || 0
    }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: downloadTitle,
      dataSource: downloadedData,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, [data]);

  useLayoutEffect(() => {
    seriesRef.current.data.setAll(
      data?.map(({ xaxisValue: x, yaxisValue: y, ...rest }) => ({
        x,
        y,
        color:
          '#' +
          Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, '0'),
        ...rest
      }))
    );
  }, [data]);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
};

export default BubbleChart;
