import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, MenuItem, Select } from '@mui/material';

import { getAssetTypes } from 'api/taskManagement.service';
import useNotification from 'hooks/useNotifications';
import { transformText } from 'utils';

import { LabelDisplay } from '.';

const TenantAssetTypesSelect = ({ formik, name, labelText, handleChange, required, ...props }) => {
  const [assetTypes, setAssetTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { error: errorNotification } = useNotification();

  useEffect(() => {
    setLoading(true);
    getAssetTypes()
      .then((res) => {
        if (res?.status === 200) {
          setAssetTypes(res.data || []);
        } else {
          errorNotification('Failed to fetch asset types', 3000);
        }
      })
      .catch(() => {
        errorNotification('Failed to fetch asset types', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <LabelDisplay label={labelText} required={required} forEle={name} />
      <Select
        id={name}
        name={name}
        value={formik.values[name]}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors[name])}
        fullWidth
        {...props}
      >
        {loading ? (
          <MenuItem disabled>Loading...</MenuItem>
        ) : (
          assetTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {transformText({ text: type, style: 'capitalize' })}
            </MenuItem>
          ))
        )}
      </Select>
      {formik.errors[name] && <FormHelperText error>{formik.errors[name]}</FormHelperText>}
    </Box>
  );
};

export default TenantAssetTypesSelect;
