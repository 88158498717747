import React from 'react';
import { Box, MenuItem, Pagination, Select, Typography } from '@mui/material';
import { gridPageCountSelector, gridPageSelector, gridPageSizeSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

const PAGE_SIZE_OPTIONS = [5, 10, 25, 100];

/**
 * DataTablePagination: Custom pagination component for DataGrid
 * @param {Object} props - Component props
 * @param {number} props.totalCount - Total number of rows in the dataset
 * @returns {JSX.Element} Custom pagination component with row selection and page navigation
 */

export const DataTablePagination = ({ totalCount }) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const rowCount = sessionStorage.getItem('totalCount') || totalCount;

  const startRow = page * pageSize + 1;
  const endRow = Math.min((page + 1) * pageSize, rowCount);

  const handlePageChange = (event, value) => {
    apiRef.current.setPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    apiRef.current.setPageSize(event.target.value);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ padding: '0 16px', width: '100%' }}>
      <Box display="flex" alignItems="center" gap="8px">
        <Typography variant="body2">Rows per page:</Typography>
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          variant="outlined"
          sx={{
            marginLeft: '8px',
            padding: '2px 4px',
            borderRadius: '4px',
            minWidth: '50px',
            height: '32px',
            lineHeight: '32px',
            fontSize: '0.875rem',
            boxShadow: 'none',
            '& .MuiSelect-select': {
              padding: '2px',
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ccc'
            }
          }}
        >
          {PAGE_SIZE_OPTIONS.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">
          {startRow} - {endRow} of {rowCount}
        </Typography>
        <Pagination count={pageCount} page={page + 1} onChange={handlePageChange} />
      </Box>
    </Box>
  );
};
