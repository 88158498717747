import React from 'react';
import { Grid } from '@mui/material';

import FadeInDiv from 'ui-component/FadeInDiv';
import SectionWrapper from 'ui-component/SectionWrapper';

import { getGridByIndexAndNumberOfCharts } from '../dataProducts.utils';

import { DataProductChart } from './DataProductChart';

/**
 *
 * @param {string} name - The name of the data product
 * @param {Z.infer<typeof getDataproductNameGetResponse.charts >} charts - the data products charts specifications
 * @param {Object} dateRange - Date range for the charts
 * @param {string} dateRange.start - Start date of the date range
 * @param {string} dateRange.end - End date of the date range
 * @returns
 */
export const DataProductChartsContainer = ({ name, charts, dateRange }) => {
  const numberOfCharts = charts.length;
  return (
    <FadeInDiv>
      <SectionWrapper variant={'box'} title={'Graphical Insights'} bordered={true} hasBackButton={false}>
        <Grid container spacing={2}>
          {charts.map((chart, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={getGridByIndexAndNumberOfCharts(index, numberOfCharts)}
              lg={getGridByIndexAndNumberOfCharts(index, numberOfCharts)}
              key={chart.id}
            >
              <DataProductChart name={name} chart={chart} dateRange={dateRange} />
            </Grid>
          ))}
        </Grid>
      </SectionWrapper>
    </FadeInDiv>
  );
};
