import { Box } from '@mui/material';
import { CloseCircle } from 'iconsax-react';

import { ERROR_MAP } from 'constants/error';
import { StyledErrorContainer, StyledErrorTextGroupContainer, StyledTypography } from 'styled-components/global';
import RemoteComponentError from 'ui-component/ErrorComponents/RemoteComponentError';

/*
An error page component that displays the error message based on the status code.
*/

const ErrorPage = ({ errorType, axiosError = {}, remoteComponentName = 'Ask Asato' }) => {
  let status;
  let error;

  switch (errorType) {
    case 'axiosError':
      status = axiosError?.response?.status || axiosError?.status;
      error = ERROR_MAP[status] || ERROR_MAP[500];
      break;
    case 'componentError':
      return (
        <StyledErrorTextGroupContainer>
          <StyledTypography weight={'bolder'} size={'large'}>
            Oops!
          </StyledTypography>
          <StyledTypography>Something went wrong. Please try again later.</StyledTypography>
        </StyledErrorTextGroupContainer>
      );
    case 'remoteComponentError':
      status = 500; // Assuming a default status for remote component errors
      error = ERROR_MAP[status] || ERROR_MAP[500];

      return <RemoteComponentError name={remoteComponentName} />;
    default:
      return null; // Handle unexpected error types
  }

  if (status && status !== 401 && status !== 403) {
    return (
      <StyledErrorContainer fullScreen>
        <Box className="error-container">
          <CloseCircle size={70} />
          <StyledErrorTextGroupContainer>
            {Object.keys(error?.text)?.map((item, index) => {
              return (
                <StyledTypography key={index} {...error?.styledProps[item]} dangerouslySetInnerHTML={{ __html: error?.text?.[item] }} />
              );
            })}
          </StyledErrorTextGroupContainer>
        </Box>
      </StyledErrorContainer>
    );
  }
};

export default ErrorPage;
