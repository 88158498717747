import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomActionModal from './CustomActionModal';
import { CustomColumnMenu } from './CustomColumnMenuComponents';
import customFilterOperators from './customFilterOperators';
import CustomPagination from './CustomPagination';
import CustomToolbar from './CustomToolbar';
import { useChartModal } from './hooks/useChartModal';
import styles from './Styles/DataGridTableStyles';

// const DYNAMIC_KEYS = ['bundle_apps', 'productNames', 'assignedToUsers'];

const calculateDynamicRowHeight = () => {
  return 60;
};

export default function DataGridTable({
  totalPages,
  requestParams,
  dataSource,
  columns,
  loading,
  handlePageChange,
  paginationModel,
  hideFooter,
  sortingMode,
  handleSorting,
  disableColumnSorting,
  disableColumnFilter,
  onFilterChange,
  tableTotalCount,
  columnGroupingModel,
  toolbarConfig
}) {
  const { open, selectedColumnData, handleOpen, handleClose } = useChartModal(dataSource, columns, paginationModel?.search_column);

  const updatedColumns = columns?.map((column) => ({
    ...column,
    filterOperators: customFilterOperators(column?.dataType)
  }));

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterValues: []
  });

  const initialColumnState = useMemo(() => {
    const col = columns?.reduce((acc, column) => {
      if (column?.defaultVisible === false) {
        acc[column?.field] = false;
      } else {
        acc[column?.field] = true;
      }
      return acc;
    }, {});
    return col;
  }, [columns]);

  const rows = Array.isArray(dataSource)
    ? dataSource?.map((data) => ({
        ...data,
        id: data?._id || data?.id || Math.random()
      }))
    : [];

  const handleRefresh = () => {
    setFilterModel({
      items: [],
      quickFilterValues: []
    });
  };

  return (
    <Box sx={styles.container}>
      <DataGrid
        autoHeight
        getRowHeight={calculateDynamicRowHeight}
        columnGroupingModel={columnGroupingModel}
        rows={rows}
        columns={updatedColumns}
        loading={loading}
        paginationMode="server"
        sortingMode={sortingMode ? 'server' : 'client'}
        filterMode="server"
        rowCount={paginationModel?.totalCount}
        onSortModelChange={handleSorting}
        onFilterModelChange={(model) => {
          setFilterModel(model);
          onFilterChange(model);
        }}
        disableRowSelectionOnClick
        // onRowClick={handleRow}
        // disableColumnSelector
        disableDensitySelector
        hideFooter={hideFooter}
        rowHeight={60}
        paginationModel={paginationModel}
        initialState={{
          columns: {
            columnVisibilityModel: initialColumnState
          }
        }}
        slots={{
          pagination: (props) => <CustomPagination {...props} requestParams={requestParams} totalPages={totalPages} />,
          toolbar: (props) => <CustomToolbar {...props} config={toolbarConfig || {}} onRefresh={handleRefresh} />,
          columnMenu: (props) => <CustomColumnMenu {...props} handleOpen={handleOpen} />
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          },
          pagination: {
            totalCount: tableTotalCount
          }
        }}
        pageSizeOptions={[5, 10, 25, 100]}
        onPaginationModelChange={handlePageChange}
        sx={styles.dataGrid}
        disableColumnSorting={disableColumnSorting}
        disableColumnFilter={disableColumnFilter}
        getRowId={(row) => row?._id || row?.id}
        filterModel={filterModel}
      />
      <CustomActionModal open={open} handleClose={handleClose} selectedColumnData={selectedColumnData} />
    </Box>
  );
}
