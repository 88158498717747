import React from 'react';
import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

const MultiSeriesLineChart = ({ data, xLabel: xAxisLabel, yLabel: yAxisLabel }) => {
  const chartId = uuid();

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    let root = am5.Root.new(chartId);

    // Set themes
    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0
      })
    );

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: 'day',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: true
        }),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xAxisLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom'
        }),
        tooltip: am5.Tooltip.new(root, {}),
        title: am5.Label.new(root, {
          text: yAxisLabel
        })
      })
    );

    // Move y-axis label inside the y-axis values
    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: yAxisLabel,
        fontSize: 16,
        fontWeight: 600,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    // Add series for each data series
    data.forEach((seriesData) => {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesData.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: seriesData.valueYField,
          valueXField: seriesData.valueXField,
          tooltip: am5.Tooltip.new(root, {
            labelText: '{name}: {valueY}'
          })
        })
      );

      series.data.setAll(seriesData.data);
    });

    // Add cursor
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none'
      })
    );
    cursor.lineY.set('visible', false);

    // Make stuff animate on load
    chart.appear(1000, 100);

    // Cleanup on unmount
    return () => {
      root.dispose();
    };
  }, [data, xAxisLabel, yAxisLabel]);

  return <div style={{ width: '100%', height: '500px' }} id={chartId}></div>;
};

export default MultiSeriesLineChart;
