import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { Graph } from 'iconsax-react';

import { getChartByName } from 'ui-component/charts/GetByChartName';

export const prepareChartData = (dataSource, searchColumn, selectedColumn) => {
  return dataSource.map((row) => ({
    category: String(row[searchColumn?.field || selectedColumn?.field] || ''),
    value: typeof row[selectedColumn.field] === 'number' ? row[selectedColumn.field] : 1
  }));
};

// const CHART_TYPES = [
//   { value: 'barchart', label: 'Bar Graph' },
//   { value: 'piechart', label: 'Pie Chart' }
// ];

const CustomActionModal = ({ open, handleClose, selectedColumnData }) => {
  const [chartType, setChartType] = useState('barchart');

  useEffect(() => {
    const defaultChartType = selectedColumnData?.chartTypes?.[0]?.value;
    if (defaultChartType) {
      setChartType(defaultChartType);
    }
  }, [selectedColumnData]);

  const getFormattedChartValues = () => {
    if (!selectedColumnData?.chartValues) return [];

    return selectedColumnData.chartValues.map((item) => {
      switch (chartType) {
        case 'piechart':
          return {
            category: item.category,
            value: item.value
          };
        case 'barchart':
          return {
            xaxisValue: item.category,
            yaxisValue: item.value
          };
        case 'linechart':
          return {
            date: item.category,
            value: item.value
          };
        default:
          return {
            category: item.category,
            value: item.value
          };
      }
    });
  };

  const chartData = {
    chartValues: getFormattedChartValues(),
    isEnabled: true,
    type: chartType,
    xAxisTitle: selectedColumnData?.columnTitle || '',
    yAxisTitle: 'Value'
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <span>
            {selectedColumnData?.categoryColumnTitle} vs {selectedColumnData?.columnTitle}
          </span>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <Select
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
              displayEmpty
              startAdornment={<Graph size="1.25rem" variant="Bold" style={{ marginRight: '8px' }} />}
            >
              {selectedColumnData?.chartTypes?.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: '90%', width: '100%' }}>{getChartByName({ data: chartData })}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomActionModal;
