import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';

const InputComponent = ({ item, applyValue, dataType }) => {
  const [filterValue, setFilterValue] = useState(item.value || '');
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current?.focus();
  }, [filterValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFilterValue(newValue);
    applyValue({ ...item, value: newValue });
  };

  return (
    <FormControl fullWidth>
      <FormLabel>
        <Typography fontSize="12px">Value</Typography>
      </FormLabel>
      {/* TODO: Add date picker for date type */}
      <TextField
        inputRef={inputRef}
        variant="standard"
        value={filterValue}
        onChange={handleChange}
        placeholder={'Filter value'}
        InputProps={{ style: { padding: 0 } }}
        fullWidth
        type={dataType === 'number' ? 'number' : 'text'}
      />
    </FormControl>
  );
};

const FILTER_OPERATORS = [
  {
    filter: (dataType) => ({
      value: 'contains',
      label: 'Contains',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value) return null;
        return ({ value }) => value?.toString()?.includes(filterItem.value);
      },
      InputComponent: (props) => <InputComponent {...props} dataType={dataType} />
    }),
    dataTypes: ['string']
  },
  {
    filter: (dataType) => ({
      value: 'equals',
      label: 'Equals',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value) return null;
        return ({ value }) => value?.toString() === filterItem.value;
      },
      InputComponent: (props) => <InputComponent {...props} dataType={dataType} />
    }),
    dataTypes: ['string', 'number', 'date']
  },
  {
    filter: (dataType) => ({
      value: 'doesNotEqual',
      label: 'Does not equal',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value) return null;
        return ({ value }) => value?.toString() !== filterItem.value;
      },
      InputComponent: (props) => <InputComponent {...props} dataType={dataType} />
    }),
    dataTypes: ['string', 'number', 'date']
  },
  {
    filter: (dataType) => ({
      value: 'min',
      label: 'Minimum',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value) return null;
        return ({ value }) => Number(value) >= Number(filterItem.value);
      },
      InputComponent: (props) => <InputComponent {...props} dataType={dataType} />
    }),
    dataTypes: ['number', 'date']
  },
  {
    filter: (dataType) => ({
      value: 'max',
      label: 'Maximum',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.columnField || !filterItem.value) return null;
        return ({ value }) => Number(value) <= Number(filterItem.value);
      },
      InputComponent: (props) => <InputComponent {...props} dataType={dataType} />
    }),
    dataTypes: ['number', 'date']
  }
];

export const getColumnFilterOperators = (column) => {
  const dataType = column?.dataType || 'string';
  const operators = FILTER_OPERATORS.filter((operator) => operator.dataTypes.includes(dataType)).map((operator) =>
    operator.filter(dataType)
  );
  return operators;
};
