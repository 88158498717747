/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import { z as zod } from 'zod';

/**
 * @summary List
 */
export const listGetResponseNameRegExp = new RegExp('^[a-zA-Z]+$');
export const listGetResponseVersionRegExp = new RegExp('^\\d+\\.\\d+\\.\\d+$');

export const listGetResponseItem = zod.object({
  _id: zod.string().or(zod.null()).optional(),
  name: zod.string().regex(listGetResponseNameRegExp),
  description: zod.array(zod.string()),
  version: zod.string().regex(listGetResponseVersionRegExp),
  priority: zod.string().optional(),
  category: zod.string().optional(),
  logo: zod.string().or(zod.null()).optional(),
  authorization: zod.record(zod.string(), zod.array(zod.string())).optional(),
  tags: zod.record(zod.string(), zod.string()).or(zod.null()).optional(),
  insights: zod
    .array(
      zod.object({
        id: zod.string(),
        key: zod.string().optional(),
        pipeline: zod
          .object({
            db: zod.string(),
            collection: zod.string(),
            stages: zod.array(zod.object({}))
          })
          .optional(),
        summary: zod.string().or(zod.null()).optional()
      })
    )
    .optional()
});
export const listGetResponse = zod.array(listGetResponseItem);

/**
 * @summary Create
 */
export const createPostBodyNameRegExp = new RegExp('^[a-zA-Z]+$');
export const createPostBodyVersionRegExp = new RegExp('^\\d+\\.\\d+\\.\\d+$');
export const createPostBodyCollectionMappingsRelationshipsItemFromFieldRegExp = new RegExp(
  '^[a-zA-Z_][a-zA-Z0-9_]*\\.[a-zA-Z_][a-zA-Z0-9_]*$'
);
export const createPostBodyCollectionMappingsRelationshipsItemToFieldRegExp = new RegExp(
  '^[a-zA-Z_][a-zA-Z0-9_]*\\.[a-zA-Z_][a-zA-Z0-9_]*$'
);

export const createPostBody = zod.object({
  _id: zod.string().or(zod.null()).optional(),
  name: zod.string().regex(createPostBodyNameRegExp),
  description: zod.array(zod.string()),
  version: zod.string().regex(createPostBodyVersionRegExp),
  priority: zod.string().optional(),
  category: zod.string().optional(),
  logo: zod.string().or(zod.null()).optional(),
  authorization: zod.record(zod.string(), zod.array(zod.string())).optional(),
  tags: zod.record(zod.string(), zod.string()).or(zod.null()).optional(),
  insights: zod
    .array(
      zod.object({
        id: zod.string(),
        key: zod.string().optional(),
        pipeline: zod
          .object({
            db: zod.string(),
            collection: zod.string(),
            stages: zod.array(zod.object({}))
          })
          .optional(),
        summary: zod.string().or(zod.null()).optional()
      })
    )
    .optional(),
  preCannedQuestions: zod.array(zod.string()).or(zod.null()).optional(),
  collectionMappings: zod
    .object({
      schemas: zod
        .record(
          zod.string(),
          zod.object({
            fields: zod
              .array(
                zod.object({
                  name: zod.string(),
                  type: zod.string(),
                  description: zod.string().or(zod.null()).optional(),
                  format: zod.string().or(zod.null()).optional(),
                  items: zod.object({}).or(zod.null()).optional(),
                  properties: zod.object({}).or(zod.null()).optional()
                })
              )
              .optional()
          })
        )
        .optional(),
      relationships: zod
        .array(
          zod.object({
            fromField: zod.string().regex(createPostBodyCollectionMappingsRelationshipsItemFromFieldRegExp),
            toField: zod.string().regex(createPostBodyCollectionMappingsRelationshipsItemToFieldRegExp)
          })
        )
        .or(zod.null())
        .optional()
    })
    .or(zod.null())
    .optional(),
  charts: zod
    .array(
      zod.object({
        id: zod.string(),
        title: zod.string(),
        description: zod.string().or(zod.null()),
        chartType: zod.enum(['barchart', 'bubblechart']),
        pipeline: zod.object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        }),
        isEnabled: zod.boolean(),
        xAxisTitle: zod.string(),
        yAxisTitle: zod.string(),
        category: zod.string(),
        insights: zod
          .object({
            id: zod.string(),
            key: zod.string().optional(),
            pipeline: zod
              .object({
                db: zod.string(),
                collection: zod.string(),
                stages: zod.array(zod.object({}))
              })
              .optional(),
            summary: zod.string().or(zod.null()).optional()
          })
          .or(zod.null())
          .optional()
      })
    )
    .or(zod.null())
    .optional(),
  tables: zod
    .array(
      zod.object({
        id: zod.string(),
        description: zod.string().or(zod.null()).optional(),
        info: zod.string().or(zod.null()).optional(),
        order: zod.number(),
        columnGroupingModel: zod
          .array(
            zod.object({
              groupId: zod.string(),
              children: zod.array(
                zod.object({
                  field: zod.string()
                })
              )
            })
          )
          .or(zod.null())
          .optional(),
        columns: zod.array(
          zod.object({
            key: zod.string(),
            title: zod.string().or(zod.null()).optional(),
            description: zod.string().or(zod.null()).optional(),
            dataType: zod.enum(['string', 'number', 'date', 'time', 'datetime', 'boolean', 'object', 'href']),
            source: zod.string().or(zod.null()).optional(),
            editable: zod.boolean().or(zod.null()).optional()
          })
        ),
        pipeline: zod.object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        }),
        insights: zod
          .object({
            id: zod.string(),
            key: zod.string().optional(),
            pipeline: zod
              .object({
                db: zod.string(),
                collection: zod.string(),
                stages: zod.array(zod.object({}))
              })
              .optional(),
            summary: zod.string().or(zod.null()).optional()
          })
          .or(zod.null())
          .optional()
      })
    )
    .or(zod.null())
    .optional(),
  transform: zod
    .object({
      schedule: zod.string().optional(),
      containerImage: zod.string(),
      command: zod.array(zod.string()).or(zod.null()).optional(),
      args: zod.array(zod.string()).or(zod.null()).optional(),
      env: zod
        .array(
          zod.object({
            name: zod.string(),
            value: zod.string()
          })
        )
        .or(zod.null())
        .optional(),
      restartPolicy: zod.string().optional(),
      status: zod
        .object({
          last_run_time: zod.string().datetime().or(zod.null()),
          last_run_status: zod.string().or(zod.null())
        })
        .or(zod.null())
        .optional()
    })
    .or(zod.null())
    .optional()
});

/**
 * @summary Get
 */
export const getNameGetParams = zod.object({
  name: zod.string()
});

export const getNameGetResponseNameRegExp = new RegExp('^[a-zA-Z]+$');
export const getNameGetResponseVersionRegExp = new RegExp('^\\d+\\.\\d+\\.\\d+$');
export const getNameGetResponseCollectionMappingsRelationshipsItemFromFieldRegExp = new RegExp(
  '^[a-zA-Z_][a-zA-Z0-9_]*\\.[a-zA-Z_][a-zA-Z0-9_]*$'
);
export const getNameGetResponseCollectionMappingsRelationshipsItemToFieldRegExp = new RegExp(
  '^[a-zA-Z_][a-zA-Z0-9_]*\\.[a-zA-Z_][a-zA-Z0-9_]*$'
);

export const getNameGetResponse = zod.object({
  _id: zod.string().or(zod.null()).optional(),
  name: zod.string().regex(getNameGetResponseNameRegExp),
  description: zod.array(zod.string()),
  version: zod.string().regex(getNameGetResponseVersionRegExp),
  priority: zod.string().optional(),
  category: zod.string().optional(),
  logo: zod.string().or(zod.null()).optional(),
  authorization: zod.record(zod.string(), zod.array(zod.string())).optional(),
  tags: zod.record(zod.string(), zod.string()).or(zod.null()).optional(),
  insights: zod
    .array(
      zod.object({
        id: zod.string(),
        key: zod.string().optional(),
        pipeline: zod
          .object({
            db: zod.string(),
            collection: zod.string(),
            stages: zod.array(zod.object({}))
          })
          .optional(),
        summary: zod.string().or(zod.null()).optional()
      })
    )
    .optional(),
  preCannedQuestions: zod.array(zod.string()).or(zod.null()).optional(),
  collectionMappings: zod
    .object({
      schemas: zod
        .record(
          zod.string(),
          zod.object({
            fields: zod
              .array(
                zod.object({
                  name: zod.string(),
                  type: zod.string(),
                  description: zod.string().or(zod.null()).optional(),
                  format: zod.string().or(zod.null()).optional(),
                  items: zod.object({}).or(zod.null()).optional(),
                  properties: zod.object({}).or(zod.null()).optional()
                })
              )
              .optional()
          })
        )
        .optional(),
      relationships: zod
        .array(
          zod.object({
            fromField: zod.string().regex(getNameGetResponseCollectionMappingsRelationshipsItemFromFieldRegExp),
            toField: zod.string().regex(getNameGetResponseCollectionMappingsRelationshipsItemToFieldRegExp)
          })
        )
        .or(zod.null())
        .optional()
    })
    .or(zod.null())
    .optional(),
  charts: zod
    .array(
      zod.object({
        id: zod.string(),
        title: zod.string(),
        description: zod.string().or(zod.null()),
        chartType: zod.enum(['barchart', 'bubblechart']),
        pipeline: zod.object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        }),
        isEnabled: zod.boolean(),
        xAxisTitle: zod.string(),
        yAxisTitle: zod.string(),
        category: zod.string(),
        insights: zod
          .object({
            id: zod.string(),
            key: zod.string().optional(),
            pipeline: zod
              .object({
                db: zod.string(),
                collection: zod.string(),
                stages: zod.array(zod.object({}))
              })
              .optional(),
            summary: zod.string().or(zod.null()).optional()
          })
          .or(zod.null())
          .optional()
      })
    )
    .or(zod.null())
    .optional(),
  tables: zod
    .array(
      zod.object({
        id: zod.string(),
        description: zod.string().or(zod.null()).optional(),
        info: zod.string().or(zod.null()).optional(),
        order: zod.number(),
        columnGroupingModel: zod
          .array(
            zod.object({
              groupId: zod.string(),
              children: zod.array(
                zod.object({
                  field: zod.string()
                })
              )
            })
          )
          .or(zod.null())
          .optional(),
        columns: zod.array(
          zod.object({
            key: zod.string(),
            title: zod.string().or(zod.null()).optional(),
            description: zod.string().or(zod.null()).optional(),
            dataType: zod.enum(['string', 'number', 'date', 'time', 'datetime', 'boolean', 'object', 'href']),
            source: zod.string().or(zod.null()).optional(),
            editable: zod.boolean().or(zod.null()).optional()
          })
        ),
        pipeline: zod.object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        }),
        insights: zod
          .object({
            id: zod.string(),
            key: zod.string().optional(),
            pipeline: zod
              .object({
                db: zod.string(),
                collection: zod.string(),
                stages: zod.array(zod.object({}))
              })
              .optional(),
            summary: zod.string().or(zod.null()).optional()
          })
          .or(zod.null())
          .optional()
      })
    )
    .or(zod.null())
    .optional(),
  transform: zod
    .object({
      schedule: zod.string().optional(),
      containerImage: zod.string(),
      command: zod.array(zod.string()).or(zod.null()).optional(),
      args: zod.array(zod.string()).or(zod.null()).optional(),
      env: zod
        .array(
          zod.object({
            name: zod.string(),
            value: zod.string()
          })
        )
        .or(zod.null())
        .optional(),
      restartPolicy: zod.string().optional(),
      status: zod
        .object({
          last_run_time: zod.string().datetime().or(zod.null()),
          last_run_status: zod.string().or(zod.null())
        })
        .or(zod.null())
        .optional()
    })
    .or(zod.null())
    .optional()
});

/**
 * @summary Update
 */
export const updateNamePutParams = zod.object({
  name: zod.string()
});

export const updateNamePutBodyNameRegExp = new RegExp('^[a-zA-Z]+$');
export const updateNamePutBodyVersionRegExp = new RegExp('^\\d+\\.\\d+\\.\\d+$');
export const updateNamePutBodyCollectionMappingsRelationshipsItemFromFieldRegExp = new RegExp(
  '^[a-zA-Z_][a-zA-Z0-9_]*\\.[a-zA-Z_][a-zA-Z0-9_]*$'
);
export const updateNamePutBodyCollectionMappingsRelationshipsItemToFieldRegExp = new RegExp(
  '^[a-zA-Z_][a-zA-Z0-9_]*\\.[a-zA-Z_][a-zA-Z0-9_]*$'
);

export const updateNamePutBody = zod.object({
  _id: zod.string().or(zod.null()).optional(),
  name: zod.string().regex(updateNamePutBodyNameRegExp),
  description: zod.array(zod.string()),
  version: zod.string().regex(updateNamePutBodyVersionRegExp),
  priority: zod.string().optional(),
  category: zod.string().optional(),
  logo: zod.string().or(zod.null()).optional(),
  authorization: zod.record(zod.string(), zod.array(zod.string())).optional(),
  tags: zod.record(zod.string(), zod.string()).or(zod.null()).optional(),
  insights: zod
    .array(
      zod.object({
        id: zod.string(),
        key: zod.string().optional(),
        pipeline: zod
          .object({
            db: zod.string(),
            collection: zod.string(),
            stages: zod.array(zod.object({}))
          })
          .optional(),
        summary: zod.string().or(zod.null()).optional()
      })
    )
    .optional(),
  preCannedQuestions: zod.array(zod.string()).or(zod.null()).optional(),
  collectionMappings: zod
    .object({
      schemas: zod
        .record(
          zod.string(),
          zod.object({
            fields: zod
              .array(
                zod.object({
                  name: zod.string(),
                  type: zod.string(),
                  description: zod.string().or(zod.null()).optional(),
                  format: zod.string().or(zod.null()).optional(),
                  items: zod.object({}).or(zod.null()).optional(),
                  properties: zod.object({}).or(zod.null()).optional()
                })
              )
              .optional()
          })
        )
        .optional(),
      relationships: zod
        .array(
          zod.object({
            fromField: zod.string().regex(updateNamePutBodyCollectionMappingsRelationshipsItemFromFieldRegExp),
            toField: zod.string().regex(updateNamePutBodyCollectionMappingsRelationshipsItemToFieldRegExp)
          })
        )
        .or(zod.null())
        .optional()
    })
    .or(zod.null())
    .optional(),
  charts: zod
    .array(
      zod.object({
        id: zod.string(),
        title: zod.string(),
        description: zod.string().or(zod.null()),
        chartType: zod.enum(['barchart', 'bubblechart']),
        pipeline: zod.object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        }),
        isEnabled: zod.boolean(),
        xAxisTitle: zod.string(),
        yAxisTitle: zod.string(),
        category: zod.string(),
        insights: zod
          .object({
            id: zod.string(),
            key: zod.string().optional(),
            pipeline: zod
              .object({
                db: zod.string(),
                collection: zod.string(),
                stages: zod.array(zod.object({}))
              })
              .optional(),
            summary: zod.string().or(zod.null()).optional()
          })
          .or(zod.null())
          .optional()
      })
    )
    .or(zod.null())
    .optional(),
  tables: zod
    .array(
      zod.object({
        id: zod.string(),
        description: zod.string().or(zod.null()).optional(),
        info: zod.string().or(zod.null()).optional(),
        order: zod.number(),
        columnGroupingModel: zod
          .array(
            zod.object({
              groupId: zod.string(),
              children: zod.array(
                zod.object({
                  field: zod.string()
                })
              )
            })
          )
          .or(zod.null())
          .optional(),
        columns: zod.array(
          zod.object({
            key: zod.string(),
            title: zod.string().or(zod.null()).optional(),
            description: zod.string().or(zod.null()).optional(),
            dataType: zod.enum(['string', 'number', 'date', 'time', 'datetime', 'boolean', 'object', 'href']),
            source: zod.string().or(zod.null()).optional(),
            editable: zod.boolean().or(zod.null()).optional()
          })
        ),
        pipeline: zod.object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        }),
        insights: zod
          .object({
            id: zod.string(),
            key: zod.string().optional(),
            pipeline: zod
              .object({
                db: zod.string(),
                collection: zod.string(),
                stages: zod.array(zod.object({}))
              })
              .optional(),
            summary: zod.string().or(zod.null()).optional()
          })
          .or(zod.null())
          .optional()
      })
    )
    .or(zod.null())
    .optional(),
  transform: zod
    .object({
      schedule: zod.string().optional(),
      containerImage: zod.string(),
      command: zod.array(zod.string()).or(zod.null()).optional(),
      args: zod.array(zod.string()).or(zod.null()).optional(),
      env: zod
        .array(
          zod.object({
            name: zod.string(),
            value: zod.string()
          })
        )
        .or(zod.null())
        .optional(),
      restartPolicy: zod.string().optional(),
      status: zod
        .object({
          last_run_time: zod.string().datetime().or(zod.null()),
          last_run_status: zod.string().or(zod.null())
        })
        .or(zod.null())
        .optional()
    })
    .or(zod.null())
    .optional()
});

/**
 * @summary Delete
 */
export const deleteNameDeleteParams = zod.object({
  name: zod.string()
});

/**
 * @summary Patch
 */
export const patchNamePatchParams = zod.object({
  name: zod.string()
});

export const patchNamePatchBody = zod.object({
  user_info: zod
    .object({
      id: zod.string(),
      employeeEmailId: zod.string(),
      displayName: zod.string().or(zod.null()).optional(),
      jobTitle: zod.string().or(zod.null()).optional(),
      customer: zod.object({
        id: zod.string(),
        name: zod.string()
      }),
      personas: zod.array(
        zod.object({
          id: zod.string(),
          name: zod.string()
        })
      ),
      accountEnabled: zod.boolean().or(zod.null()).optional(),
      createdBy: zod.string().or(zod.null()).optional(),
      createdTimestamp: zod.string().datetime().or(zod.null()).optional()
    })
    .optional()
});

/**
 * @summary Get
 */
export const getTablesTemplateGetResponse = zod.object({
  id: zod.string(),
  description: zod.string().or(zod.null()).optional(),
  info: zod.string().or(zod.null()).optional(),
  order: zod.number(),
  columnGroupingModel: zod
    .array(
      zod.object({
        groupId: zod.string(),
        children: zod.array(
          zod.object({
            field: zod.string()
          })
        )
      })
    )
    .or(zod.null())
    .optional(),
  columns: zod.array(
    zod.object({
      key: zod.string(),
      title: zod.string().or(zod.null()).optional(),
      description: zod.string().or(zod.null()).optional(),
      dataType: zod.enum(['string', 'number', 'date', 'time', 'datetime', 'boolean', 'object', 'href']),
      source: zod.string().or(zod.null()).optional(),
      editable: zod.boolean().or(zod.null()).optional()
    })
  ),
  pipeline: zod.object({
    db: zod.string(),
    collection: zod.string(),
    stages: zod.array(zod.object({}))
  }),
  insights: zod
    .object({
      id: zod.string(),
      key: zod.string().optional(),
      pipeline: zod
        .object({
          db: zod.string(),
          collection: zod.string(),
          stages: zod.array(zod.object({}))
        })
        .optional(),
      summary: zod.string().or(zod.null()).optional()
    })
    .or(zod.null())
    .optional()
});

/**
 * Fetches all available chart templates using `model_json_schema()`
and converts them into actual ChartConfig instances.
 * @summary Get
 */
export const getChartsTemplateGetResponse = zod.record(
  zod.string(),
  zod.object({
    id: zod.string(),
    title: zod.string(),
    description: zod.string().or(zod.null()),
    chartType: zod.enum(['barchart', 'bubblechart']),
    pipeline: zod.object({
      db: zod.string(),
      collection: zod.string(),
      stages: zod.array(zod.object({}))
    }),
    isEnabled: zod.boolean(),
    xAxisTitle: zod.string(),
    yAxisTitle: zod.string(),
    category: zod.string(),
    insights: zod
      .object({
        id: zod.string(),
        key: zod.string().optional(),
        pipeline: zod
          .object({
            db: zod.string(),
            collection: zod.string(),
            stages: zod.array(zod.object({}))
          })
          .optional(),
        summary: zod.string().or(zod.null()).optional()
      })
      .or(zod.null())
      .optional()
  })
);

/**
 * @summary Get Table
 */
export const getTableDpNameTablesTableIdPostParams = zod.object({
  dp_name: zod.string(),
  table_id: zod.string()
});

export const getTableDpNameTablesTableIdPostQueryParams = zod.object({
  insights: zod.boolean().or(zod.number()).or(zod.string()).or(zod.null()).optional(),
  download: zod.boolean().or(zod.number()).or(zod.string()).or(zod.null()).optional()
});

export const getTableDpNameTablesTableIdPostBody = zod.object({
  dateRange: zod
    .object({
      start: zod.string().datetime(),
      end: zod.string().datetime()
    })
    .or(zod.null()),
  query: zod.array(zod.object({}))
});

export const getTableDpNameTablesTableIdPostResponse = zod.object({
  values: zod.array(zod.object({})).optional(),
  summary: zod.string().optional()
});

/**
 * @summary Get Chart
 */
export const getChartDpNameChartsChartIdPostParams = zod.object({
  dp_name: zod.string(),
  chart_id: zod.string()
});

export const getChartDpNameChartsChartIdPostQueryParams = zod.object({
  insights: zod.boolean().or(zod.number()).or(zod.string()).or(zod.null()).optional()
});

export const getChartDpNameChartsChartIdPostBody = zod.object({
  dateRange: zod
    .object({
      start: zod.string().datetime(),
      end: zod.string().datetime()
    })
    .or(zod.null()),
  query: zod.array(zod.object({}))
});

export const getChartDpNameChartsChartIdPostResponse = zod.object({
  values: zod.array(
    zod
      .object({
        xaxisValue: zod.string(),
        yaxisValue: zod.number()
      })
      .or(
        zod.object({
          title: zod.string(),
          tooltipValue: zod.number(),
          tooltipXAxisValue: zod.number(),
          tooltipYAxisValue: zod.number(),
          value: zod.number(),
          valueLabel: zod.string(),
          xaxisValue: zod.number(),
          xaxisValueLabel: zod.string(),
          yaxisValue: zod.number(),
          yaxisValueLabel: zod.string()
        })
      )
  )
});

/**
 * @summary Get Insight
 */
export const getInsightDpNameInsightsInsightIdPostParams = zod.object({
  dp_name: zod.string(),
  insight_id: zod.string()
});

export const getInsightDpNameInsightsInsightIdPostResponse = zod.object({
  id: zod.string(),
  key: zod.string().optional(),
  pipeline: zod
    .object({
      db: zod.string(),
      collection: zod.string(),
      stages: zod.array(zod.object({}))
    })
    .optional(),
  summary: zod.string().or(zod.null()).optional()
});
