import React from 'react';
import { useState } from 'react';
import { Modal, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CloseCircle, InfoCircle, Maximize2 } from 'iconsax-react';

import PaperCard from 'ui-component/cards/PaperCard';
import { getChartByName } from 'ui-component/charts/GetByChartName';
import FadeInDiv from 'ui-component/FadeInDiv';
import GraphSkeleton from 'ui-component/Skeletons/GraphSkeleton';

import { useFetchDataProductChartData } from '../dataProducts.hooks';

/**
 *
 * @param {string} name - The name of the data product
 * @param {Z.infer<typeof getDataproductNameGetResponse.charts[number] >} chart - the data product chart specifications
 * @param {Object} dateRange - Date range for the chart
 * @param {string} dateRange.start - Start date of the date range
 * @param {string} dateRange.end - End date of the date range
 * @returns
 */
export const DataProductChart = ({ name, chart, dateRange }) => {
  const [modal, setModal] = useState(null);
  const { data: chartValues = [], isLoading } = useFetchDataProductChartData(name, chart.id, dateRange);
  const chartComponent = getChartByName({
    data: {
      ...chart,
      chartValues
    }
  });

  const handleMaximizeClick = () => {
    setModal(
      <Modal open={true} onClose={() => setModal(null)} disableAutoFocus>
        <Box
          display={'flex'}
          alignItems={'center'}
          position={'relative'}
          sx={{ width: '100vw', height: '100vh', bgcolor: 'background.paper', p: 4, position: 'relative' }}
        >
          <Box sx={{ zIndex: 1, position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}>
            <CloseCircle size="32" onClick={() => setModal(null)} />
          </Box>
          <Box width={'100%'} padding={2}>
            {chartComponent}
          </Box>
        </Box>
      </Modal>
    );
  };
  return (
    <FadeInDiv>
      <PaperCard padding={2}>
        <Box flexDirection={'column'} display={'flex'} gap={1}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box px={2} py={1} display={'flex'} alignItems={'center'} gap={1}>
              <Typography variant="h6" fontWeight={500}>
                {chart?.title}
              </Typography>
              {chart?.description && (
                <Tooltip title={chart?.description} placement={'top'}>
                  <InfoCircle size="18" color={'#2A76FA'} />
                </Tooltip>
              )}
            </Box>
            <Box>
              <Tooltip title="Full Screen" arrow placement="top">
                <Maximize2 size="21" color="#b9b9b9" style={{ cursor: 'pointer' }} onClick={handleMaximizeClick} />
              </Tooltip>
            </Box>
          </Box>
          {isLoading ? <GraphSkeleton /> : chartComponent}
          {modal}
        </Box>
      </PaperCard>
    </FadeInDiv>
  );
};
