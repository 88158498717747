import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';

const LineAreaChart = ({ data, downloadTitle = 'Charts' }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    let root = am5.Root.new(chartRef.current);

    // Set themes
    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false
        // wheelX: null,
        // wheelY: null
      })
    );

    // Add cursor
    let cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'xaxisValue',
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: data.xaxisTitle,
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxWidth: 130,
      ellipsis: '...'
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: data.yaxisTitle,
        y: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      0
    );

    // Create series for the filled area
    let areaSeries = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: 'Apps Used',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'yaxisValue',
        categoryXField: 'xaxisValue',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY} - Apps Used',
          getFillFromSprite: true
        }),

        fill: am5.color(0x5591f6),
        smoothed: true
      })
    );

    areaSeries.fills.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            opacity: 1
          },
          {
            opacity: 0.2
          }
        ],
        rotation: 90
      })
    );

    areaSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.4
    });

    areaSeries.strokes.template.setAll({
      visible: false,
      strokeWidth: 0
    });

    // Create thick line series
    let lineSeries = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: 'Apps Used Line',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'yaxisValue',
        categoryXField: 'xaxisValue',
        stroke: am5.color('#2A76F4'),
        strokeWidth: 4,
        smoothed: true
      })
    );

    // Add scrollbar
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      })
    );

    // Set data
    areaSeries.data.setAll(data.value);
    lineSeries.data.setAll(data.value);
    xAxis.data.setAll(data.value);

    // Make stuff animate on load
    areaSeries.appear(1000);
    lineSeries.appear(1000);
    chart.appear(1000, 100);

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data.value,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      },
      filePrefix: downloadTitle
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, [data]);

  return <div id="chartdiv" style={{ width: '100%', height: '500px' }} ref={chartRef}></div>;
};

export default LineAreaChart;
