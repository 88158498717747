import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Add, CloseCircle } from 'iconsax-react';

import MenuDropdownButton from 'ui-component/buttons/MenuDropdownButton';
import Createtask from 'ui-component/Modal/CreateTask';
import { connectionMenuItems, handleConnectionOptionSelect } from 'utils/connectionUtils';

import InvoiceModal from '../Modal/InvoiceModal';

const CreateButton = ({ name }) => {
  const [open, setOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSuccess = () => setOpen(false);

  const handleInvoiceOpen = () => setInvoiceOpen(true);
  const handleInvoiceClose = () => setInvoiceOpen(false);
  //todo: need to clean
  const handleOptionSelect = (option) => {
    handleConnectionOptionSelect(option, navigate, dispatch);
  };

  if (location.pathname === '/link') {
    return (
      <MenuDropdownButton name={name || 'Create New Connection'} onOptionSelect={handleOptionSelect} menuItems={connectionMenuItems} />
    );
  }

  if (location.pathname === '/invoice-list') {
    return (
      <Box display="flex" justifyContent="flex-end">
        <Button startIcon={<Add />} variant="contained" onClick={handleInvoiceOpen}>
          {name || 'Create New Invoice'}
        </Button>
        {invoiceOpen && <InvoiceModal open={invoiceOpen} onClose={handleInvoiceClose} />}
      </Box>
    );
  }

  const handleRevertDataProduct = () => {
    window.location.href = '/tasks?view=workflows';
  };

  const params = new URLSearchParams(window.location.search);
  const dataProductId = params.get('dataProductId');
  const dataProductTitle = params.get('title');

  return (
    <Box display="flex" justifyContent="flex-end">
      {dataProductId ? (
        <Button startIcon={<CloseCircle />} variant="outlined" onClick={handleRevertDataProduct}>
          {dataProductTitle ? ` Data Product: ${dataProductTitle}` : 'Data Product'}
        </Button>
      ) : (
        <Button startIcon={<Add />} variant="contained" onClick={handleOpen}>
          {name || 'Create New'}
        </Button>
      )}
      {open && <Createtask open={open} handleClose={handleClose} onSuccess={handleSuccess} />}
    </Box>
  );
};

export default CreateButton;
