import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

import {
  downloadDataProductTableData,
  fetchDataProductChartData,
  fetchDataProductDetails,
  fetchDataProductInsightData,
  fetchDataProducts,
  fetchDataProductTableData
} from './dataProducts.api';
import { getChartQueryParams, getInsightQueryParams, getTableQueryParams } from './dataProducts.utils';

const useFetchDataProducts = () => {
  return useQuery({
    queryKey: ['dataProducts'],
    queryFn: fetchDataProducts
  });
};

const useFetchDataProductDetails = (name) => {
  return useQuery({
    queryKey: ['dataProductDetails', name],
    queryFn: async () => {
      return fetchDataProductDetails(name);
    },
    enabled: !!name
  });
};

const useFetchDataProductTableData = (dataProductname, tableId, query = {}) => {
  const { skip, limit, sortSettings, filters, dateRange } = query;
  const queryParams = getTableQueryParams({ skip, limit, sortSettings, filters, dateRange });
  return useQuery({
    queryKey: ['dataProductTableData', dataProductname, tableId, { skip, limit, sortSettings, filters }],
    queryFn: async () => {
      return fetchDataProductTableData(dataProductname, tableId, queryParams);
    },
    enabled: !!dataProductname && !!tableId
  });
};

const useDownloadDataProductTableData = (onSuccess) => {
  return useMutation({
    mutationFn: async ({ name, tableId, query = {} }) => {
      const { sortSettings, filters, dateRange } = query;
      const queryParams = getTableQueryParams({ sortSettings, filters, dateRange, download: true });
      return downloadDataProductTableData(name, tableId, queryParams);
    },
    onSuccess
  });
};

const useFetchDataProductChartData = (dataProductname, chartId, dateRange) => {
  const queryParams = getChartQueryParams(dateRange);
  return useQuery({
    queryKey: ['dataProductChartData', dataProductname, chartId],
    queryFn: async () => {
      return fetchDataProductChartData(dataProductname, chartId, queryParams);
    },
    enabled: !!dataProductname && !!chartId
  });
};

const useFetchDataProductInsightData = (dataProductname, insightId, dateRange) => {
  const queryParams = getInsightQueryParams(dateRange);
  return useQuery({
    queryKey: ['dataProductInsightData', dataProductname, insightId],
    queryFn: async () => {
      return fetchDataProductInsightData(dataProductname, insightId, queryParams);
    },
    enabled: !!dataProductname && !!insightId
  });
};

const useFetchDataProductInsightsData = (dataProductname, insightIds, dateRange) => {
  const queryParams = getInsightQueryParams(dateRange);

  return useQueries({
    queries: insightIds.map((insightId) => ({
      queryKey: ['dataProductInsightData', dataProductname, insightId],
      queryFn: () => fetchDataProductInsightData(dataProductname, insightId, queryParams),
      select: ({ id, summary }) => ({ id, summary }),
      enabled: !!dataProductname && !!insightId
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending)
      };
    }
  });
};

export {
  useFetchDataProducts,
  useFetchDataProductDetails,
  useFetchDataProductTableData,
  useFetchDataProductChartData,
  useFetchDataProductInsightData,
  useDownloadDataProductTableData,
  useFetchDataProductInsightsData
};
