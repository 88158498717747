import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';

import { updateActionStatus } from 'api/taskManagement.service';
import useNotification from 'hooks/useNotifications';
import { useFunctionContext } from 'views/common/FunctionContext';

const ActionUpdateModal = ({ open, handleClose, from, to, actionId, setState, handleRefreshTable }) => {
  const [updateReason, setUpdateReason] = useState('');
  const [loading, setLoading] = useState(false);
  const { error: errorNotification, success: successNotification } = useNotification();
  const { taskId } = useParams();
  const { sharedFunction } = useFunctionContext() || {};
  const { handleRefreshHybridCards } = sharedFunction || '';
  const isNoteMandatory = ['Cancelled', 'Failure', 'Success', 'Skipped', 'Deferred'].includes(to);

  const handleUpdate = () => {
    if (isNoteMandatory && !updateReason) {
      errorNotification('Please enter a reason for the status update', 3000);
    } else {
      setLoading(true);
      updateActionStatus(taskId, {
        newStatus: to,
        id: actionId,
        currentStatus: from,
        note: updateReason
      })
        .then((res) => {
          if (res.status === 200) {
            setState({ from, to });
            successNotification('Action updated successfully', 3000);
            handleRefreshTable();
            if (handleRefreshHybridCards) {
              handleRefreshHybridCards('getTaskChartData');
              handleRefreshHybridCards('getTaskLogs');
              handleRefreshHybridCards('getTaskInfo');
            }
          } else {
            errorNotification('Error updating Action', 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          errorNotification('Error updating Action', 3000);
        })
        .finally(() => {
          setUpdateReason('');
          handleClose();
          setLoading(false);
        });
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2
  };

  const handleCloseModal = () => {
    setUpdateReason('');
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="reject-task-modal" aria-describedby="reject-task-form">
      <Box sx={style}>
        <Typography id="reject-task-modal" variant="h3" component="h2" mb={1}>
          Action Status Update
        </Typography>

        <TextField
          label={isNoteMandatory && !updateReason ? 'Note (Required)' : 'Note'}
          required={isNoteMandatory && !updateReason}
          placeholder="Enter the reason for the status update"
          value={updateReason}
          onChange={(e) => setUpdateReason(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={handleCloseModal}>
            Cancel
          </Button>
          {/* ! Hard code alert */}
          <Button
            disabled={loading || (isNoteMandatory && !updateReason)} // Disable the button during loading
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            // Todo : Remove inline style
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>Update</Box>
            {loading && (
              <CircularProgress
                size={24}
                color="inherit"
                sx={{
                  position: 'absolute'
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActionUpdateModal;
