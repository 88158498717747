import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Skeleton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Share } from 'iconsax-react';

import { StyledScrollbarWrapper, StyledTypography } from 'styled-components/global';

import { useFetchDataProductInsightsData } from '../dataProducts.hooks';
import { DATA_PRODUCTS_PATHS } from '../dataProducts.paths';

const style1 = {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '24px',
  color: '#434C5B'
};
const style2 = {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '10px'
};

const priorityData = {
  high: {
    bgcolor: '#FEE9E9',
    color: '#FF0000'
  },
  medium: {
    bgcolor: '#FEEFD7',
    color: '#FAAE35'
  },
  low: {
    bgcolor: '#EFF8E6',
    color: '#5DB806'
  }
};

/**
 * DataProductInsightCard component displays information about a data product in a card format
 * @param {Object} props - Component props
 * @param {'list'|'detail'} [props.cardType='list'] - Type of card view to display
 * @param {Object} props.dataProduct - Data product information
 * @param {string} props.dataProduct.name - Name of the data product
 * @param {string} props.dataProduct.description - Description of the data product
 * @param {string} props.dataProduct.priority - Priority of the data product
 * @param {Array} [props.dataProduct.insights=[]] - Array of insights associated with the data product
 * @param {Object} props.dateRange - Date range for the insights
 * @param {string} props.dateRange.start - Start date of the date range
 * @param {string} props.dateRange.end - End date of the date range
 * @returns {JSX.Element} DataProductInsightCard component
 */

export const DataProductInsightCard = ({ cardType = 'list', dataProduct, dateRange }) => {
  const navigate = useNavigate();
  const { name, description, priority, insights = [] } = dataProduct;
  const insightIds = insights.map((insight) => insight.id);
  const { data: insightsData, pending } = useFetchDataProductInsightsData(name, insightIds, dateRange);

  const isListView = cardType === 'list';
  const Wrapper = ({ children }) => (
    <Box
      sx={{
        cursor: isListView ? 'pointer' : 'default',
        p: '24px',
        borderRadius: '16px',
        bgcolor: '#F4F8FE',
        flex: 1
      }}
      display={'flex'}
      flexDirection={'column'}
      gap={'16px'}
      component={'div'}
      mb={1}
      {...(isListView ? { onClick: () => navigate(DATA_PRODUCTS_PATHS.DATA_PRODUCTS_DETAIL.replace(':name', name)) } : {})}
    >
      {children}
    </Box>
  );

  return (
    <Wrapper>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        columnGap={2}
        sx={{
          overflowY: 'auto'
        }}
      >
        <StyledTypography
          data-test-id={name}
          sx={{
            ...style1,
            fontWeight: '600',
            fontSize: '16px',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: '20px',
            minHeight: '40px',
            maxHeight: '40px'
          }}
        >
          {name}
        </StyledTypography>
        <Box
          sx={{
            padding: '12px 16px',
            borderRadius: '8px',
            ...priorityData[priority || 'low']
          }}
        >
          <Typography sx={{ ...style2, textTransform: 'capitalize' }}>{priority}</Typography>
        </Box>
      </Box>

      <StyledTypography
        sx={{
          ...style1,
          fontWeight: '400',
          fontSize: '16px',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          minHeight: '50px',
          maxHeight: '50px',
          overflowY: 'auto',
          scrollbarWidth: 'none'
        }}
      >
        {pending || insightsData[0]?.pending ? (
          <>
            <Skeleton variant="text" width={'100%'} height={'100%'} />
            <Skeleton variant="text" width={'100%'} height={'100%'} />
          </>
        ) : (
          insightsData[0]?.summary
        )}
      </StyledTypography>

      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        gap={'12px'}
        sx={{
          bgcolor: '#FFF',
          padding: '16px',
          borderRadius: '8px',
          maxWidth: '100%',
          minHeight: '70px',
          maxHeight: '65px',
          fontWeight: '400',
          fontSize: '16px',
          position: 'relative'
        }}
      >
        <Box>
          <Share size="24" color="#434C5B" />
        </Box>
        <StyledScrollbarWrapper sx={{ overflowY: 'auto', maxHeight: '50px', scrollbarWidth: 'thin', width: '100%' }}>
          {pending || insightsData[1]?.pending ? (
            <>
              <Skeleton variant="text" width={'100%'} height={'100%'} />
              <Skeleton variant="text" width={'100%'} height={'100%'} />
            </>
          ) : (
            <StyledTypography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#434C5B' }}>
              {insightsData[1]?.summary || description[1]}
            </StyledTypography>
          )}
        </StyledScrollbarWrapper>
      </Box>

      <hr
        style={{
          height: '1px',
          background: '#D4E4FD',
          width: '100%',
          border: 0,
          margin: 0
        }}
      />

      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'8px'}>
        <Avatar />
        <Typography style={{ ...style1, fontWeight: 500, textTransform: 'capitalize' }}>{'Asato'}</Typography>
      </Box>
    </Wrapper>
  );
};
