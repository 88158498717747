import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5flow from '@amcharts/amcharts5/flow';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { getNodeDataForSankey } from 'utils/graphUtils';

const SankeyChart = ({ data, downloadTitle = 'Chart' }) => {
  const id = uuidv4();
  const seriesRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(id);
    // Title
    root.container.children.unshift(
      am5.Label.new(root, {
        text: data?.title,
        fontSize: 16,
        fontWeight: '500',
        textAlign: 'left',
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        maxWidth: 300,
        wrap: true,
        oversizedBehavior: 'wrap'
      })
    );

    // Sankey series
    const series = root.container.children.push(
      am5flow.Sankey.new(root, {
        sourceIdField: 'from',
        targetIdField: 'to',
        valueField: 'thickness',
        nodeAlign: data?.node_align || 'left',
        nodePadding: 50,
        paddingTop: 40,
        paddingRight: 100,
        maxHeight: 600
      })
    );

    series.nodes.setAll({
      nameField: 'name',
      fillField: 'color'
    });

    // Set node data
    series.nodes.data.setAll(getNodeDataForSankey(data?.node_values));

    series.nodes.get('colors').set('step', 2);

    series.nodes.nodes.template.setAll({
      draggable: true
    });

    series.nodes.labels.template.setAll({
      maxWidth: 200,
      oversizedBehavior: 'truncate',
      textAlign: 'center'
    });

    // series.appear(1000, 100);
    seriesRef.current = series;

    // Exporting
    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data?.links.map(({ thickness, ...rest }) => ({
        ...rest,
        value: thickness
      })),
      filePrefix: downloadTitle,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, downloadTitle]);

  useLayoutEffect(() => {
    if (seriesRef.current) {
      seriesRef.current.data.setAll(data?.links);
    }
  }, [data]);

  return (
    <Box sx={{ width: '100%' }}>
      <div id={id} style={{ width: '100%', minHeight: '650px', maxHeight: '800px', margin: '20px 0 0 0' }} />
      {data?.node_categories && (
        <Box display={'flex'} justifyContent={'space-between'} paddingX={1} paddingY={1}>
          {data?.node_categories?.map((category, index) => (
            <Typography sx={{ fontWeight: 'bold' }} key={index}>
              {category}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SankeyChart;
