import React from 'react';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { InfoCircle } from 'iconsax-react';

const OnHoverToolTip = ({ title, variant = 'icon', children, placement = 'bottom-end', onClick = () => {}, className = '' }) => {
  const useStyles = makeStyles(() => ({
    tooltipText: {
      fontSize: '14px',
      fontWeight: 500,
      backgroundColor: 'black',
      padding: '12px 18px',
      '& ::-webkit-scrollbar': {
        height: '2px',
        width: '2px'
      }
    },

    // Special class for CellRenderers
    cellRendererTooltip: {
      '&[data-popper-placement*="bottom-start"]': {
        '& .MuiTooltip-tooltip': {
          transformOrigin: 'center top'
        },
        '& .MuiTooltip-arrow': {
          left: '12px !important',
          transform: 'translate3d(0, 0, 0) !important'
        }
      },
      '&[data-popper-placement*="bottom-end"]': {
        '& .MuiTooltip-tooltip': {
          transformOrigin: 'center top'
        },
        '& .MuiTooltip-arrow': {
          right: '12px !important',
          transform: 'translate3d(0, 0, 0) !important'
        }
      }
    }
  }));

  const classes = useStyles();

  const tooltipProps = {
    arrow: true,
    placement,
    classes: {
      tooltip: classes.tooltipText,
      popper: `${classes.tooltipArrow} ${className === 'cell-renderer' ? classes.cellRendererTooltip : ''}`
    }
  };

  switch (variant) {
    case 'icon':
      return (
        <Box display={'flex'} onClick={onClick}>
          <Tooltip {...tooltipProps} title={title}>
            <InfoCircle size={16} color={'#292D32'} />
          </Tooltip>
        </Box>
      );
    case 'children':
      return (
        <Tooltip {...tooltipProps} title={title} onClick={onClick}>
          {children}
        </Tooltip>
      );
    case 'icon-with-children':
      return (
        <Box display={'flex'} alignItems={'flex-start'} gap={1} justifyContent={'flex-start'} onClick={onClick}>
          {children}
          <Tooltip {...tooltipProps} title={title}>
            <Box paddingTop={0.5}>
              <InfoCircle size="18" color={'#2A76FA'} />
            </Box>
          </Tooltip>
        </Box>
      );
    default:
      return null;
  }
};

export default OnHoverToolTip;
