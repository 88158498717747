import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const NameValue = ({ name, value }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Typography variant="p">
        <strong>{name}</strong>
      </Typography>
      <Typography variant="p">{value}</Typography>
    </Box>
  );
};

/**
 * DataProductInfoCard component displays information about a data product in a card format
 * @param {Object} props - Component props
 * @param {Object} props.dataProduct - Data product information
 * @param {string} props.dataProduct.name - Name of the data product
 * @param {string} props.dataProduct.description - Description of the data product
 * @param {string} [props.dataProduct.version] - version of the data product
 * @returns {JSX.Element} DataProductInfoCard component
 */
export const DataProductInfoCard = ({ dataProduct, dateRange }) => {
  const { name, description, version } = dataProduct;

  return (
    <Box
      sx={{
        p: '24px',
        borderRadius: '16px',
        bgcolor: '#F4F8FE',
        flex: 1
      }}
      display={'flex'}
      flexDirection={'column'}
      gap={'16px'}
      component={'div'}
      mb={1}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} display={'flex'} flexDirection={'column'} gap={2}>
          <NameValue name={'Name'} value={name} />
          <NameValue name={'Date Range'} value={`${dateRange.start} to ${dateRange.end}`} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} display={'flex'} flexDirection={'column'} gap={2}>
          <NameValue name={'Version'} value={version} />
          <NameValue name={'Description'} value={description.join(' ')} />
        </Grid>
      </Grid>
    </Box>
  );
};
