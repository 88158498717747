import { Box, Typography } from '@mui/material';
import { Graph } from 'iconsax-react';

import BarChartMultiSeries from 'ui-component/amCharts/amBarChartMultiSeries';
import BarchartWithLine from 'ui-component/amCharts/amBarChartWithLine';
import BoxPlot from 'ui-component/amCharts/amBoxPlotChart';
import ControlChart from 'ui-component/amCharts/amControlChart';
import HeatMap from 'ui-component/amCharts/amHeatMap';
import ImageBarChart from 'ui-component/amCharts/amImageBarChart';
import IrregularInterval from 'ui-component/amCharts/amIrregularIntervalXY';
import LineAreaChart from 'ui-component/amCharts/amLineAreaChart';
import LineChart from 'ui-component/amCharts/amLineChart';
import MapChart from 'ui-component/amCharts/amMap';
import MapBubbleChart from 'ui-component/amCharts/amMapBubbleChart';
import MultiSeriesLineChart from 'ui-component/amCharts/amMultiSeriesLineChart';
import PieChart from 'ui-component/amCharts/amPieChart';
import ScatterPlot from 'ui-component/amCharts/amScatterPlot';
import SmoothLineAreaChart from 'ui-component/amCharts/amSmoothLineChart';
import StackedColumnChart from 'ui-component/amCharts/amStackedColumnChart';
import StepLineChart from 'ui-component/amCharts/amStepLineChart';
import VarianceChart from 'ui-component/amCharts/amVarianceBarChart';
import { WaterFallChart } from 'ui-component/amCharts/amWaterFallChart';
import ProgressBar from 'ui-component/amCharts/ProgressBar';
import { getMultiStackedCharts } from 'utils/graphUtils';

import Barchart from '../amCharts/ambarchart';
import BubbleChart from '../amCharts/amBubbleChart';
import InvertedBarChart from '../amCharts/amInvertedBarChart';
import Pareto from '../amCharts/amParetoChart';
import RadarChart from '../amCharts/amRadarChart';
import RadialHistogram from '../amCharts/amRadialHistogramChart';
import SankeyChart from '../amCharts/amSankeyChart';

const barData = [
  { xaxisValue: 'Figma', yaxisValue: 150000 },
  { xaxisValue: 'Adobe Creative Cloud', yaxisValue: 120000 },
  { xaxisValue: 'Microsoft Office 365', yaxisValue: 110000 },
  { xaxisValue: 'Salesforce', yaxisValue: 100000 },
  { xaxisValue: 'Slack', yaxisValue: 80000 },
  { xaxisValue: 'Google Workspace', yaxisValue: 60000 },
  { xaxisValue: 'Zoom', yaxisValue: 50000 },
  { xaxisValue: 'GitHub', yaxisValue: 40000 },
  { xaxisValue: 'Trello', yaxisValue: 30000 }
];

export const getChartByName = ({ data, placeholderVariant, hideAxisLabels, gridSize }) => {
  const title = data?.title;
  const chartType = data?.chartType || data?.type;
  const chartValue = data?.chart_value || data?.value || data?.chartValues;
  const xaxisValueLabel = data?.xaxisValueLabel || data?.xaxisvalueLabel;
  const yaxisValueLabel = data?.yaxisValueLabel || data?.yaxisvalueLabel;
  const xaxisTitle = data?.xaxisTitle || data?.x_axis_title || data?.xAxisTitle;
  const yaxisTitle = data?.yaxisTitle || data?.y_axis_title || data?.yAxisTitle;
  const chartHeight = data?.chartHeight || '300px';
  const progressType = data?.progressType || '$';

  if (!data || (Array.isArray(chartValue) && (chartValue.length === 0 || chartValue[0]?.totalCount === 0))) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '1rem'
        }}
      >
        {/* <Typography>----- No data available -----</Typography> */}
        <Graph size="100" color="#2A76F4" opacity={0.6} />
        <Typography fontSize={'1rem'} textAlign={'center'}>
          {placeholderVariant ? (
            <span>
              To see the Chart,
              <br /> You need to create at least one Action.
            </span>
          ) : (
            'Oops! Graph data is missing.'
          )}
        </Typography>
      </Box>
    );
  }

  if (chartType === 'bubblechart')
    return (
      <BubbleChart
        gridSize={gridSize}
        data={chartValue}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        xTooltipLabel={xaxisValueLabel}
        yTooltipLabel={yaxisValueLabel}
        valueLabel={data?.valueLabel}
        downloadTitle={title}
        title={title}
        yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
      />
    );
  if (chartType === 'scatterchart')
    return (
      <ScatterPlot
        gridSize={gridSize}
        data={chartValue}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        xTooltipLabel={xaxisValueLabel}
        yTooltipLabel={yaxisValueLabel}
        downloadTitle={title}
        title={title}
        yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
      />
    );

  if (chartType === 'sankeydiagram') return <SankeyChart data={chartValue} downloadTitle={title} gridSize={gridSize} />;
  if (chartType === 'radarchart')
    return <RadarChart data={chartValue} downloadTitle={title} targetTitle={yaxisTitle} gridSize={gridSize} />;
  if (chartType === 'paretochart') return <Pareto data={chartValue} downloadTitle={title} gridSize={gridSize} />;
  if (chartType === 'barchartinverted')
    return (
      <InvertedBarChart
        gridSize={gridSize}
        data={chartValue}
        xLabel={data?.x_axis_title}
        yLabel={data?.y_axis_title}
        downloadTitle={title}
      />
    );
  if (chartType === 'barchart')
    return (
      <Barchart
        gridSize={gridSize}
        data={chartValue}
        xLabel={data?.xAxisTitle}
        yLabel={data?.yAxisTitle}
        downloadTitle={title}
        yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
        height={data?.height}
      />
    );
  if (chartType === 'barchartwithdescription') {
    const chartData = data?.chartValues?.[0]?.chartValues || [];
    return (
      <Barchart
        gridSize={gridSize}
        data={chartData}
        xLabel={data?.xAxisTitle}
        yLabel={data?.yAxisTitle}
        downloadTitle={title}
        yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
        xFormat={data?.format ? data?.format[0]?.x_axis_format : ''}
        height="250px"
      />
    );
  }
  if (chartType === 'histogramchart')
    return <RadialHistogram gridSize={gridSize} apiData={data?.value ? data?.value : barData} downloadTitle={title} />;
  if (chartType === 'boxplotchart')
    return <BoxPlot gridSize={gridSize} data={data?.value || []} xLabel={data?.xaxisTitle} yLabel={yaxisTitle} downloadTitle={title} />;
  if (chartType === 'irregularchart')
    return <IrregularInterval gridSize={gridSize} data={chartValue} xLabel={data?.xaxisTitle} yLabel={yaxisTitle} downloadTitle={title} />;
  if (chartType === 'barchartwithicon')
    return <ImageBarChart gridSize={gridSize} data={chartValue} xLabel={data?.xaxisTitle} yLabel={yaxisTitle} downloadTitle={title} />;
  if (chartType === 'sankeychart') return <SankeyChart gridSize={gridSize} data={chartValue} downloadTitle={title} />;
  if (chartType === 'variancechart')
    return (
      <VarianceChart
        gridSize={gridSize}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        type={data?.type}
        data={data}
        downloadTitle={title}
        yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
      />
    );
  if (chartType === 'stackedbarchart')
    return (
      <>
        {data?.displayType !== 'grouped' ? (
          <StackedColumnChart
            gridSize={gridSize}
            data={data}
            xLabel={xaxisTitle || ''}
            yLabel={yaxisTitle || ''}
            downloadTitle={title}
            yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
          />
        ) : (
          <>{getMultiStackedCharts(data)}</>
        )}
      </>
    );
  if (chartType === 'waterfallchart')
    return (
      <WaterFallChart
        gridSize={gridSize}
        type="app"
        data={chartValue}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        downloadTitle={title}
        yFormat={data?.format ? data?.format[0]?.y_axis_format : ''}
      />
    );
  if (chartType === 'multiseries-drilldown')
    return <BarChartMultiSeries gridSize={gridSize} data={chartValue} downloadTitle={title} xLabel={xaxisTitle} yLabel={yaxisTitle} />;
  if (chartType === 'heatmap')
    return (
      <HeatMap
        gridSize={gridSize}
        data={chartValue}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        downloadTitle={title}
        manualOrder={data?.manualOrder}
      />
    );
  if (chartType === 'piechart') return <PieChart gridSize={gridSize} data={chartValue} downloadTitle={title} />;
  if (chartType === 'barchartwithline')
    return (
      <BarchartWithLine
        gridSize={gridSize}
        xLabel={data?.xAxisTitle || ''}
        yLabel1={data?.yAxisTitle1 || ''}
        yLabel2={data?.yAxisTitle2 || ''}
        data={data}
        downloadTitle={title}
      />
    );
  if (chartType === 'linechart') return <LineChart gridSize={gridSize} data={chartValue} downloadTitle={title} />;
  if (chartType === 'controlchart')
    return (
      <ControlChart
        gridSize={gridSize}
        data={chartValue}
        xLabel={data?.xAxisTitle}
        yLabel={data?.yAxisTitle}
        downloadTitle={title}
        hasScrollBarX={data?.hasScrollBarX}
      />
    );

  if (chartType === 'mapchart') return <MapChart gridSize={gridSize} data={chartValue} downloadTitle={title} />;
  if (chartType === 'mapbubblechart')
    return <MapBubbleChart gridSize={gridSize} data={chartValue} showTable downloadTitle={title} tableId={data?.id} />;
  if (chartType === 'line-area-chart')
    return <LineAreaChart gridSize={gridSize} data={chartValue} xLabel={xaxisTitle} yLabel={yaxisTitle} downloadTitle={title} />;
  if (chartType === 'progress')
    return (
      <ProgressBar
        gridSize={gridSize}
        title={title}
        data={chartValue?.length && chartValue[0] ? chartValue[0] : {}}
        progressType={progressType}
        dropdownValue={data?.dropdownValue}
      />
    );
  if (chartType === 'smoothlinechart')
    return (
      <SmoothLineAreaChart
        gridSize={gridSize}
        data={chartValue[0]}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        chartHeight={chartHeight}
        hideAxisLabels={hideAxisLabels}
      />
    );
  if (chartType === 'stepprogresschart')
    return (
      <StepLineChart
        gridSize={gridSize}
        data={chartValue[0]}
        chartHeight={chartHeight}
        xLabel={xaxisTitle}
        yLabel={yaxisTitle}
        downloadTitle={title}
      />
    );

  if (chartType === 'multi-line-chart') {
    return <MultiSeriesLineChart gridSize={gridSize} data={chartValue} xLabel={xaxisTitle} yLabel={yaxisTitle} downloadTitle={title} />;
  }
};
