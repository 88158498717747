// * Transformers for graph data
import * as am5 from '@amcharts/amcharts5';
import { Grid } from '@mui/material';

import StackedColumnChart from 'ui-component/amCharts/amStackedColumnChart';

const colors = {
  critical: am5.color(0xca0101),
  verybad: am5.color(0xe17a2d),
  medium: am5.color(0xe1d92d),
  good: am5.color(0x5dbe24),
  verygood: am5.color(0x0b7d03)
};

export const benchMarkVarianceToSeries = (data) => {
  // * Creating this transformer to convert Ayush' weird graph data to amcharts series data
  const { values = '', variance = '' } = data?.chartValues[0] || {};

  const convertedData = Object.keys(values).map((xAxisValue) => {
    // * blue column is made of category and s1_value
    // * i.e. blue column value = s1_value

    // ! (if type is positive) - under prov
    // * colored column starting value = blue column value
    // * colored column ending value = blue column value + variance

    // ! (if type is negative) - over prov

    // * colored column starting value = blue column value - variance
    // * colored column ending value = blue column value (if type is negative)

    // Todo :

    // * blue column is made of category and s1_value
    // * i.e. blue column value - actual value :
    // * if positive - blue column value + variance
    // * if negative - blue column value - variance

    // ! (if type is positive) - under utillized
    // * colored column starting value = blue column value
    // * colored column ending value = blue column value + variance

    // ! (if type is negative) - over utillized
    // * colored column starting value = blue column value - variance
    // * colored column ending value = blue column value (if type is negative)

    // backup :
    /*
    {
      category: xAxisValue,
      s1_value: values[xAxisValue],
      s2_value: data.type === 'positive' ? values[xAxisValue] + Math.abs(Variance[xAxisValue]) : values[xAxisValue],
      s2_openValue: data.type === 'positive' ? values[xAxisValue] : values[xAxisValue] - Math.abs(Variance[xAxisValue]),
      s2_actualValue: Variance[xAxisValue],
      fill: data.type === 'positive' ? '#5DB806' : '#FF0000'
    }
    */
    return {
      category: xAxisValue,
      s1_value: data.isPositive ? values[xAxisValue] + Math.abs(variance[xAxisValue]) : values[xAxisValue] - Math.abs(variance[xAxisValue]),
      s2_value: data.isPositive ? values[xAxisValue] + Math.abs(variance[xAxisValue]) : values[xAxisValue],
      s2_openValue: data.isPositive ? values[xAxisValue] : values[xAxisValue] - Math.abs(variance[xAxisValue]),
      s2_variance: variance[xAxisValue],
      s2_actualValue: values[xAxisValue],
      fill: data.isPositive ? '#5DB806' : '#FF0000'
    };
  });

  return {
    ...data,
    values: undefined,
    Variance: undefined,
    graphData: convertedData
  };
};

export const waterFallToSeries = (data = {}) => {
  const input = data;
  const categories = Object.keys(input);
  const output = [];
  let accumulatedValue = 0;
  let previousValue = 0;

  categories.forEach((category, index) => {
    const clinicCount = input[category]['Clinic Count'];
    const costOfAssets = input[category]['Cost of assets'] || input[category]['Cost Variance'];

    accumulatedValue += clinicCount;

    const dataEntry = {
      category,
      value: index !== categories.length - 1 ? accumulatedValue : clinicCount,
      open: previousValue,
      stepValue: index !== categories.length - 1 ? accumulatedValue : clinicCount,
      displayValue: clinicCount,
      toolTip:
        costOfAssets === 'NULL'
          ? 'N/A'
          : `Cost of assets: ${new Intl.NumberFormat(navigator.language, {
              style: 'currency',
              currency: input[categories[0]]['Currency'] || 'USD',
              maximumFractionDigits: 0
            }).format(costOfAssets)}`
    };

    if (index === categories.length - 1) {
      dataEntry.open = 0;
      dataEntry.displayValue = clinicCount;
    }

    output.push(dataEntry);
    previousValue = accumulatedValue;
  });

  console.log({
    ...data,
    Values: undefined,
    graphData: output
  });

  return {
    ...data,
    Values: undefined,
    graphData: output
  };
};

// * Generic Utils for the graphs

export const getNodeDataForSankey = (data = []) => {
  return data?.map((node) => ({
    name: `
    ${Object.keys(node)[0]}
    ${Object.values(node)[0]}
    `,
    id: Object.keys(node)[0],
    color: node?.color && am5.color(node.color)
  }));
};

// * Utility to convert different chart data to table data

export const getTableFromStackedBar = (input = []) => {
  const headers = Object.keys(input[0]).map((key) => {
    return {
      key,
      label: key,
      title: key,
      display: true,
      type: 'string'
    };
  });

  const data = input;

  return {
    headers,
    data
  };
};
export const getTableFromSankeyData = (input = {}) => {
  const { links } = input;

  const headers = [
    {
      key: 'From',
      title: 'From',
      label: 'From',
      display: true,
      type: 'string'
    },
    {
      key: 'To',
      title: 'To',
      label: 'To',
      display: true,
      type: 'string'
    },
    {
      key: 'Value',
      title: 'Value',
      label: 'Value',
      display: true,
      type: 'number'
    }
  ];

  const data = links.map((link) => {
    return {
      From: link?.from,
      To: link?.to,
      Value: link?.thickness
    };
  });

  console.log({
    data,
    headers
  });

  return {
    data,
    headers
  };
};

export const extractHeatmapLinks = (heatMapData) => {
  const xAxisSet = new Set();
  const yAxisSet = new Set();

  heatMapData.forEach((dataPoint) => {
    xAxisSet.add(dataPoint.x);
    yAxisSet.add(dataPoint.y);
  });

  const xAxis = Array.from(xAxisSet).map((category) => ({ category }));
  const yAxis = Array.from(yAxisSet).map((category) => ({ category }));

  return { xAxis, yAxis };
};

export const getMultiStackedCharts = (data) => {
  if (data.displayType === 'grouped') {
    return (
      <Grid container>
        {data?.groupedValues?.map((value, index) => {
          const newData = {
            ...data,
            chartValues: [value],
            groupedValues: undefined,
            yaxisTitle: data?.[`yAxisTitle${index + 1}`],
            xaxisTitle: data?.[`xAxisTitle${index + 1}`]
          };
          console.log('neWadar : ', value, newData);
          return (
            <Grid item xs={12} md={6} lg={6} key={`${newData?.title}-${index}`}>
              <StackedColumnChart
                data={newData}
                xLabel={newData?.xaxisTitle || ''}
                yLabel={newData?.yaxisTitle || ''}
                downloadTitle={newData?.title}
                yFormat={data?.format[index]?.y_axis_format}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

export const getProcessedHeatmapData = (data) => {
  const final = data.map((item) => ({
    y: item.y,
    x: item.x,
    value: item.value,
    columnSettings: {
      ...item.columnSettings,
      fill: colors[item.columnSettings.fill]
    }
  }));

  return final;
};

/* For Stub */

let date = new Date();
date.setHours(0, 0, 0, 0);
let value = 100;

function generateData() {
  // Generate random data

  value = Math.round(Math.random() * 10 - 5 + value);
  am5.time.add(date, 'day', 1);
  return {
    date: date.getTime(),
    value: value
  };
}

export const generateStubForLineSeries = (count) => {
  let data = [];
  for (var i = 0; i < count; ++i) {
    data.push(generateData());
  }
  return data;
};

export const getDateRangeType = (data) => {
  if (!data || data.length === 0) return null;

  const dates = data.map((item) => new Date(item.xaxisValue));
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));

  const diffTime = Math.abs(maxDate - minDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays <= 7 ? 'day' : 'week';
};

export function ensureCompleteHeatMapData(data, manualOrder = false) {
  // Extract unique x and y values from the dataset
  const xValues = [...new Set(data.map((item) => item.x))];
  const yValues = [...new Set(data.map((item) => item.y))];

  // Create a mapping of existing keys and y-to-color mapping
  const existingKeys = new Set(data.map((item) => `${item.x}-${item.y}`));
  const yToColorMap = {};
  const orderMap = {};

  data.forEach((item) => {
    if (!yToColorMap[item.y]) {
      yToColorMap[item.y] = item.columnSettings.fill;
    }
    if (item?.order) {
      orderMap[item.y] = item?.order;
    }
  });

  const filledData = [...data];

  // Add missing objects
  xValues.forEach((x) => {
    yValues.forEach((y) => {
      const key = `${x}-${y}`;
      if (!existingKeys.has(key)) {
        filledData.push({
          _id: x,
          x: x,
          y: y,
          value: 0, // Default value
          order: orderMap[y],
          columnSettings: {
            fill: yToColorMap[y] || 'default' // Use the appropriate color or 'default'
          }
        });
      }
    });
  });

  if (manualOrder) {
    filledData.sort((a, b) => (a.order > b.order ? 1 : -1));
    filledData.sort((a, b) => (a.x > b.x ? -1 : 1));
  }

  return filledData;
}

export const multiSeriesBarData = (input) => {
  return input.map((item) => ({
    category: item.category,
    color: item.color,
    values: Object.entries(item.values).map(([key, value], index) => ({
      key: `s${index + 1}`,
      label: `${key}`,
      value: value
    }))
  }));
};

export const generateDownloadData = ({ data, xLabel, yLabel, chartType }) => {
  if (chartType === 'heatmap') {
    return data.map((item) => ({
      [xLabel]: item.x,
      [yLabel]: item.y,
      value: item.value
    }));
  }
  if (chartType === 'waterfall') {
    return data?.map((item) => {
      return {
        [xLabel]: item.category,
        [yLabel]: item.value
      };
    });
  }
  if (chartType === 'stackedBar') {
    // Assuming data is structured as an array of objects with categories and values
    return data.map((item) => {
      return {
        category: item.category,
        ...Object.fromEntries(
          Object.entries(item)
            .filter(([key, value]) => key !== 'category' && value !== null)
            .map(([key, value]) => [key || '-', value || '-']) || '-'
        )
      };
    });
  }
  return data;
};
