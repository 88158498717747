import { configuration } from 'services/baseApiService';
import { toUrlParams } from 'utils';

import { createHeadersWithToken } from './api';
import axios from './interceptors';

//  * Generic API request handler
const fetchTmsData = async (request, service = configuration.tasManagementSystemHost, path, httpMethod, options = {}) => {
  const { queryParams, formData, customHeaders } = options;

  const tokenHeaders = createHeadersWithToken();

  // Construct URL with query params if provided
  let URL = `${service}${path}`;
  if (queryParams) {
    const queryString = toUrlParams(queryParams);
    URL = `${URL}?${queryString}`;
  }

  // Headers with token, optionally merged with custom headers
  const headers = {
    ...tokenHeaders,
    ...customHeaders,
    ...(formData ? { 'Content-Type': 'multipart/form-data' } : {}) // Adjust content-type for form-data
  };

  try {
    // Choose the payload type based on the request format
    const data = formData || request;

    // Handle GET requests (no payload, headers come second)
    if (httpMethod === 'get') {
      return await axios.get(URL, { ...headers });
    }

    // For non-GET requests (POST, PUT, DELETE, etc.), data is second, headers third
    return await axios[httpMethod](URL, data, { ...headers });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// const loginDetails = JSON.parse(localStorage.getItem('userDetails'));
// const tenantId = loginDetails?.user?.customer?.id;
const role = localStorage.getItem('persona');
/**
 * Update task status by ID
 */
export const updateTaskStatus = async (id, request) => {
  const path = `tasks/${id}/status`;
  return await fetchTmsData(request, configuration.tasManagementSystemHost, path, 'put');
};

//  * Create a new task
export const createTask = async (request, options) => {
  const path = 'tasks/create';
  return await fetchTmsData(request, configuration.tasManagementSystemHost, path, 'post', options);
};

//  * Get users list by tenant
export const getUsersListAll = async () => {
  const path = `api/v1/list/users`;
  return await fetchTmsData({}, configuration.insightsDevBaseUrl, path, 'get');
};

export const getUsersList = async () => {
  // ! hard code alert
  // https://user-dev.asato.ai/user/?page_number=0&page_size=10&has_page_count=true
  const path = `user/?page_number=0&page_size=100&has_page_count=true`;
  return await fetchTmsData({}, configuration.newApiBaseUrl, path, 'get');
};

// * Get Asset List by type
export const getAssetList = async (assetType) => {
  const path = `api/v1/list/assets?asset_type=${assetType}`;
  return await fetchTmsData({}, configuration.insightsDevBaseUrl, path, 'get');
};

export const taskDetailCardApiCalls = {
  //  * gets Task info
  getTaskInfo: async (id) => {
    const path = `tasks/${id}/info`;
    return await fetchTmsData({}, configuration.tasManagementSystemHost, path, 'get');
  },
  // * gets Task Logs
  getTaskLogs: async (id) => {
    const path = `tasks/${id}/logs`;
    return await fetchTmsData({}, configuration.tasManagementSystemHost, path, 'get');
  },
  // * gets Chart Data
  getTaskChartData: async (id) => {
    const path = `screens/charts?task_id=${id}&id=affected_asset_count&screen_name=task-detail&role=${role}`;
    const res = await fetchTmsData({}, configuration.insightsDevBaseUrl, path, 'get');
    return res;
  }
};

// * Create a new action
export const createAction = async (id, request) => {
  const path = `tasks/${id}/add-action`;
  return await fetchTmsData(request, configuration.tasManagementSystemHost, path, 'put');
};

// * Update action status
export const updateActionStatus = async (id, request) => {
  const path = `tasks/${id}/action-status`;
  return await fetchTmsData(request, configuration.tasManagementSystemHost, path, 'put');
};

// * Soft deleting the tasks
export const deleteTask = async ({ task_id }) => {
  const path = `tasks/${task_id}/soft-delete`;
  return await fetchTmsData({}, configuration.tasManagementSystemHost, path, 'put');
};

// * Soft deleting the actions
export const deleteAction = async ({ task_id, action_id }) => {
  const path = `tasks/${task_id}/action/${action_id}/soft-delete`;
  return await fetchTmsData({}, configuration.tasManagementSystemHost, path, 'put');
};

// * Search
export const searchApi = async (searchValue, apiPath, searchKey) => {
  const separator = apiPath.includes('?') ? '&' : '?';
  const path = `${apiPath}${separator}search_key=${searchKey}&search_value=${searchValue}&page_number=0&page_size=10`;
  return await fetchTmsData({}, configuration.insightsDevBaseUrl, path, 'get');
};

export const getAssetTypes = async () => {
  const path = `api/v1/asset/types`;
  return await fetchTmsData({}, configuration.insightsDevBaseUrl, path, 'get');
};
