import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import img from 'assets/images/noDataFound.svg';

const ServerError = ({ retryDispatchFunction }) => {
  return (
    <Grid
      sx={{
        textAlign: 'center',
        width: '100%',
        m: 'auto'
      }}
    >
      <Box component="img" src={img} alt="No reports illustration" sx={{ width: '20%', height: 'auto', mb: 4 }} />
      <Typography gutterBottom sx={{ fontSize: '24px', fontWeight: '600' }}>
        Oops! Something went wrong...
      </Typography>
      <Button onClick={retryDispatchFunction} variant="outlined" color="primary">
        Retry?
      </Button>
    </Grid>
  );
};

export default ServerError;
