import { Box, Chip, createSvgIcon, Link, Typography } from '@mui/material';
import { ExportSquare } from 'iconsax-react';
import { AggregateBuilder, andExpr, orExpr } from 'mongodb-aggregate-builder';

import { STATUS_COLOR } from 'constants/colors';
import styles from 'ui-component/table/DataSourceTable/Styles/DataGridTableStyles';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';
import { formatNumber } from 'utils';

/**
 * @param {Object} params - The parameters for the table query
 * @param {number} params.skip - The number of rows to skip (e.g. 10 to skip first 10 rows)
 * @param {number} params.limit - The number of rows to limit (e.g. 25 to show 25 rows per page)
 * @param {Array} params.sortSettings - The sort settings for the table (e.g. [{field: 'name', sort: 'asc'}])
 * @param {Array} params.filters - The filter settings for the table (e.g. {items: [{field: 'status', value: 'active', operator: 'equals'}], logicOperator: 'and'})
 * @returns {Object} - The query object containing the MongoDB aggregation pipeline
 * @example
 * // Get first 10 rows sorted by name ascending
 * getTableQueryParams({
 *   skip: 0,
 *   limit: 10,
 *   sortSettings: [{field: 'name', sort: 'asc'}]
 * })
 *
 * // Get rows 11-20 with status filter
 * getTableQueryParams({
 *   skip: 10,
 *   limit: 10,
 *   filters: {items: [{field: 'status', value: 'active', operator: 'equals'}], logicOperator: 'and'}
 * })
 */
export const getTableQueryParams = ({ skip = 0, limit = 10, sortSettings, filters, download = false, dateRange }) => {
  const queryBuilder = new AggregateBuilder();
  const rowsBuilder = new AggregateBuilder();
  const totalCountBuilder = new AggregateBuilder();
  rowsBuilder.skip(skip).limit(limit);
  totalCountBuilder.count('count');
  const { items = [], logicOperator } = filters;

  // Adding Match settings to the query builder
  if (items.length > 0) {
    const filterList = items
      .filter(({ value }) => !!value)
      .map(({ field, value, operator }) => {
        switch (operator) {
          case 'contains':
            return { [field]: { $regex: value, $options: 'i' } };
          case 'min':
            return { [field]: { $gte: Number(value) } };
          case 'max':
            return { [field]: { $lte: Number(value) } };
          case 'doesNotEqual':
            return { [field]: { $ne: value } };
          default:
            return { [field]: value };
        }
      });
    if (filterList.length > 0) {
      const match = logicOperator === 'and' ? andExpr(filterList) : orExpr(filterList);
      queryBuilder.match(match);
    }
  }

  // Adding Sort Settings to the query builder as needed
  if (!!sortSettings && sortSettings.length > 0) {
    const sortObject = sortSettings?.reduce((acc, curr) => {
      acc[curr.field] = curr.sort === 'asc' ? 1 : -1;
      return acc;
    }, {});
    queryBuilder.sort(sortObject);
  }

  if (!download) {
    // Adding the facet to the query builder
    queryBuilder.facet({ rows: rowsBuilder.build(), totalCount: totalCountBuilder.build() });
  }

  return {
    query: queryBuilder.build(),
    dateRange
  };
};

export const getChartQueryParams = (dateRange) => {
  const queryBuilder = new AggregateBuilder();
  return {
    query: queryBuilder.build(),
    dateRange
  };
};

export const getInsightQueryParams = (dateRange) => {
  const queryBuilder = new AggregateBuilder();
  return {
    query: queryBuilder.build(),
    dateRange
  };
};

/**
 * Renders a cell value with different formatting based on data type and parameters
 * @param {Object} data - Column configuration object containing dataType, columnColor, etc
 * @param {Object} params - Cell parameters including value
 * @returns {JSX.Element} Rendered cell content
 */
const renderConnectionStatus = (data, params) => {
  const value = params?.value?.toString();
  const isLongValue = value && value.length > 18;
  const isEmpty = params?.value === '' || params?.value === null || params?.value === undefined;

  // Show error indicator for required empty cells
  if (data?.dataType === 'nonEmpty' && isEmpty) {
    return (
      <Box sx={styles.emptyRequiredCell}>
        <Typography color="error">{'-'}</Typography>
      </Box>
    );
  }

  const color = data?.columnColor || 'Default';

  const status_color = STATUS_COLOR[value] || 'Default';

  if (isEmpty) return '-';

  // Render clickable link if isClickable is true
  if (data.isClickable) {
    return (
      <Link
        sx={{
          cursor: 'pointer',
          color: status_color
        }}
        onClick={(e) => {
          e.preventDefault();
          // TODO: Implement the navigation
          console.log(data);
        }}
      >
        {value}
      </Link>
    );
  }

  // Handle different data types with specific rendering
  switch (data?.dataType) {
    case 'status': {
      return (
        <Box mt={1} sx={{ textTransform: 'capitalize' }}>
          {params.value ? 'Active' : 'Inactive'}
        </Box>
      );
    }
    case 'status_info': {
      return params?.value ? (
        <Box display="flex" alignItems="center" gap={3} my={1.5} sx={{ textTransform: 'capitalize' }}>
          <Chip variant="outlined" label={value} color={params.value === 'success' ? 'success' : 'error'} />
        </Box>
      ) : (
        '-'
      );
    }
    case 'tag': {
      return params?.value ? (
        <Box display="flex" alignItems="center" gap={1} my={1.5}>
          {(Array.isArray(value) ? value : value.split(/[ ,]+/)).map((val, index) => (
            <Chip
              key={index}
              label={val.trim()}
              color={
                val.trim() === 'Asset' ? 'primary' : val.trim() === 'Cost' ? 'secondary' : val.trim() === 'Usage' ? 'default' : 'error'
              }
            />
          ))}
        </Box>
      ) : (
        '-'
      );
    }
    case 'link': {
      return params?.value ? (
        <Link
          title={params.value || ''}
          href="#"
          sx={{ color: color }}
          onClick={(e) => {
            e.preventDefault();
            // TODO: Implement link onClick
          }}
        >
          Link
        </Link>
      ) : (
        '-'
      );
    }
    // Format numeric, date and currency values
    case 'data':
    case 'number':
    case 'percentage':
    case 'date':
    case 'currency':
      return (
        <span>
          {formatNumber({
            number: value,
            format: data?.dataType,
            formatCurrency: data?.formatCurrency
          })}
        </span>
      );
    case 'external_url': {
      return (
        <a style={{ textDecoration: 'underline', color: 'hsl(210, 100%, 36%)' }} href={value} target="_blank" rel="noreferrer">
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {value} <ExportSquare size={14} />
          </div>
        </a>
      );
    }
    // Default rendering with tooltip for long values
    default:
      return (
        <OnHoverToolTip variant="children" placement="bottom-start" title={isLongValue ? value : ''}>
          <span style={{ textTransform: 'capitalize' }}>{value}</span>
        </OnHoverToolTip>
      );
  }
};

/**
 * Processes column definitions and returns a formatted array for the DataGrid
 * @param {Array} columns - Array of column definitions
 * @returns {Array} Array of processed column objects
 */
export const processColumns = (columns = []) => {
  return (
    columns
      // .filter((column) => column?.display)
      .map((column) => ({
        field: column?.key,
        headerName: column?.title,
        headerClassName: 'header',
        type: column?.type,
        dataType: column?.dataType,
        flex: 1,
        editable: false,
        filterable: column?.filterable ?? true,
        disableColumnMenu: column?.disableColumnMenu ?? false,
        sortable: column?.sortable ?? true,
        minWidth: column?.width || 160,
        defaultVisible: column?.defaultVisible ?? true,
        hideable: column?.hideable ?? true,
        renderCell: (params) => renderConnectionStatus(column, params)
      }))
  );
};

/**
 * Calculates the grid size based on the index and the number of charts
 *
 * @param {number} index - The index of the chart
 * @param {number} numberOfElements - The number of charts
 * @returns {number} The grid size
 */
export const getGridByIndexAndNumberOfCharts = (index, numberOfElements) => {
  switch (numberOfElements) {
    case 5:
      return index === 0 ? 12 : 6;
    case 4:
      return index === 0 ? 12 : 4;
    case 2:
    case 1:
      return 12;
    default:
      return index === 0 ? 12 : index <= 2 ? 6 : 4;
  }
};

/**
 * Downloads a CSV file
 * @param {Array} data - The data to download
 * @param {string} name - The name of the data source
 */
export const downloadCSV = (data, name) => {
  const date = new Date().toISOString().replace('T', ':').slice(0, 19);
  const filename = `${name}-data-${date}.csv`;
  // Convert object array to CSV string
  const headers = Object.keys(data[0]);
  const csvString = [headers.join(','), ...data.map((row) => headers.map((header) => row[header]).join(','))].join('\n');

  // Create a Blob object for the CSV data
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link element and trigger a click event to download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

export const getDateRangeFromNow = (days = 90) => {
  const endDate = new Date();
  const startDate = new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000);
  return { start: startDate.toISOString().split('T')[0], end: endDate.toISOString().split('T')[0] };
};
