import { useNavigate, useParams } from 'react-router';
import { ChevronLeft } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import EmptyTablePlaceHolder from 'ui-component/ErrorComponents/TableEmpty';
import FadeInDiv from 'ui-component/FadeInDiv';
import GraphSkeleton from 'ui-component/Skeletons/GraphSkeleton';
import InsightCardSkeleton from 'ui-component/Skeletons/InsightsCardSkeleton';
import InsightTableSkeleton from 'ui-component/Skeletons/InsightTableSkeleton';

import { useFetchDataProductDetails } from '../dataProducts.hooks';
import { getDateRangeFromNow } from '../dataProducts.utils';
import { getNameGetResponse } from '../dataProducts.zod';

import { DataProductChartsContainer } from './DataProductChartsContainer';
import { DataProductInfoCard } from './DataProductInfoCard';
import { DataProductInsightCard } from './DataProductInsightCard';
import { DataProductTablesContainer } from './DataProductTablesContainer';

/**
 * Data product detail page
 * @returns {JSX.Element}
 */
export default function DataProductDetailPage() {
  const navigate = useNavigate();
  const { name } = useParams();
  const { data, isLoading } = useFetchDataProductDetails(name);
  const { success, data: dataProduct } = getNameGetResponse.safeParse(data);
  const dateRange = getDateRangeFromNow(90);
  return (
    <FadeInDiv>
      <Box p={2} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
        <Box
          // width={'24px'}
          height={'24px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(-1)}
        >
          <ChevronLeft />
        </Box>
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 500,
            ml: 0
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box py={0} px={2} gap={2} display={'flex'} flexDirection={'column'}>
        {isLoading ? (
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <InsightCardSkeleton />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InsightCardSkeleton />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InsightTableSkeleton />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <GraphSkeleton />
            </Grid>
          </Grid>
        ) : (
          <>
            {success && !!dataProduct ? (
              <>
                <Grid container display={'grid'} gridTemplateColumns={'repeat(2, 1fr)'} gap={1}>
                  <DataProductInsightCard cardType={'detail'} dataProduct={dataProduct} dateRange={dateRange} />
                  <DataProductInfoCard dataProduct={dataProduct} dateRange={dateRange} />
                </Grid>
                <DataProductTablesContainer name={name} tables={dataProduct.tables} dateRange={dateRange} />
                <DataProductChartsContainer name={name} charts={dataProduct.charts} dateRange={dateRange} />
              </>
            ) : (
              <EmptyTablePlaceHolder placeholder={`Data product "${name}"`} />
            )}
          </>
        )}
      </Box>
    </FadeInDiv>
  );
}
