import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import usaLow from '@amcharts/amcharts5-geodata/usaLow';
import { v4 as uuid } from 'uuid';

const MapChart = ({ data }) => {
  const id = uuid();
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE_MAP);
    const root = am5.Root.new(id);
    chartRef.current = root;

    let regionalSeries = {};
    let currentSeries;

    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'rotateX',
        projection: am5map.geoAlbersUsa()
      })
    );

    // Create polygon series
    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: usaLow
      })
    );

    // Disable wheel interactions for zooming
    chart.chartContainer.set('wheelY', 'none');
    chart.chartContainer.set('wheelX', 'none');

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}'
    });

    polygonSeries.mapPolygons.template.states.create('hover', {
      fill: am5.color(0x297373)
    });

    var zoomOut = root.tooltipContainer.children.push(
      am5.Button.new(root, {
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        dx: -20,
        dy: 20,
        themeTags: ['zoom'],
        icon: am5.Graphics.new(root, {
          themeTags: ['button', 'icon'],
          strokeOpacity: 0.7,
          draw: function (display) {
            display.moveTo(0, 0);
            display.lineTo(12, 0);
          }
        })
      })
    );

    zoomOut.get('background').setAll({
      cornerRadiusBL: 40,
      cornerRadiusBR: 40,
      cornerRadiusTL: 40,
      cornerRadiusTR: 40
    });
    zoomOut.events.on('click', function () {
      if (currentSeries) {
        currentSeries.hide();
      }
      chart.goHome();
      zoomOut.hide();
      currentSeries = regionalSeries.US.series;
      currentSeries.show();
    });
    zoomOut.hide();

    var parsed = data;

    // Ensure polygons are validated before accessing them
    polygonSeries.events.on('datavalidated', function () {
      setupStores(parsed);
    });

    // Load store data
    function setupStores(data) {
      regionalSeries.US = {
        markerData: [],
        series: createSeries('stores')
      };

      currentSeries = regionalSeries.US.series;

      am5.array.each(data, function (storeData) {
        var store = {
          state: storeData.MAIL_ST_PROV_C,
          long: am5.type.toNumber(storeData.LNGTD_I),
          lat: am5.type.toNumber(storeData.LATTD_I),
          location: storeData.co_loc_n,
          city: storeData.mail_city_n,
          count: am5.type.toNumber(storeData.count),
          drifted: am5.type.toNumber(storeData.drifted)
        };

        // Process state-level data
        if (regionalSeries[store.state] === undefined) {
          var statePolygon = getPolygon('US-' + store.state);
          if (statePolygon) {
            var centroid = statePolygon.visualCentroid();

            regionalSeries[store.state] = {
              target: store.state,
              type: 'state',
              name: statePolygon.dataItem.dataContext.name,
              count: store.count,
              stores: 1,
              drifted: 0,
              state: store.state,
              markerData: [],
              geometry: {
                type: 'Point',
                coordinates: [centroid.longitude, centroid.latitude]
              }
            };
            regionalSeries.US.markerData.push(regionalSeries[store.state]);
          } else {
            return;
          }
        } else {
          regionalSeries[store.state].stores++;
          regionalSeries[store.state].count += store.count;
          regionalSeries[store.state].drifted += store.drifted;
        }

        // Process city-level data
        if (regionalSeries[store.city] === undefined) {
          regionalSeries[store.city] = {
            target: store.city,
            type: 'city',
            name: store.city,
            count: store.count,
            stores: 1,
            drifted: 0,
            state: store.state,
            markerData: [],
            geometry: {
              type: 'Point',
              coordinates: [store.long, store.lat]
            }
          };
          regionalSeries[store.state].markerData.push(regionalSeries[store.city]);
        } else {
          regionalSeries[store.city].stores++;
          regionalSeries[store.city].count += store.count;
          regionalSeries[store.city].drifted += store.drifted;
        }

        regionalSeries[store.city].markerData.push({
          name: store.location,
          count: store.count,
          stores: 1,
          state: store.state,
          drifted: store.drifted,
          geometry: {
            type: 'Point',
            coordinates: [store.long, store.lat]
          }
        });
      });

      regionalSeries.US.series.data.setAll(regionalSeries.US.markerData);
    }

    // Finds polygon in series by its id
    function getPolygon(id) {
      let found = null;
      polygonSeries.mapPolygons.each(function (polygon) {
        if (polygon.dataItem.get('id') === id) {
          found = polygon;
        }
      });
      return found;
    }

    // Creates series with heat rules
    function createSeries(heatfield) {
      var pointSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {
          valueField: heatfield,
          calculateAggregates: true
        })
      );

      var circleTemplate = am5.Template.new(root);
      pointSeries.bullets.push(function () {
        var container = am5.Container.new(root, {});

        var circle = container.children.push(
          am5.Circle.new(
            root,
            {
              radius: 10,
              fill: am5.color(0x000000),
              fillOpacity: 0.7,
              cursorOverStyle: 'pointer',
              tooltipText: '[bold]{name}[/]\nClinics Count: {stores} \nDrifted Assets: {drifted}'
            },
            circleTemplate
          )
        );

        var label = container.children.push(
          am5.Label.new(root, {
            text: '{stores}',
            fill: am5.color(0xffffff),
            populateText: true,
            centerX: am5.p50,
            centerY: am5.p50,
            textAlign: 'center'
          })
        );

        circle.events.on('click', function (ev) {
          var data = ev.target.dataItem.dataContext;

          if (!data.target) {
            return;
          }

          if (!regionalSeries[data.target].series) {
            regionalSeries[data.target].series = createSeries('count');
            regionalSeries[data.target].series.data.setAll(data.markerData);
          }

          if (currentSeries) {
            currentSeries.hide();
          }

          if (data.type === 'state') {
            var statePolygon = getPolygon('US-' + data.state);
            polygonSeries.zoomToDataItem(statePolygon.dataItem);
          } else if (data.type === 'city') {
            chart.zoomToGeoPoint(
              {
                latitude: data.geometry.coordinates[1],
                longitude: data.geometry.coordinates[0]
              },
              64,
              true
            );
          }

          currentSeries = regionalSeries[data.target].series;
          currentSeries.show();
          zoomOut.show();
        });

        return am5.Bullet.new(root, {
          sprite: container
        });
      });

      pointSeries.set('heatRules', [
        {
          target: circleTemplate,
          dataField: 'value',
          min: 10,
          max: 30,
          key: 'radius'
        }
      ]);

      return pointSeries;
    }

    return () => {
      root.dispose();
    };
  }, []);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
};

export default MapChart;
