import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, IconButton, Modal } from '@mui/material';

import { updateLicenseForm } from 'api/asset360.service';
import useNotification from 'hooks/useNotifications';
import { StyledTypography } from 'styled-components/global';
import GlobalForm from 'ui-component/forms/GlobalForm';
import ErrorModal from 'ui-component/Modal/ErrorModal';
import { findFieldConfig, getUrlParams, initializeFormValues, processFieldValue, processFormFields } from 'utils';
import { getLicenseForm } from 'api/asset360.service';

import styles from './styles/LicenseEditModalStyles';

const LicenseEditModal = ({ open, onClose, onRefresh, loading, error, licenseId: LicenseId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const notification = useNotification();
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (LicenseId) {
      setIsLoading(true);
      getLicenseForm(LicenseId).then((res) => {
        setFormData(res.data);
        setIsLoading(false);
      });
    }
  }, [open, LicenseId]);

  // Reset input values when modal closes
  useEffect(() => {
    if (!open) {
      setInputValues({});
    }
  }, [open]);

  // Initialize
  useEffect(() => {
    if (formData) {
      setInputValues(initializeFormValues(formData));
    }
  }, [formData]);

  const handleInputChange = (e, value) => {
    const { name, value: eventValue } = e.target;
    const fieldConfig = findFieldConfig(formData, name);
    const finalValue = processFieldValue(value !== undefined ? value : eventValue, fieldConfig?.type);

    setInputValues((prev) => ({
      ...prev,
      [name]: finalValue
    }));
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    try {
      let licenseId = LicenseId;

      const payload = Object.entries(formData).reduce((acc, [groupKey, fields]) => {
        acc[groupKey] = processFormFields(fields, inputValues);
        return acc;
      }, {});

      const response = await updateLicenseForm(licenseId, payload);

      if (response.status === 200) {
        setShowSuccessModal(true);
      } else {
        notification.error('Failed to update license');
      }
    } catch (error) {
      console.error('Failed to update license:', error);
      notification.error(error?.data?.detail || 'Failed to update license');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    onClose();
    onRefresh?.();
  };

  const FormButtons = ({ onCancel, isSubmitting }) => (
    <Box sx={styles.buttonContainer}>
      <Button variant="outlined" fullWidth onClick={onCancel} disabled={isSubmitting}>
        Cancel
      </Button>
      <Button variant="contained" fullWidth type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Updating...' : 'Update'}
      </Button>
    </Box>
  );

  const renderModalContent = () => {
    if (loading || isLoading) {
      return (
        <Box sx={styles.loaderContainer}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box sx={styles.errorContainer}>
          <StyledTypography color="error" align="center">
            {error}
          </StyledTypography>
          <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      );
    }

    if (!formData) {
      return null;
    }

    return (
      <GlobalForm
        formData={formData}
        onSubmit={handleUpdate}
        resetOnSubmit={false}
        validateOnChange={true}
        initialValues={inputValues}
        onChange={handleInputChange}
        enableReinitialize={true}
      >
        <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
      </GlobalForm>
    );
  };

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="license-edit-modal">
        <Box sx={styles.modalBox}>
          <Box sx={styles.headerSection}>
            <IconButton onClick={onClose} aria-label="close" sx={styles.closeButton}>
              <CloseIcon />
            </IconButton>

            <StyledTypography size="24px" weight="bold">
              Edit Entitlements Details
            </StyledTypography>
          </Box>

          <Box sx={styles.contentSection}>{renderModalContent()}</Box>
        </Box>
      </Modal>

      <ErrorModal open={showSuccessModal} onClose={handleSuccessModalClose} message="License updated successfully" isSuccess={true} />
    </>
  );
};

export default LicenseEditModal;
