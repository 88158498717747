import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

import { multiSeriesBarData } from 'utils/graphUtils';

const BarChartMultiSeries = ({ data, xLabelRotation = -45, storybook = false, xLabel, yLabel }) => {
  const chartId = uuid();
  const chartValues = multiSeriesBarData(storybook ? data?.chartValues : data);
  const rootRef = useRef(null);
  const xAxisRef = useRef(null);
  const seriesRefs = useRef([]);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(chartId);
    rootRef.current = root;

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true
      })
    );

    chart.children.unshift(
      am5.Label.new(root, {
        text: data?.Title,
        fontSize: 16,
        fontWeight: '500',
        textAlign: 'left',
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        y: 0,
        maxWidth: 300,
        wrap: true,
        oversizedBehavior: 'wrap'
      })
    );

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });
    xRenderer.labels.template.setAll({
      rotation: xLabelRotation,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingTop: 3
    });
    xRenderer.grid.template.setAll({
      location: 1
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        currentChart: 'l0',
        maxDeviation: 0.3,
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxHeight: 120,
      maxWidth: 130,
      ellipsis: '...'
    });

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
        min: 0
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        text: yLabel,
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    const createSeries = (chart, xAxis, yAxis, seriesName, seriesLabel) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(rootRef.current, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: seriesName,
          categoryXField: 'category'
        })
      );
      series.columns.template.setAll({
        strokeOpacity: 0,
        tooltipY: 0,
        tooltipText: `${seriesLabel}: {valueY}`
      });
      series.columns.template.adapters.add('fill', (fill, target) => {
        const category = target.dataItem.get('categoryX');
        const categoryData = chartValues.find((item) => item.category === category);
        return categoryData?.color ? am5.color(categoryData.color) : fill;
      });
      return series;
    };

    const seriesNames = chartValues.reduce((acc, item) => {
      item.values.forEach((value) => {
        if (!acc.some((item) => item.key === value.key)) {
          acc.push({ key: value.key, label: value.label });
        }
      });
      return acc;
    }, []);

    seriesNames.forEach((seriesName) => {
      const series = createSeries(chart, xAxis, yAxis, seriesName.key, seriesName.label);
      seriesRefs.current.push(series);
    });

    xAxisRef.current = xAxis;

    return () => {
      root.dispose();
    };
  }, [data]);

  useLayoutEffect(() => {
    if (xAxisRef.current && seriesRefs.current.length > 0) {
      xAxisRef.current.data.setAll(chartValues);
      seriesRefs.current.forEach((series) => {
        series.data.setAll(
          chartValues.flatMap((item) =>
            item?.values.map((value) => ({
              category: item.category,
              [value.key]: value.value
            }))
          )
        );
      });
    }
  }, [data]);

  return <div style={{ width: '100%', height: '500px' }} id={chartId}></div>;
};

export default BarChartMultiSeries;
