import { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

import { ensureCompleteHeatMapData, extractHeatmapLinks, generateDownloadData } from 'utils/graphUtils';

const HeatMap = ({ data: initialData, yLabel = '', xLabel = '', gridSize, manualOrder = false }) => {
  const data = ensureCompleteHeatMapData(initialData, manualOrder);
  const seriesRef = useRef(null);
  const id = uuid();
  const xLabelRotation = gridSize === 12 ? 0 : -45;
  const xLabelPaddingX = gridSize === 12 ? am5.p50 : am5.p100;

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    var root = am5.Root.new(id);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0,
        paddingRight: 0,
        layout: root.verticalLayout
      })
    );

    // Create axes and their renderers
    var yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      minGridDistance: 20,
      inversed: true,
      minorGridEnabled: true
    });

    yRenderer.grid.template.set('visible', false);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: yRenderer,
        categoryField: 'category'
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    var xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 30,
      inversed: true,
      minorGridEnabled: true
    });

    xRenderer.grid.template.set('visible', false);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: 'category'
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'wrap',
      maxWidth: 100,
      rotation: xLabelRotation,
      centerX: xLabelPaddingX,
      dy: 20 // Move labels down
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: 'x',
        categoryYField: 'y',
        valueField: 'value'
      })
    );

    series.columns.template.setAll({
      tooltipText: '{value}',
      strokeOpacity: 1,
      strokeWidth: 2,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      cornerRadiusBL: 5,
      cornerRadiusBR: 5,
      width: am5.percent(100),
      height: am5.percent(100)
    });

    const colors = {
      critical: am5.color(0xca0101),
      verybad: am5.color(0xe17a2d),
      bad: am5.color(0xe1d92d),
      medium: am5.color(0x5dbe24),
      good: am5.color(0x0b7d03)
    };

    series.columns.template.adapters.add('fill', function (fill, target) {
      let dataItem = target.dataItem.dataContext;
      return colors[dataItem.columnSettings.fill] || am5.color(dataItem.columnSettings.fill);
    });

    var circleTemplate = am5.Template.new({});

    // Add heat rule
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    series.set('heatRules', [
      {
        target: circleTemplate,
        min: 10,
        max: 35,
        dataField: 'value',
        key: 'radius'
      }
    ]);

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(
          root,
          {
            fill: am5.color(0x000000),
            fillOpacity: 0.5,
            strokeOpacity: 0
          },
          circleTemplate
        )
      });
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 10,
          text: '{value}'
        })
      });
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data

    seriesRef.current = series;

    const axesCategories = extractHeatmapLinks(data);

    yAxis.data.setAll(axesCategories['yAxis']);

    xAxis.data.setAll(axesCategories['xAxis']);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100);

    const flattenedData = data.map((item) => {
      return {
        x: item.x,
        y: item.y,
        value: item.value
      };
    });

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: data?.title,
      dataSource: generateDownloadData({ data: flattenedData, xLabel, yLabel, chartType: 'heatmap' }),
      pngOptions: {
        quality: 1,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    seriesRef.current.data.setAll(data);
  }, [data]);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
};

export default HeatMap;
