import React from 'react';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import BackButton from 'ui-component/buttons/BackButton';
import ComponentErrorBoundary from 'ui-component/ErrorComponents/CommonErrorWrapper';

const SectionWrapper = ({
  children,
  title = '',
  variant = 'box',
  viewMoreUrl,
  viewMoreText = 'View All',
  headerComponents,
  bordered = true,
  hasBackButton,
  onBack,
  handleBackRoute
}) => {
  const navigate = useNavigate();

  const handleBackNavigation = handleBackRoute ? () => navigate(handleBackRoute) : onBack ? onBack : () => navigate(-1);

  switch (variant) {
    case 'top':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {title && (
            <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={2}>
                {hasBackButton && <BackButton size={24} onBack={onBack ? onBack : () => navigate(-1)} />}
                {title && (
                  <Typography
                    sx={{
                      fontSize: '2rem',
                      fontWeight: 500,
                      ml: 0
                    }}
                  >
                    {title}
                  </Typography>
                )}
              </Box>
              {viewMoreUrl && (
                <Typography
                  onClick={() => navigate('/data-product')}
                  variant="p"
                  sx={{ fontWeight: 600, color: '#2a77f4', cursor: 'pointer' }}
                >
                  {viewMoreText}
                </Typography>
              )}
              {headerComponents || null}
            </Box>
          )}
          <Box>
            <ComponentErrorBoundary componentTitle={title}>{children}</ComponentErrorBoundary>
          </Box>
        </Box>
      );
    case 'box':
      return (
        <Box className={bordered && 'box'} display={'flex'} flexDirection={'column'} gap={1}>
          {title && (
            <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={1} marginBottom={2}>
                {hasBackButton && <BackButton onBack={handleBackNavigation} />}
                {title && (
                  <Typography
                    variant="bodyLg"
                    color={'#434C5B'}
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 500,
                      ml: 0
                    }}
                  >
                    {title}
                  </Typography>
                )}
              </Box>
              {viewMoreUrl && (
                <Typography
                  data-test-id="view-all-data-products"
                  onClick={() => navigate('/data-product')}
                  variant="p"
                  sx={{ fontWeight: 600, color: '#2a77f4', cursor: 'pointer' }}
                >
                  {viewMoreText}
                </Typography>
              )}
              {headerComponents || null}
            </Box>
          )}
          <Box>
            <ComponentErrorBoundary componentTitle={title}>{children}</ComponentErrorBoundary>
          </Box>
        </Box>
      );
    case 'inout':
    default:
      return (
        <>
          {title && (
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
              {title && (
                <Typography
                  variant="bodyLg"
                  color={'#434C5B'}
                  sx={{
                    marginBottom: 1,
                    cursor: 'pointer',
                    fontSize: '18px',
                    fontWeight: 600
                  }}
                >
                  {title}
                </Typography>
              )}
            </Box>
          )}
          <Box className={bordered && 'box'} display={'flex'} flexDirection={'column'}>
            <ComponentErrorBoundary componentTitle={title}>{children}</ComponentErrorBoundary>
          </Box>
        </>
      );
  }
};

export default SectionWrapper;
