import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5radar from '@amcharts/amcharts5/radar';
// import * as am5flow from '@amcharts/amcharts5/flow';
import * as am5xy from '@amcharts/amcharts5/xy';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

const RadarChart = ({ data, downloadTitle = 'Chart', targetTitle = 'Total Upcoming Renewals' }) => {
  const id = uuid();
  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    const root = am5.Root.new(id);

    // Create custom theme
    // https://www.amcharts.com/docs/v5/concepts/themes/#Quick_custom_theme
    const myTheme = am5.Theme.new(root);
    myTheme.rule('Label').set('fontSize', 10);
    myTheme.rule('Grid').set('strokeOpacity', 0.06);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    // tell that valueX should be formatted as a date (show week number)
    root.dateFormatter.setAll({
      dateFormat: 'w',
      dateFields: ['valueX']
    });

    root.locale.firstDayOfWeek = 0;

    var weeklyData = [];
    var dailyData = [];

    var firstDay = am5.time.round(new Date(data[0]['date']), 'year', 1);
    var total = 0;
    am5.DateFormatter.new(root, {});
    var weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var weekAxisData = [{ day: 'Sun' }, { day: 'Mon' }, { day: 'Tue' }, { day: 'Wed' }, { day: 'Thu' }, { day: 'Fri' }, { day: 'Sat' }];

    var colorSet = am5.ColorSet.new(root, {});

    // PREPARE DATA
    function prepareInvoiceAmountData(data) {
      for (var i = 0; i <= 53; i++) {
        weeklyData[i] = {};
        weeklyData[i].distance = 0;
        var date = new Date(firstDay);
        date.setDate(i * 7);
        am5.time.round(date, 'week', 1);
        var endDate = am5.time.round(new Date(date), 'week', 1);

        weeklyData[i].date = date.getTime();
        weeklyData[i].endDate = endDate.getTime();
      }

      am5.array.each(data, function (di) {
        var date = new Date(di['date']);
        var weekDay = date.getDay();
        var weekNumber = moment(date).week() - 1;

        if (weekNumber == 0 && date.getMonth() == 11) {
          weekNumber = 53;
        }

        var distance = am5.math.round(di['distance']);

        weeklyData[weekNumber].distance += distance;
        weeklyData[weekNumber].distance = am5.math.round(weeklyData[weekNumber].distance, 1);
        total += distance;
        const thisDate = moment(date).toDate();
        dailyData.push({
          date: thisDate.getTime(),
          day: weekdays[weekDay],
          distance: distance,
          title: di['name'],
          weekNumber: weekNumber,
          actualWeekNumber: moment(date).week()
        });
      });
    }

    prepareInvoiceAmountData(data);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: 'panX',
        // wheelY: 'zoomX',
        innerRadius: am5.percent(20),
        radius: am5.percent(85),
        startAngle: 270 - 170,
        endAngle: 270 + 170
      })
    );

    const firstDate = new Date(data[0].date);
    const lastDate = new Date(data[data.length - 1].date);

    // const firstYear = firstDate.getFullYear();
    // const lastYear = lastDate.getFullYear();

    // Adjust firstDate to the beginning of the month
    const adjustedFirstDate = new Date(firstDate.getFullYear(), firstDate.getMonth(), 1);
    // Adjust lastDate to the end of the month
    const adjustedLastDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0);

    // Set min and max for DateAxis
    const minDate = adjustedFirstDate.getTime();
    const maxDate = adjustedLastDate.getTime();

    const number = new Intl.NumberFormat(navigator?.language || 'en-US', { maximumSignificantDigits: 3 }).format(total);

    // add label in the center
    chart.radarContainer.children.push(
      am5.Label.new(root, {
        text: `[fontSize:0.8em]${targetTitle}\n [/][fontSize:1.5em]$${number}[/]`,
        textAlign: 'center',
        centerX: am5.percent(50),
        centerY: am5.percent(50)
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    var cursor = chart.set(
      'cursor',
      am5radar.RadarCursor.new(root, {
        behavior: 'zoomX'
      })
    );
    cursor.lineY.set('visible', false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes

    // date axis
    var dateAxisRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridInvoiceAmount: 20
    });

    dateAxisRenderer.labels.template.setAll({
      radius: 30,
      textType: 'radial',
      centerY: am5.p50
    });

    var dateAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'week', count: 1 },
        renderer: dateAxisRenderer,
        min: minDate,
        max: maxDate
      })
    );

    // distance axis
    var InvoiceAmountAxisRenderer = am5radar.AxisRendererRadial.new(root, {
      axisAngle: 90,
      radius: am5.percent(60),
      innerRadius: am5.percent(20),
      inversed: true,
      minGridInvoiceAmount: 20
    });

    InvoiceAmountAxisRenderer.labels.template.setAll({
      centerX: am5.p50,
      minPosition: 0.05,
      maxPosition: 0.95
    });

    var InvoiceAmountAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: InvoiceAmountAxisRenderer
      })
    );

    // InvoiceAmountAxis.set('numberFormat', "# ' km'");

    // week axis
    var weekAxisRenderer = am5radar.AxisRendererRadial.new(root, {
      axisAngle: 90,
      innerRadius: am5.percent(60),
      radius: am5.percent(100),
      minGridInvoiceAmount: 20
    });

    weekAxisRenderer.labels.template.setAll({
      centerX: am5.p50
    });

    var weekAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'day',
        renderer: weekAxisRenderer
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    var InvoiceAmountSeries = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: InvoiceAmountAxis,
        valueYField: 'distance',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: 'week {valueX}: {valueY}'
        })
      })
    );

    InvoiceAmountSeries.columns.template.set('strokeOpacity', 0);

    // Set up heat rules
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    InvoiceAmountSeries.set('heatRules', [
      {
        target: InvoiceAmountSeries.columns.template,
        key: 'fill',
        min: am5.color(0x673ab7),
        max: am5.color(0xf44336),
        dataField: 'valueY'
      }
    ]);

    // bubble series is a line series with stroeks hiddden
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    var bubbleSeries = chart.series.push(
      am5radar.RadarLineSeries.new(root, {
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: weekAxis,
        baseAxis: dateAxis,
        categoryYField: 'day',
        valueXField: 'date',
        valueField: 'distance',
        maskBullets: false
      })
    );

    // only bullets are visible, hide stroke
    bubbleSeries.strokes.template.set('forceHidden', true);

    // add bullet
    var circleTemplate = am5.Template.new({});
    bubbleSeries.bullets.push(function () {
      var graphics = am5.Circle.new(
        root,
        {
          fill: InvoiceAmountSeries.get('fill'),
          tooltipText: '{title}: {value} $'
        },
        circleTemplate
      );
      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });

    // Add heat rule (makes bubbles to be of a various size, depending on a value)
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    bubbleSeries.set('heatRules', [
      {
        target: circleTemplate,
        min: 3,
        max: 15,
        dataField: 'value',
        key: 'radius'
      }
    ]);

    // set data
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data

    InvoiceAmountSeries.data.setAll(weeklyData);
    weekAxis.data.setAll(weekAxisData);
    bubbleSeries.data.setAll(dailyData);

    bubbleSeries.appear(1000);
    InvoiceAmountSeries.appear(1000);
    chart.appear(1000, 100);

    bubbleSeries.appear(1000);
    InvoiceAmountSeries.appear(1000);
    chart.appear(1000, 100);

    // create axis ranges
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var i = 0; i < 24; i++) {
      createRange(months[i], i);
    }

    function createRange(name, index) {
      var axisRange = dateAxis.createAxisRange(dateAxis.makeDataItem({ above: true }));
      axisRange.get('label').setAll({ text: name });

      var fromTime = new Date(firstDay.getFullYear(), i, 1, 0, 0, 0).getTime();
      var toTime = am5.time.add(new Date(fromTime), 'month', 1).getTime();

      axisRange.set('value', fromTime);
      axisRange.set('endValue', toTime);

      // every 2nd color for a bigger contrast
      var fill = axisRange.get('axisFill');
      fill.setAll({
        toggleKey: 'active',
        cursorOverStyle: 'pointer',
        fill: colorSet.getIndex(index * 2),
        visible: true,
        dRadius: 25,
        innerRadius: -25
      });
      axisRange.get('grid').set('visible', false);

      var label = axisRange.get('label');
      label.setAll({
        fill: am5.color(0xffffff),
        textType: 'circular',
        radius: 8,
        text: months[index]
      });

      // clicking on a range zooms in
      fill.events.on('click', function (event) {
        var dataItem = event.target.dataItem;
        if (event.target.get('active')) {
          dateAxis.zoom(0, 1);
        } else {
          dateAxis.zoomToValues(dataItem.get('value'), dataItem.get('endValue'));
        }
      });
    }

    const downloadedData = data?.map((item) => ({
      Name: item?.name,
      Type: item?.type,
      'Aannual Cost': item?.distance,
      // 'Number of Licenses': item?.time,
      'Renewal Date': item?.date
    }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: downloadedData,
      filePrefix: downloadTitle,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });
    return () => {
      root.dispose();
    };

    // eslint-disable-next-line
  }, [data]);

  return <div id={id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '450px' }} />;
};

export default RadarChart;
