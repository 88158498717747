import { Card } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// ==============================|| SKELETON - Graph||============================== //

const GraphSkeleton = () => (
  <Card sx={{ height: '500px', width: '100%' }}>
    <Skeleton sx={{ transform: 'scale(1, 0.95)' }} height={'3%'} width={'100%'} />
    <Skeleton sx={{ transform: 'scale(1, 0.95)' }} height={'97%'} width={'100%'} />
  </Card>
);

export default GraphSkeleton;
