const styles = {
  actionIcon: {
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#2A76F4',
      transition: 'color 0.1s ease'
    }
  },
  emptyRequiredCell: {
    border: '1px solid #FC8080',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: '5px',
    textAlign: 'center',
    padding: '1px 5px',
    width: '80px',
    height: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  navlink: {
    textDecoration: 'none',
    color: 'black'
  },
  activeChip: {
    backgroundColor: '#EFF8E6',
    color: '#4CAF50'
  },
  inactiveChip: {
    backgroundColor: '#FEE9E9',
    color: '#F44336'
  },
  urlColor: '#2A76F4',
  urlHoverColor: '#1E5BB8',
  urlButton: {
    color: '#2A76F4',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '&:hover': {
      color: '#1E5BB8',
      transition: 'color 0.1s ease'
    }
  },
  superTagsContainer: (isSingleValue) => ({
    display: 'flex',
    flexDirection: 'column',
    color: 'black',
    bgcolor: '#2A76F41A',
    borderRadius: 2,
    width: '100%',
    position: 'relative',
    top: '41%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '55px',
    padding: '4px 8px',
    margin: '6px 0',
    justifyContent: isSingleValue ? 'center' : 'flex-start'
  }),
  superTagsContentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  superTagsScrollContainer: (isSingleValue) => ({
    width: isSingleValue ? '100%' : '90%',
    overflow: 'auto',
    display: isSingleValue ? 'flex' : 'block',
    alignItems: isSingleValue ? 'center' : 'flex-start',
    '&::-webkit-scrollbar': {
      height: '3px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px'
    }
  }),
  superTagsSingleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  superTagsTypography: {
    padding: '1px 0',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem'
  },
  superTagsTooltip: {
    padding: '8px',
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px'
    }
  },
  superTagsTooltipTypography: {
    padding: '3px 0',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    color: 'white'
  },
  personasTypography: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '10px',
    color: '#434C5B'
  },
  fileViewBox: {
    display: 'flex',
    alignItems: 'center'
  },
  fileViewTypography: {
    marginX: '5px'
  },
  fileViewLink: {
    color: '#2A76F4',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1E5BB8'
    }
  },
  profileImage: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#E6EFFF',
    color: '#434C5B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  invoiceStatusBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  invoiceStatusTypography: {
    textAlign: 'center'
  },
  infoIcon: {
    color: '#616161',
    marginLeft: '0.2em',
    cursor: 'help',
    fontSize: '18px'
  },
  superTagsMoreCount: {
    padding: '2px 4px',
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: '#2A76F4',
    backgroundColor: '#E6EFFF',
    borderRadius: '4px'
  }
};

export default styles;
