import React from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/system';

import { formatCurrency } from 'utils';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 40,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#AAC8FB',
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800]
    })
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#3F84F5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#3F84F5'
    })
  }
}));

const ProgressBar = ({ data, dropdownValue }) => {
  const { total, progress = 60, leftSideText, rightSideText } = data || {};

  // ! its a disaster, need to fix this
  // const prefix = dropdownValue === 'Cost' ? '$' : '';
  const postfix = dropdownValue === 'Percent' ? '%' : '';

  const percentage = (progress / total) * 100;

  return (
    <Box width={'100%'}>
      <Box marginY={2} display={'flex'} justifyContent={'space-between'}>
        <Typography>
          {leftSideText}&nbsp;:&nbsp;
          <strong>
            {/* {prefix}&nbsp; */}
            {dropdownValue === 'Cost' ? formatCurrency(progress) : progress}
            {postfix}&nbsp;
          </strong>
        </Typography>
        <Typography>
          {rightSideText}&nbsp;:&nbsp;
          <strong>
            {/* {prefix}&nbsp; */}
            {dropdownValue === 'Cost' ? formatCurrency(total - progress) : total - progress}
            {postfix}&nbsp;
          </strong>
        </Typography>
      </Box>
      <BorderLinearProgress variant="determinate" value={percentage} />
    </Box>
  );
};

export default ProgressBar;
