import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';

import img from 'assets/images/errorBoundary.svg';

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // to keep track of when an error occurs
    // and the error itself
    this.state = {
      hasError: false,
      error: undefined
    };

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  // update the component state when an error occurs
  static getDerivedStateFromError(error) {
    console.error('error : ', error);
    // specify that the error boundary has caught an error
    return {
      hasError: true,
      error: error
    };
  }

  resetErrorBoundary() {
    this.setState({ hasError: false });
  }

  // defines what to do when an error gets caught
  componentDidCatch(error, errorInfo) {
    // log the error

    console.error(error);
    console.error(errorInfo);

    // record the error in an APM tool...
  }

  render() {
    if (this.state.hasError) {
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '250px',
        gap: 2
      };

      const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '1rem'
      };

      const textContainerStyle = {
        width: '100%',
        textAlign: 'center'
      };

      return (
        <Box
          sx={{
            width: '60%',
            m: 'auto'
          }}
        >
          <Box sx={containerStyle}>
            <Box component="img" src={img} alt="Something went wrong" sx={imageStyle} />
            <Box sx={textContainerStyle}>
              <Typography variant="h4" gutterBottom>
                Oops! We couldn&apos;t load that.
              </Typography>
              <Typography>
                Sorry, this isn&apos;t supposed to happen. But our team is aware and is working on a fix.
                <br />
                Hang tight! Need support instead? Write to us at <a href="mailto:support@asato.ai">support@asato.ai</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ComponentErrorBoundary;
