// material-ui
import { Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// ==============================|| SKELETON - InsightTable||============================== //

const InsightTableSkeleton = () => (
  <Card sx={{ width: '100%' }}>
    <CardContent>
      <Grid container direction="column">
        <Grid item>
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} width={'30%'} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} />
          <Skeleton variant="rectangular" sx={{ my: 2 }} height={24} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default InsightTableSkeleton;
