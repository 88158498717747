import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Delete } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Chip, FormControl, Link as MuiLink, MenuItem, Tooltip, Typography } from '@mui/material';
import { DocumentText1, Edit, Edit2, ExportSquare, Eye, TickCircle } from 'iconsax-react';

import { updateAssignmentStatus } from 'api/api';
import { STATUS_COLOR } from 'constants/colors';
import { setSelectedId } from 'store/slices/CategoryDetailsSlice';
import { changeStep, SetIsEditTrue } from 'store/slices/StepperSlice';
import { StyledChip, StyledIconWrapper, StyledSelect } from 'styled-components/global';
import GlobalButton from 'ui-component/buttons';
import CustomizedSwitches from 'ui-component/buttons/CustomSwitch';
import ErrorMessageWithIcon from 'ui-component/Modal/ErrorMessageWithIcon';
import PopOverTab from 'ui-component/Modal/PopOverTab';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';
import { formatNumber } from 'utils';
import { useFunctionContext } from 'views/common/FunctionContext';

import styles from './Styles/DataSourceTableStyles';

export const ActionIcon = ({ onClick }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box sx={styles.actionIcon} title="View" onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Eye size="20" color={hover ? '#2A76F4' : '#818690'} variant={hover ? 'Bulk' : 'Outline'} style={{ marginRight: '8px' }} />
      View
    </Box>
  );
};

export const MenuOptionIcon = ({ IconComponent, label }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box gap={1} sx={styles.actionIcon} title={label} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <IconComponent size="24" color={hover ? '#2A76F4' : '#818690'} variant={hover ? 'Bulk' : 'Outline'} />
      {label}
    </Box>
  );
};

// Custom hook for navigation-related functions
export const useNavigationHandlers = (selectedChip) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenu = (item) => {
    sessionStorage.setItem('selectedId', item?.name);
    dispatch(setSelectedId(item?.name));
    navigate(`/link/API/connector-input-params`);
    dispatch(changeStep(1));
    dispatch(SetIsEditTrue(true));
  };

  const handleRow = (row) => {
    const link = row?.redirectUrl ? row?.redirectUrl : `/link?type=${selectedChip}&source_id=${row.name}`;
    navigate(link, { replace: false });
  };

  const menuOptions = [
    {
      label: 'View',
      icon: <MenuOptionIcon IconComponent={Eye} label="View" />,
      onClick: handleRow
    },
    {
      label: 'Edit',
      icon: <MenuOptionIcon IconComponent={Edit} label="Edit" />,
      onClick: handleMenu
    }
  ];

  return { menuOptions, handleMenu, handleRow };
};

export const ConnectionStatusRenderer = ({
  data,
  params,
  classes,
  // handleNavigate,
  handleFileDownload,
  handleRow,
  handleTaskOpen,
  handleStatusChange,
  handleSwitchChange,
  handleEdit,
  onRowDelete,
  onEditClick,
  onDeleteClick,
  pageType,
  navigate,
  i,
  menuOptions,
  apiCallOnPage,
  requestParams
}) => {
  const { sharedFunction } = useFunctionContext() || {};
  const { taskStatus } = sharedFunction || '';
  const value = params?.value?.toString();
  const isLongValue = value && value.length > 18;
  const isEmpty = params?.value === '' || params?.value === null || params?.value === undefined;

  const formControlValues = {};
  const color = data?.columnColor || 'Default';
  const status_color = STATUS_COLOR[value] || 'Default';

  if (data?.dataType === 'nonEmpty' && isEmpty) {
    return (
      <Box sx={styles.emptyRequiredCell}>
        <Typography color="error">{'-'}</Typography>
      </Box>
    );
  }

  if (isEmpty) return '-';

  switch (data.key) {
    case 'personas': {
      const label = params?.value?.map((i) => i?.name).join(', ');
      return (
        <Tooltip title={label}>
          <Typography variant="bodySm" sx={styles.personasTypography}>
            {label}
          </Typography>
        </Tooltip>
      );
    }
    default:
      break;
  }

  switch (data?.dataType) {
    case 'email':
      return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>{value}</div>;

    case 'status':
      return (
        <Box className={params.value ? classes?.True : classes?.False} mt={1} sx={{ textTransform: 'capitalize' }}>
          {params.value ? 'Active' : 'Inactive'}
        </Box>
      );

    case 'status_info':
      return params?.value ? (
        <Box display="flex" alignItems="center" gap={3} my={1.5} sx={{ textTransform: 'capitalize' }}>
          <Chip variant="outlined" label={value} color={params.value === 'success' ? 'success' : 'error'} />
        </Box>
      ) : (
        '-'
      );

    case 'color_chip':
      return <StyledChip variant="outlined" label={value} color={STATUS_COLOR[value] || 'warning'} />;

    case 'status_button': {
      const type = params?.value?.type;
      const currentValue = formControlValues[params?.row?.name] || params?.value?.currentStatus;
      const options = params?.value?.options || [];

      switch (type) {
        case 'button':
          return (
            <Box display="flex" gap={1} mt={1}>
              {options.map((btn) => (
                <GlobalButton
                  key={btn?.apiKey}
                  variant="outlined"
                  onClick={() => handleTaskOpen(params?.row, btn?.apiKey == 'Accepted' ? 'accept' : 'reject')}
                >
                  {btn?.label}
                </GlobalButton>
              ))}
            </Box>
          );
        case 'select':
          return (
            <FormControl size="small">
              <StyledSelect
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}
                stylevariant={'no-border'}
                color={STATUS_COLOR[currentValue] || 'warning'}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentValue}
                onChange={(e) => handleStatusChange(e, params.row.id, currentValue, e.target.value)}
                disabled={
                  options.length === 1 ||
                  (pageType === 'task-detail' &&
                    (taskStatus === 'Completed' || taskStatus === 'Rejected' || taskStatus === 'Closed by User'))
                }
              >
                {options?.map((task) => (
                  <MenuItem key={task.apiKey} value={task.label}>
                    {task.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          );
        default:
          return null;
      }
    }

    case 'tag':
      return params?.value ? (
        <Box display="flex" alignItems="center" gap={1} my={1.5}>
          {(Array.isArray(value) ? value : value.split(/[ ,]+/)).map((val, index) => (
            <Chip
              key={index}
              label={val.trim()}
              color={
                val.trim() === 'Asset' ? 'primary' : val.trim() === 'Cost' ? 'secondary' : val.trim() === 'Usage' ? 'default' : 'error'
              }
            />
          ))}
        </Box>
      ) : (
        '-'
      );
    case 'superTags': {
      const values = Array.isArray(params.value) ? params.value : params.value.split(',');
      const isSingleValue = values.length === 1;

      if (!params?.value || values.length === 0) return '-';

      return (
        <Box sx={styles.superTagsContainer(isSingleValue)}>
          <OnHoverToolTip
            title={
              !isSingleValue && (
                <Box sx={styles.superTagsTooltip}>
                  {values.map((val, index) => (
                    <Typography key={index} variant="body2" sx={styles.superTagsTooltipTypography}>
                      {val?.trim()}
                    </Typography>
                  ))}
                </Box>
              )
            }
            variant="children"
            placement="right-end"
          >
            <Box sx={styles.superTagsContentWrapper}>
              <Box sx={styles.superTagsScrollContainer(isSingleValue)}>
                {values.slice(0, isSingleValue ? 1 : 2).map((val, index) => (
                  <Typography key={index} variant="body2" sx={styles.superTagsTypography}>
                    {val?.trim()}
                  </Typography>
                ))}
              </Box>
              {!isSingleValue && values.length > 2 && (
                <Typography variant="body2" sx={styles.superTagsMoreCount}>
                  {`+${values.length - 2}`}
                </Typography>
              )}
            </Box>
          </OnHoverToolTip>
        </Box>
      );
    }

    case 'invoice_status': {
      const { invoiceStatus: sta, info } = params.row || {};
      const isProcessing = sta === 'Processing';

      const btn = isProcessing
        ? {
            text: 'Processing ↻',
            color: '#434C5B',
            onClick: () => apiCallOnPage(requestParams)
          }
        : null;

      const boxStyle = {
        height: '100%',
        cursor: isProcessing ? 'pointer' : 'default'
      };

      return (
        <MuiLink
          title={params.value || ''}
          className={classes.urlButton}
          onClick={(e) => {
            e.preventDefault();
            if (btn?.onClick) btn.onClick();
          }}
        >
          <Box display="flex-start" sx={boxStyle}>
            {isProcessing && btn?.icon && <>{btn.icon}</>}
            <OnHoverToolTip title={Array.isArray(info) ? info?.map((item, index) => <div key={index}>{item}</div>) : ''} variant="children">
              <Box sx={styles.invoiceStatusBox}>
                <Typography variant="button" color={btn ? btn.color : status_color} sx={styles.invoiceStatusTypography}>
                  {isProcessing ? btn.text : params?.value || '-'}
                </Typography>
                {info?.length > 0 && <InfoIcon sx={styles.infoIcon} />}
              </Box>
            </OnHoverToolTip>
          </Box>
        </MuiLink>
      );
    }

    case 'link': {
      if (!params?.value) return '-';

      let displayText = 'Link';
      const url = params.value?.toLowerCase();

      if (url?.endsWith('.pdf')) {
        displayText = 'PDF';
      } else if (url?.endsWith('.csv')) {
        displayText = 'CSV';
      }

      return (
        <MuiLink
          // title={params.value || ''}
          href="#"
          sx={{ color: color }}
          onClick={(e) => {
            e.preventDefault();
            handleFileDownload(params.value);
          }}
        >
          {displayText}
        </MuiLink>
      );
    }

    case 'mouseEvent':
      return <ActionIcon onClick={() => handleRow(params.row)} />;

    case 'fileView': {
      const fileUrl = params?.value;
      return fileUrl ? (
        <Box sx={styles.fileViewBox}>
          <DocumentText1 size="18" />
          <Typography sx={styles.fileViewTypography}>Terms.doc</Typography>
          <MuiLink href={fileUrl} target="_blank" sx={styles.fileViewLink}>
            View
          </MuiLink>
        </Box>
      ) : (
        '-'
      );
    }

    case 'profileName': {
      const fullName = params?.value || '';
      const nameParts = fullName.split(' ');
      const initials = nameParts.length > 1 ? nameParts[0][0] + nameParts[nameParts.length - 1][0] : nameParts[0][0];

      return (
        <Box display="flex" alignItems="center" height="100%" gap={2}>
          <Box sx={styles.profileImage}>{initials}</Box>
          <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
            {fullName}
          </Typography>
        </Box>
      );
    }

    case 'redirectUrl': {
      if (pageType === 'dataset-detail') {
        return <ActionIcon onClick={() => navigate(value)} />;
      }
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box mt={0.5}>
            <PopOverTab params={params} divOptions={menuOptions} />
          </Box>
        </Box>
      );
    }

    case 'error':
      return params.value ? <ErrorMessageWithIcon message={params.value} /> : '';

    case 'verified':
      return <TickCircle size="24" color="#5DB806" />;

    case 'switch':
      return (
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <CustomizedSwitches onChange={() => handleSwitchChange(params.row, i)} check={params.value} />
          <Edit2
            size="32"
            color="#2A76F4"
            style={{
              height: '20px',
              width: '20px',
              cursor: 'pointer',
              textTransform: 'capitalize'
            }}
            onClick={() => handleEdit(params.row)}
          />
        </Box>
      );

    case 'Url': {
      const status = params.row?.status || '';

      let buttonProps = {};

      if (status === 'success') {
        buttonProps = {
          text: 'View',
          color: 'primary',
          onClick: () => navigate(params.row.redirectUrl)
        };
      } else if (status === 'inProgress') {
        buttonProps = {
          text: 'Refresh ↻',
          color: 'default',
          onClick: () => apiCallOnPage(requestParams)
        };
      } else if (status === 'failed') {
        buttonProps = {
          text: 'Failed',
          color: 'error'
        };
      }

      return (
        <MuiLink
          title={params.value || ''}
          className={classes.urlButton}
          onClick={(e) => {
            e.preventDefault();
            if (buttonProps.onClick) buttonProps.onClick();
          }}
        >
          <Typography variant="button" color={buttonProps.color} sx={{ textTransform: 'capitalize' }}>
            {buttonProps.text}
          </Typography>
        </MuiLink>
      );
    }

    case 'href': {
      const hrefKey = data?.tableRedirectKey || 'redirectUrl';
      const href = params?.row?.[hrefKey];
      const invoiceId = params?.row?.invoiceId;

      const handleClick = async (e) => {
        e.preventDefault();

        if (pageType === 'invoice-list') {
          navigate(href);
          try {
            await updateAssignmentStatus(invoiceId, params?.row?.action);
          } catch (error) {
            console.error('Error updating assignment status:', error);
          }
        } else {
          navigate(href);
        }
      };

      return href ? (
        <MuiLink sx={{ cursor: 'pointer', color: status_color }} onClick={handleClick}>
          {value}
        </MuiLink>
      ) : (
        value
      );
    }

    case 'delete':
      return (
        <Box onClick={() => onRowDelete(params.row)} display="flex" alignItems="center" justifyContent="flex-start" height={'100%'}>
          <StyledIconWrapper round={'circle'}>
            <Delete
              size="24"
              color={params.row.delete === false ? 'grey' : 'error'}
              sx={{ cursor: params.row.delete === false ? 'not-allowed' : 'pointer' }}
            />
          </StyledIconWrapper>
        </Box>
      );

    case 'data':
    case 'number':
    case 'percentage':
    case 'date':
    case 'currency':
      return (
        <span>
          {formatNumber({
            number: value,
            format: data?.dataType,
            formatCurrency: data?.formatCurrency,
            options: {
              currencyType: params.row?.currencyType
            }
          })}
        </span>
      );

    case 'external_url':
      return (
        <a style={{ textDecoration: 'underline', color: 'hsl(210, 100%, 36%)' }} href={value} target="_blank" rel="noreferrer">
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {value} <ExportSquare size={14} />
          </div>
        </a>
      );

    case 'modify':
      return (
        <Box display="flex" alignItems="center" gap={1} height="100%">
          <StyledIconWrapper round={'circle'} onClick={() => onEditClick?.(params.row)}>
            <Edit2 size="20" color="#2A76F4" />
          </StyledIconWrapper>
          <StyledIconWrapper round={'circle'} onClick={() => onDeleteClick?.(params.row)}>
            <Delete size="20" color="error" />
          </StyledIconWrapper>
        </Box>
      );

    default:
      return (
        <OnHoverToolTip variant="children" placement="bottom-start" title={isLongValue ? value : ''} className="cell-renderer">
          <span style={{ textTransform: 'capitalize' }}>{value}</span>
        </OnHoverToolTip>
      );
  }
};
