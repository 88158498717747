import React, { useLayoutEffect, useMemo, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

//to Do need to fix height
const Barchart = ({
  rotation = '-45',
  data,
  xLabel,
  yLabel,
  downloadTitle = 'Chart',
  yFormat = 'number',
  xFormat = 'string',
  height = '500px'
}) => {
  const id = useMemo(() => uuid(), []);
  const xAxisRef = useRef(null);
  const seriesRef = useRef(null);

  const chartData = useMemo(
    () =>
      data?.map((i) => ({
        ...i,
        xaxisValue: xFormat === 'date' && !isNaN(Date.parse(i.xaxisValue)) ? moment(i.xaxisValue).format('ll') : i.xaxisValue,
        averageValue: i.averageValue,
        yaxisValue: Number(i.yaxisValue),
        actualyaxisValue: i.yaxisValue
      })),
    [data, xFormat]
  );

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    const root = am5.Root.new(id);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        paddingLeft: 0,
        paddingRight: 1,
        autoResize: false,
        interpolationDuration: 0
      })
    );

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });
    xRenderer.labels.template.setAll({
      rotation,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingTop: 3
    });
    xRenderer.grid.template.setAll({
      location: 1
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'xaxisValue',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxHeight: 120,
      maxWidth: 95,
      ellipsis: '...'
    });

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        maxDeviation: 0.3,
        renderer: yRenderer,
        min: 0,
        numberFormat: yFormat === 'currency' ? "'$'#a" : '#a'
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    yAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxHeight: 120,
      maxWidth: 80,
      ellipsis: '...'
    });

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'yaxisValue',
        sequencedInterpolation: true,
        categoryXField: 'xaxisValue',
        tooltip: am5.Tooltip.new(root, {
          labelText: `{categoryX} : {actualyaxisValue}` + (data.some((item) => item.averageValue) ? `\nAverage: {averageValue}` : '')
        })
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      width: am5.percent(50),
      fill: am5.color('#2A76F4')
    });

    series.columns.template.adapters.add('fillGradient', (fillGradient, target) => {
      const dataItem = target.dataItem?.dataContext;
      const defaultPrimaryColor = '#2A76F4';
      const defaultSecondaryColor = '#7FADF8';

      const primaryColor = dataItem?.color || defaultPrimaryColor;
      const secondaryColor = dataItem?.color || defaultSecondaryColor;

      return am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(primaryColor),
            offset: 0.1
          },
          {
            color: am5.color(secondaryColor)
          }
        ],
        rotation: 90
      });
    });

    xAxisRef.current = xAxis;
    seriesRef.current = series;

    const downloadedData = chartData?.map((item) => ({ [xLabel]: item?.xaxisValue, [yLabel]: item?.yaxisValue }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: downloadTitle,
      dataSource: downloadedData,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };
  }, [chartData, downloadTitle, id, rotation, xLabel, yFormat, yLabel]);

  useLayoutEffect(() => {
    xAxisRef.current.data.setAll(chartData);
    seriesRef.current.data.setAll(chartData);
  }, [chartData]);

  return <div id={id} style={{ width: '100%', height }} />;
};

export default Barchart;
