import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import moment from 'moment';

const BoxPlot = ({ data, xLabel, yLabel, downloadTitle = 'Chart' }) => {
  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const medianaSeriesRef = useRef(null);

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    let root = am5.Root.new(chartRef.current);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true
        // wheelX: 'panX',
        // wheelY: 'zoomX'
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: 'zoom',
          minorGridEnabled: true,
          minGridDistance: 70
        }),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom'
        })
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        fontSize: 16,
        fontWeight: 600,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    var color = root.interfaceColors.get('background');

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.CandlestickSeries.new(root, {
        fill: color,
        stroke: color,
        name: 'MDXI',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'close',
        openValueYField: 'open',
        lowValueYField: 'low',
        highValueYField: 'high',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: 'High: {highValueY}\nQ3: {valueY}\nMedian: {mediana}\nQ1: {openValueY}\nLow: {lowValueY}'
        })
      })
    );

    // mediana series
    var medianaSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        stroke: root.interfaceColors.get('background'),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'mediana',
        valueXField: 'date',
        noRisers: true
      })
    );

    //median
    for (let i = 0; i < data.length; i++) {
      const dataItem = data[i];
      dataItem.mediana = dataItem.median;
    }

    medianaSeries.data.setAll(data);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis
      })
    );
    cursor.lineY.set('visible', false);

    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd'
    });

    series.data.setAll(data);

    seriesRef.current = series;
    medianaSeriesRef.current = medianaSeries;

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    // // series.appear(1000, 100);
    // medianaSeries.appear(1000, 100);
    // chart.appear(1000, 100);

    const downloadedData = data?.map((item) => ({
      Date: moment(item?.date).format('YYYY-MM-DD'),
      Low: item?.low,
      High: item?.high,
      Median: item?.median,
      Q1: item?.open,
      Q3: item?.close
    }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: downloadedData,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      },
      filePrefix: downloadTitle
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    seriesRef.current.data.setAll(data);
    medianaSeriesRef.current.data.setAll(data);
  }, [data]);

  return <div id="chartdiv" style={{ width: '100%', height: '500px' }} ref={chartRef}></div>;
};

export default BoxPlot;
