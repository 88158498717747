/* eslint-disable*/
import { useRef, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { formatCurrency } from 'utils';
import { v4 as uuid } from 'uuid';
import { generateDownloadData } from 'utils/graphUtils';

const StackedColumnChart = ({ data, yFormat = 'number', xLabel = '', yLabel = '' }) => {
  const xAxisRef = useRef(null);

  const chartId = uuid();

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);

    var root = am5.Root.new(chartId);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: root.verticalLayout
      })
    );

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true
    });
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: 16,
        fontWeight: 600,
        x: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        numberFormat: yFormat === 'currency' ? "'$'#a" : '#a',
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: 600,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color('#7685A3')
      }),
      0
    );

    function makeSeries(name) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          categoryXField: 'category'
        })
      );

      series.columns.template.adapters.add('tooltipText', (text, target) => {
        const valueY = target?.dataItem?.get('valueY');
        let categoryX = target?.dataItem?.get('categoryX');
        let newName = name;
        if (newName && newName.length > 12) {
          const half = Math.ceil(newName.length / 2);
          newName = newName.slice(0, half) + ' -\n- ' + newName.slice(half);
        }
        let formattedValueY = yFormat === 'currency' ? formatCurrency(valueY) : valueY;
        return `[fontSize: 10px]${newName},\n ${categoryX} : ${formattedValueY}`;
      });

      series.data.setAll(data.chartValues);

      // series.appear();

      series.bullets.push(function (root, series, dataItem) {
        let label = am5.Label.new(root, {
          fill: root.interfaceColors.get('alternativeText'),
          centerY: am5.p50,
          centerX: am5.p50
        });

        return am5.Bullet.new(root, {
          sprite: label
        });
      });
    }

    for (let seriesName of Object.keys(data.chartValues[0])) {
      if (seriesName !== 'category') {
        makeSeries(seriesName);
      }
    }

    xAxisRef.current = xAxis;

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: data?.title,
      dataSource: generateDownloadData({ data: data?.chartValues, xLabel, yLabel, chartType: 'stackedBar' }),
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      }
    });

    return () => {
      root.dispose();
    };

    // eslint-disable-next-line
  }, [data]);

  useLayoutEffect(() => {
    if (xAxisRef.current) {
      xAxisRef.current.data.setAll(data.chartValues);
    }
  }, [data]);

  return <div id={chartId} style={{ width: '100%', height: '500px' }}></div>;
};

export default StackedColumnChart;
