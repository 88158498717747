import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuid } from 'uuid';

const ImageBarChart = ({ data, xLabel, yLabel, downloadTitle = 'Chart' }) => {
  const id = uuid();
  const xAxisRef = useRef(null);
  const seriesRef = useRef(null);
  const chartData = data.map((i) => ({
    ...i,
    yaxisValue: parseFloat(i.yaxisValue)
    // icon: { src: i.icon }
  }));

  useLayoutEffect(() => {
    am5.addLicense(import.meta.env.VITE_AM_LICENSE);
    let root = am5.Root.new(id);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        layout: root.verticalLayout
      })
    );

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    xRenderer.labels.template.setAll({
      paddingRight: 0,
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100
    });

    xRenderer.grid.template.setAll({
      location: 1
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'xaxisValue',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.children.moveValue(
      am5.Label.new(root, {
        text: xLabel,
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      xAxis.children.length - 1
    );

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'truncate',
      maxHeight: 110,
      maxWidth: 150,
      ellipsis: '...'
    });

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 })
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        y: am5.p50,
        centerX: am5.p50,
        fontSize: 16,
        fontWeight: 600,
        fill: am5.color('#7685A3')
      }),
      0
    );

    // Add series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'yaxisValue',
        categoryXField: 'xaxisValue'
      })
    );

    series.columns.template.setAll({
      tooltipText: '{categoryX}: {valueY}',
      tooltipY: 0,
      strokeOpacity: 0,
      templateField: 'columnSettings',
      width: am5.percent(50),
      fill: am5.color('#2A76F4'),
      cornerRadiusTL: 50,
      cornerRadiusTR: 50
    });

    series.columns.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color('#2A76F4'),
            offset: 0.1
          },
          {
            color: am5.color('#7FADF8')
          }
        ],
        rotation: 90
      })
    );

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Picture.new(root, {
          templateField: 'icon',
          width: 25,
          height: 25,
          centerX: am5.p50,
          centerY: 30
        })
      });
    });

    xAxisRef.current = xAxis;
    seriesRef.current = series;

    // series.data.setAll(data);

    // Make stuff animate on load
    // series.appear();
    chart.appear(1000, 100);

    const downloadedData = data?.map((item) => ({ [xLabel]: item?.xaxisValue, [yLabel]: item?.yaxisValue }));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: downloadedData,
      pngOptions: {
        quality: 0.8,
        maintainPixelRatio: true
      },
      csvOptions: {
        addBOM: true,
        addColumnNames: true
      },
      htmlOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      xlsxOptions: {
        disabled: true
      },
      filePrefix: downloadTitle
    });

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    xAxisRef.current.data.setAll(chartData);
    seriesRef.current.data.setAll(chartData);
  }, [chartData]);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
};

export default ImageBarChart;
